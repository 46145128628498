import { Component, Injector, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { BasePage } from '../base-page/base-page';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.page.html',
  styleUrls: ['./notification.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationPage extends BasePage implements OnInit {

  private readonly _BTN_TEXT = 'VIEW_MORE';

  @Input() notification: any;

  canShowMoreButton = false;
  btnText           = this._BTN_TEXT;

  constructor(injector: Injector) {
    super(injector);
  }

  enableMenuSwipe(): boolean {
    return false;
  }

  ngOnInit() {

    this.canShowMoreButton = this.notification.postId ||
      this.notification.placeId ||
      this.notification.categoryId;

    this.btnText = this.notification.btnText || this._BTN_TEXT;
  }

  onShowMoreButtonTouched() {
    this.modalCtrl.dismiss(this.notification);
  }

  onCloseButtonTouched() {
    this.modalCtrl.dismiss();
  }

}
