import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subject, lastValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OnesignalService } from 'src/app/services/onesignal.service';
import { ParseFile } from 'src/app/services/parse-file-service';
import { ProfileCompleteService } from 'src/app/services/profile-complete.service';
import { PublicProfileService } from 'src/app/services/public-profile.service';
import { User } from 'src/app/services/user.service';
import { emailValidator } from 'src/app/validators/email-validator';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import * as Parse from 'parse';
import { Preference } from 'src/app/services/preference.service';
import { Globals } from 'src/app/helpers/globals';
import { JsonLoaderService } from 'src/app/services/json-loader.service';
import { CountryCode, ISO_COUNTRY_CODES_CA_US, ISO_COUNTRY_CODES_OTHERS } from 'src/app/components-standalone/phone-number/phone-number-codes';
import { Place } from 'src/app/services/place.service';
import { DateTime } from 'luxon';
import { ModalActions } from 'src/app/modals/modal-actions';
import { ModalKitchenExperienceComponent } from 'src/app/modals/modal-kitchen-experience/modal-kitchen-experience.component';
import { fadeInOut } from 'src/app/animations/animations';
import { ToastSchedulerService } from 'src/app/services/toast-scheduler.service';
import { SyzlAsyncValidators } from 'src/app/validators/syzl-async-validators';
import { NotificationsPreferencesService } from 'src/app/services/notifications-preferences.service';
import { ConversationsService } from 'src/app/services/conversations.service';
import { ConversationEdge } from 'src/generated/graphql';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { instagramHandleValidator } from '../../validators/instagram-handle-validator';
import { TabService } from 'src/app/services/tab.service';

type ViewType = 'view' | 'editRequired' | 'editPublic';

interface IConfirmed {
  hasIdentity:   boolean;
  hasCriminal:   boolean;
  hasInsurance:  boolean;
  hasFoodSafety: boolean;
}

interface IBookingsHosted {
  first: Date;
  total: number;
}

interface IBookingsEquipment {
  equipmentCategoryId: string;
  numTimes:            number;
}

interface IBookingsCompleted extends IBookingsHosted {
  equipment: IBookingsEquipment[];
}

export interface IEquipmentExperience {
  equipmentCategoryId: string;
  experience:          number;
}

interface ITraining {
  programName: string;
  institution: string;
  location:    string;
}

interface IProvince {
  abbr: string;
  name: string;
}


class UserBookingStats {
  first = '';
  total = 0;
  equipment = [];
  constructor(first: any, total: any, equipment?: any) {
    this.first = DateTime.fromJSDate(new Date(first)).toFormat('yyyy/LL/dd');
    this.total = total;
    this.equipment = equipment;
  }
}

class ViewPublicProfileData {

  readonly HTTPS                          = 'https://';
  readonly FACEBOOK_DEFAULT               = this.HTTPS + 'www.facebook.com/';
  readonly LINKEDIN_DEFAULT               = this.HTTPS + 'www.linkedin.com/in/';
  readonly YOUTUBE_DEFAULT                = this.HTTPS + 'www.youtube.com/';
  readonly INSTAGRAM_DEFAULT              = '';
  firstName                               = '';
  hasInsurance                            = false;
  hasCriminal                             = false;
  hasFoodSafety                           = false;
  hasIdentity                             = false;
  kitchenExperience                       = 0;
  memberSinceYear: number | null          = new Date().getFullYear();
  about                                   = '';
  livesIn                                 = '';
  photo                                   = '';
  verificationExists                      = false;
  equipmentExperience                     = [];
  userPlaces: any                         = [];
  bookingsCompleted: any                  = null;
  bookingsHosted: any                     = null;
  training: ITraining[]                   = [];
  hasTraining                             = false;
  facebook                                = '';
  instagram                               = '';
  linkedin                                = '';
  website                                 = '';
  youtube                                 = '';
  hasSocials                              = false;

  constructor(basicProfile: any, userPlaces: Place[], publicProfileFormData: any, publicProfile: any) {

    this.firstName           = basicProfile.firstName;
    this.hasInsurance        = basicProfile.hasInsurance;
    this.hasCriminal         = basicProfile.hasCriminal;
    this.hasFoodSafety       = basicProfile.hasFoodSafety;
    this.hasIdentity         = basicProfile.hasIdentity;
    this.memberSinceYear     = DateTime.fromJSDate(new Date(basicProfile.memberSince)).year;
    this.photo               = basicProfile.photo;
    this.equipmentExperience = publicProfileFormData.equipmentExperience;
    this.userPlaces          = userPlaces;
    this.verificationExists  = this.hasIdentity || this.hasCriminal || this.hasInsurance || this.hasFoodSafety;
    this.about               = publicProfileFormData.about;
    this.livesIn             = `${publicProfileFormData.city}, ${publicProfileFormData.province}`;
    this.kitchenExperience   = publicProfileFormData.kitchenExperience;
    this.training            = publicProfileFormData.training;
    this.hasTraining         = Boolean(this.training[0]?.programName || this.training[0]?.institution || this.training[0]?.location);
    this.facebook            = publicProfileFormData.facebook  === this.FACEBOOK_DEFAULT  ? '' : this.validateSocialUrl(publicProfileFormData.facebook);
    this.instagram           = publicProfileFormData.instagram === this.INSTAGRAM_DEFAULT ? '' : this.displayInstagram(publicProfileFormData.instagram);
    this.linkedin            = publicProfileFormData.linkedin  === this.LINKEDIN_DEFAULT  ? '' : this.validateSocialUrl(publicProfileFormData.linkedin);
    this.website             = publicProfileFormData.website   === this.HTTPS             ? '' : this.validateSocialUrl(publicProfileFormData.website);
    this.youtube             = publicProfileFormData.youtube   === this.YOUTUBE_DEFAULT   ? '' : this.validateSocialUrl(publicProfileFormData.youtube);
    this.hasSocials          = Boolean(this.facebook || this.instagram || this.linkedin || this.website || this.youtube);

    if (publicProfile.id) {
      this.bookingsCompleted = publicProfile.get('bookingsCompleted') ? new UserBookingStats(publicProfile.get('bookingsCompleted')?.first, publicProfile.get('bookingsCompleted')?.total) : null;
      this.bookingsHosted    = publicProfile.get('bookingsHosted') ? new UserBookingStats(publicProfile.get('bookingsHosted')?.first, publicProfile.get('bookingsHosted')?.total) : null;
    }
  }

  validateSocialUrl(url: string): string {
    const urlRegEx = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    return url.match(urlRegEx) ? url : '';
  }

  displayInstagram(handle: string): string {
    return this.HTTPS + 'www.instagram.com/' + handle;
  }
}

@Component({
  selector: 'app-profile-editor',
  templateUrl: './profile-editor.component.html',
  styleUrls: ['./profile-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeInOut
  ]
})
export class ProfileEditorComponent implements OnInit, OnChanges, OnDestroy {

  private readonly _AUTO_SAVE_INTERVAL = 1000 * 15; // 15 seconds

  readonly MAX_LENGTH_ABOUT   = 500;
  readonly MODE_VIEW          = 'view';
  readonly MODE_EDIT_REQUIRED = 'editRequired';
  readonly MODE_EDIT_PUBLIC   = 'editPublic';



  @Input() existingConversation = '';
  @Input() mode: ViewType = this.MODE_EDIT_REQUIRED;
  @Input() profileUserId  = ''; // The user we want to view
  @Input() isPreviewMode  = false;
  @Input() location: any  = null;
  @Input() user!: User;         // The logged in user
  @Output() userUpdated     = new EventEmitter<User>();
  @Output() nameVisible     = new EventEmitter<boolean>();
  @Output() navigateToConvo = new EventEmitter<any>();

  facebookUrlPattern     = /^https:\/\/www\.facebook\.com\/\S*$/;
  websiteUrlPattern      = /^(https:\/\/|http:\/\/)\S+\.\S+$/;
  youtubeLinkPattern     = /^https:\/\/www\.youtube\.com\/\S*$/;
  linkedinProfilePattern = /^https:\/\/www\.linkedin\.com\/\S*$/;

  formRequired = this._fb.group({
    firstName: this._fb.control('', [Validators.required]),
    lastName:  this._fb.control('', [Validators.required]),
    authorize: this._fb.control(false, { validators: [Validators.requiredTrue], updateOn: 'change' }),
    email:     this._fb.control('', {
      validators:      [emailValidator(), Validators.required],
      asyncValidators: [this._asyncValidators.hasUniqueUserProperty()],
      updateOn: 'blur'
    }),
    username:  this._fb.control('', [Validators.required]),
    phone:     this._fb.control('', [Validators.required]),
    smsAgree:  this._fb.control(true, { updateOn: 'change' }),
    country:   this._fb.control('', {
      validators: [Validators.required],
      updateOn:   'change'
    }),
    terms:     this._fb.control(false, { validators: [Validators.requiredTrue], updateOn: 'change' }),
    photo:     new FormControl(),
  }, { updateOn: 'blur' });

  formPublic = this._fb.group({
    city:                this._fb.control(''),
    province:            this._fb.control('', { updateOn: 'change' }),
    about:               this._fb.control(''),
    facebook:            this._fb.control('', Validators.pattern(this.facebookUrlPattern)),
    instagram:           this._fb.control('', instagramHandleValidator()),
    website:             this._fb.control('', Validators.pattern(this.websiteUrlPattern)),
    youtube:             this._fb.control('', Validators.pattern(this.youtubeLinkPattern)),
    linkedin:            this._fb.control('', Validators.pattern(this.linkedinProfilePattern)),
    kitchenExperience:   this._fb.control(0),
    equipmentExperience: this._fb.array<FormGroup<{
      equipmentCategoryId: FormControl<string | null>;
      experience:          FormControl<number | null>;
    }>>([]),
    training: this._fb.array<FormGroup<{
      programName: FormControl<string | null>;
      institution: FormControl<string | null>;
      location:    FormControl<string | null>;
    }>>([])
  }, { updateOn: 'blur' });

  isTermsHidden                     = false;
  conversations: ConversationEdge[] = [];
  saving                            = false;
  termsSource                       = '';
  updatedAt: Date | null            = null;
  provinces: IProvince[]            = [];
  countries: CountryCode[]          = [...ISO_COUNTRY_CODES_CA_US, ...ISO_COUNTRY_CODES_OTHERS];

  confirmedItems: IConfirmed | null            = null;
  bookingsCompleted: IBookingsCompleted | null = null;
  bookingsHosted: IBookingsHosted | null       = null;
  equipmentCategories: any[]                   = [];

  viewProfileData: any                         = {};

  updatedTimeoutValue                          = 0;
  preferredUnit                                = '';

  isLoggedIn = false;

  instagram = 'Instagram';
  facebook  = 'Facebook';

  private _canSave         = false;
  private _intervalCreated = false;
  private _savesInProgress = 0;
  private _saveStartMs     = 0;
  private _publicProfile   = new Parse.Object('UserPublicProfile');
  private _valFormRequired = this.formRequired.value;
  private _valFormPublic   = this.formPublic.value;
  private _destroy$        = new Subject<void>();

  @ViewChild('divEditPublic', { static: false }) private _divEditPublic: ElementRef | null = null;
  @ViewChild('divSaving', { static: false }) private _divSaving: ElementRef | null         = null;

  constructor(
    private _fb: FormBuilder,
    private _changeRef: ChangeDetectorRef,
    private _translator: TranslateService,
    private _toast: ToastController,
    private _oneSignalService: OnesignalService,
    private _profileCompleteService: ProfileCompleteService,
    private _publicProfileService: PublicProfileService,
    private _jsonLoader: JsonLoaderService,
    private _modalCtrl: ModalController,
    private _toastService: ToastSchedulerService,
    private _translateService: TranslateService,
    private _asyncValidators: SyzlAsyncValidators,
    private _conversationsService: ConversationsService,
    private _notificationPrefService: NotificationsPreferencesService,
    private _preferences: Preference,
    private _renderer: Renderer2,
    public tabService: TabService,
    public breakpoints: BreakpointService,
  ) {}

  async ngOnInit() {
    const lang       = this._preferences.lang;
    this.termsSource = Globals.getTermsAndConditionsUri(lang);

    this.preferredUnit = this._preferences.unit;

    // Status changes on the email control (Async validators cause a status of PENDING where both valid and invalid are false)
    const emailCtrl = this.formRequired.controls.email;
    emailCtrl.statusChanges.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this._changeRef.markForCheck();
      if (this.mode === this.MODE_EDIT_PUBLIC && emailCtrl.status === 'VALID') {
        this._saveChanges();
      }
    });

    this._oneSignalService.initializationFailed$.pipe(takeUntil(this._destroy$)).subscribe((didFail: boolean) => {
      if (didFail) {
        const ctrl = this.formRequired.controls.smsAgree;
        ctrl.patchValue(false);
        ctrl.disable();
      } else {
        const ctrl = this.formRequired.controls.smsAgree;
        ctrl.patchValue(true);
        ctrl.enable();
      }
    });

    this.isLoggedIn = User.getCurrent().isLoggedIn();
  }

  async ngOnChanges(changes: SimpleChanges) {
    // Ensure user's can ONLY edit their own profiles
    if (this.user && this.profileUserId && this.user.id === this.profileUserId) {
      this._canSave = true;
    } else {
      this._canSave = false;
      this.mode     = this.MODE_VIEW;
      this.checkForConversations();
    }

    // Setup the formRequired data (_User)
    if (changes.user && this.user) {
      this._setupRequiredForm();
    }
    
    // Setup the formPublic data (UserPublicProfile)
    if (changes.profileUserId && this.profileUserId) {
      this._setupPublicForm(this.mode === this.MODE_VIEW);
    }

    if (this.mode === this.MODE_EDIT_PUBLIC && !this._intervalCreated) {
      this.formPublic.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(() => this._saveChanges());
      this.formRequired.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(() => this._saveChanges());
      this._intervalCreated = true;
    }

  }

  async ngOnDestroy() {
    if (this.mode === this.MODE_EDIT_PUBLIC) {
      await this._saveChanges();
    }
    this._destroy$.next();
  }

  async forceSave() {
    await this._saveChanges();
  }

  onFileUploaded(files: ParseFile[] | null) {
    const file = files ? files[0] : null;
    this.formRequired.controls.photo.patchValue(file);
    this.formRequired.controls.photo.markAsDirty();
    this.formRequired.controls.photo.markAsTouched();
    if (this.mode === this.MODE_EDIT_PUBLIC) {
      this._saveChanges();
    }
  }

  onAuthorizeToggle(e: any) {
    const cntrl = this.formRequired.controls.authorize;
    cntrl.patchValue(e.detail.checked);
    cntrl.markAsDirty();
    cntrl.markAsTouched();
  }

  onTermsToggle(e: any) {
    const cntrl = this.formRequired.controls.terms;
    cntrl.patchValue(e.detail.checked);
    cntrl.markAsDirty();
    cntrl.markAsTouched();
    const el = e.target;
    el.blur();
    setTimeout(() => el.focus(), 100);
  }

  async agreeToTerms() {
    const cntrl = this.formRequired.controls.terms;
    cntrl.patchValue(true);
    cntrl.markAsDirty();
    cntrl.markAsTouched();
    this._saveChanges();
  }

  async generateNewUsername() {
    this.user.generateUsername().then((resp) => {
      const cntrl = this.formRequired.controls.username;
      cntrl.markAsDirty();
      cntrl.markAsTouched();
      cntrl.patchValue(resp.username);
      if (this.mode === this.MODE_EDIT_PUBLIC) {
        this._saveChanges();
      }
    }, (err) => {
      console.error(err);
    });
  }

  /**
   * Loads the provinces / states based on the selected country
   */
  async loadProvinces() {
    const lang     = this._preferences.lang;
    const country  = this.formRequired.controls.country.value || '';

    try {
      this.provinces = await this._jsonLoader.load(Globals.getProvincesUri(lang, country)) as IProvince[];
      if (!this.provinces || this.provinces.length === 0) {
        this.provinces = [];
        this.formPublic.controls.province.disable();
      } else {
        this.formPublic.controls.province.enable();
      }
    } catch (error) {
      this.provinces = [];
    }
    this._changeRef.markForCheck();
  }

  async onSubmit() {
    this.formRequired.markAllAsTouched();
    if (this.formRequired.valid && this.formRequired.controls.authorize && this.formRequired.controls.terms.value) {
      try {
        this.saving = true;
        const data  = this.formRequired.getRawValue() as any; // Need "as any" so we can add the photo to the form
        this._changeRef.markForCheck();

        // SMS LOGIC
        const smsAgree = data.smsAgree;
        if (smsAgree) {
          const subscribed = await this._oneSignalService.subscribeOnSignUp(data.phone);
          if (subscribed) {
            let notificationPrefs = this.user.get('notificationPreference');
            if (!notificationPrefs) {
              try {
                notificationPrefs = await this._notificationPrefService.createDefaultNotificationsPref();
                User.getCurrent().set('notificationPreference', notificationPrefs);
              } catch (error) {
                this._translator.get('ERROR_NETWORK').subscribe(str => this.showToast(str));
              }
            }
            notificationPrefs.set('enabledSMS', true);
            await notificationPrefs.save();
          } else {
            const trans = await lastValueFrom(this._translateService.get('SMS_FAIL'));
            this.showToast(trans);
          }
        }
        delete data.smsAgree;
        
        this._changeRef.markForCheck();
        this.user = await this.user.save(data);

        const translations = await this._translator.get(['SUCCESS', 'PROFILE_UPDATED', 'OK']).toPromise();
        await Swal.fire({
          title: translations.SUCCESS,
          text: translations.PROFILE_UPDATED,
          confirmButtonText: translations.OK,
          icon: 'success',
          heightAuto: false,
          showClass: {
            popup: 'animated fade-in'
          },
          hideClass: {
            popup: 'animated fade-out'
          },
        } as SweetAlertOptions);
  
        this.saving = false;
        this._changeRef.markForCheck();
  
        this.userUpdated.emit(this.user);
        this.checkNotificationPreference();
        await this._profileCompleteService.checkProfilePhoto();
        
      } catch (error: any) {
        if (error.code === 202) {
          this._translator.get('USERNAME_TAKEN').subscribe(str => this.showToast(str));
        } else if (error.code === 203) {
          this._translator.get('EMAIL_TAKEN').subscribe(str => this.showToast(str));
        } else if (error.code === 125) {
          this._translator.get('EMAIL_INVALID').subscribe(str => this.showToast(str));
        } else {
          this._translator.get('ERROR_NETWORK').subscribe(str => this.showToast(str));
        }
      }
    }
  }

  async showToast(message: string = '', buttons: any = null, duration: number = 3000) {
    const closeText = await this._translator.get('CLOSE').toPromise();
    const toast     = await this._toast.create({
      message,
      color:    'dark',
      position: 'bottom',
      cssClass: 'tabs-bottom',
      duration,
      buttons:  buttons || [{
        text: closeText,
        role: 'cancel',
      }]
    });
    return await toast.present();
  }

  async checkNotificationPreference() {
    const preferences = this.user.get('notificationPreference');

    if (preferences) {
      const smsEnabled = preferences.get('enabledSMS');

      if (smsEnabled) {
        this._oneSignalService.assignPhoneNumber();
      }
    }
  }

  async checkForConversations() {
    const conversations = await this._conversationsService.getConversationsBetweenUsers(this.user.id, this.profileUserId);
    const existingConversations = conversations?.data.conversations.edges as ConversationEdge[];
    if (existingConversations) {
      this.conversations = existingConversations;
    }
  }

  /**
   * Sets up the form for the _User collection
   */
  private async _setupRequiredForm() {
    this.formRequired.patchValue({
      firstName: this.user.firstName,
      lastName:  this.user.lastName,
      email:     this.user.email,
      username:  this.user.username,
      phone:     this.user.get('phone'),
      country:   this.user.get('country') || this.countries[0].code,
      terms:     this.user.get('terms'),
      photo:     this.user.photo,
    });

    this.formRequired.markAsPristine();
    this._valFormRequired = this.formRequired.value;
    this.isTermsHidden    = this.formRequired.controls.terms.value || false;
    await this.loadProvinces();
  }

  /**
   * Sets up the form for the UserPublicProfile collection
   */
  private async _setupPublicForm(isViewMode: boolean) {
    try {
      const userProfile   = await this._publicProfileService.getUserPublicProfile(this.profileUserId);
      const basicProfile  = userProfile.basicProfile;
      const publicProfile = userProfile.publicProfile;
      const userPlaces    = userProfile.userPlaces;
      this._publicProfile = publicProfile;
      
      this.confirmedItems = {
        hasIdentity:   basicProfile.hasIdentity,
        hasCriminal:   basicProfile.hasCriminal,
        hasInsurance:  basicProfile.hasIdentity,
        hasFoodSafety: basicProfile.hasFoodSafety
      };
      if (publicProfile.id) {
        this.bookingsCompleted = publicProfile.get('bookingsCompleted');
        this.bookingsHosted    = publicProfile.get('bookingsHosted');
  
        this.formPublic.patchValue({
          province:          publicProfile.get('province'),
          city:              publicProfile.get('city'),
          about:             publicProfile.get('about'),
          facebook:          publicProfile.get('facebook')  || 'https://www.facebook.com/',
          instagram:         publicProfile.get('instagram') || '',
          website:           publicProfile.get('website')   || 'https://',
          youtube:           publicProfile.get('youtube')   || 'https://www.youtube.com/',
          linkedin:          publicProfile.get('linkedin')  || 'https://www.linkedin.com/in/',
          kitchenExperience: publicProfile.get('kitchenExperience') || 0,
        });
        
        // Add the equipment experience to the array
        this.equipmentCategories = userProfile.equipmentCategories;
        for (const equipmentCategory of this.equipmentCategories) {
          const equipmentCategoryId = equipmentCategory.id;
          const userExperience      = publicProfile.get('equipmentExperience')?.find((item: any) => item.equipmentCategoryId === equipmentCategoryId) || {
            equipmentCategoryId,
            experience: 0
          };
          if (!isViewMode || userExperience.experience > 0) {
            this.formPublic.controls.equipmentExperience.push(this._fb.group({
              equipmentCategoryId: this._fb.control(userExperience.equipmentCategoryId),
              experience:          this._fb.control(userExperience.experience)
            }));
          }
        }
  
        // Add the training experience to the array
        for (const training of publicProfile.get('training') || []) {
          this.formPublic.controls.training.push(this._fb.group({
            programName: this._fb.control(training.programName),
            institution: this._fb.control(training.institution),
            location:    this._fb.control(training.location),
          }));
        }
      }
  
      if (!isViewMode && this.formPublic.controls.training.controls.length === 0) {
        this.formPublic.controls.training.push(this._fb.group({
          programName: this._fb.control(''),
          institution: this._fb.control(''),
          location:    this._fb.control(''),
        }));
      }
      
      this.formPublic.markAsPristine();
      const publicProfileFormData = this.formPublic.getRawValue();
      this.viewProfileData        = new ViewPublicProfileData(basicProfile, userPlaces, publicProfileFormData, publicProfile);
      this._valFormPublic         = this.formPublic.getRawValue();
      this._changeRef.markForCheck();
    } catch (error) {
      const trans = await lastValueFrom(this._translateService.get('ERROR_NETWORK'));
      await this.showToast(trans);
    }
  }

  async addTraining() {
    this.formPublic.controls.training.push(this._fb.group({
      programName: this._fb.control(''),
      institution: this._fb.control(''),
      location:    this._fb.control(''),
    }));
    this._changeRef.markForCheck();
  }

  async deleteTraining(index: number) {
    this.formPublic.controls.training.removeAt(index);

    if (this.formPublic.controls.training.controls.length === 0) {
      this.addTraining();
    }
    this.formPublic.controls.training.markAsDirty();
    this._changeRef.markForCheck();
  }

  /**
   * Check the required and public forms for changes and saves the data accordingly
   * - When the data is saved, it updates the lastSaved time, which gets displayed on screen
   */
  private async _saveChanges() {
    if (this._canSave) {
      await this._saveRequired();
      await this._savePublic();
    }
  }

  /**
   * Saves the information destined for the _User collection
   * - We only save the information that is valid
   */
  private async _saveRequired() {
    const currValue     = this.formRequired.value;
    const prevValue     = this._valFormRequired;
    const updatedValues = this._getUpdatedValues(this.formRequired.controls, this._valFormRequired);
    if (this.formRequired.dirty && updatedValues) {
      this._showSavingBar(updatedValues, currValue, prevValue);
      this._valFormRequired = this.formRequired.value;
      this.formRequired.markAsPristine();
      this._saveStart();
      await this.user.save(updatedValues);
      this._saveEnd();

      // If we updated the phone number, we need to update OneSignal
      if (updatedValues.phone) {
        this.checkNotificationPreference();
      }

      if (updatedValues.photo) {
        await this._profileCompleteService.checkProfilePhoto();
      }
    }
  }

  /**
   * Saves the information destined for the UserPublicProfile collection
   */
  private async _savePublic() {
    const currValue     = this.formPublic.value;
    const prevValue     = this._valFormPublic;
    const updatedValues = this._getUpdatedValues(this.formPublic.controls, this._valFormPublic);
    
    if (this.formPublic.dirty && updatedValues) {
      this._showSavingBar(updatedValues, currValue, prevValue);
      this._valFormPublic = this.formPublic.value;
      this.formPublic.markAsPristine();
      this._saveStart();
      await this._publicProfile.save(updatedValues);
      this._saveEnd();
    }
  }

  /**
   * Loops over the form controls looking ONLY for items that are VALID and have been updated
   * - Returns ONLY those items
   * @param controls 
   * @returns 
   */
  private _getUpdatedValues(controls: any, initialValues: { [key: string]: any }) {
    const value: { [key: string]: any } = {};
    for (const prop in controls) {
      if (controls[prop]) {
        const initialValue = initialValues[prop];
        const control      = controls[prop];
        const isDifferent  = this._isValueDifferent(initialValue, control.value);
        if (isDifferent && control.dirty && control.valid) {
          value[prop] = control.value;
        }
      }
    }
    return Object.keys(value).length > 0 ? value : null;
  }

  /**
   * Compares the initial vs new values to see if they are different
   * @param initialValue 
   * @param newValue 
   * @returns 
   */
  private _isValueDifferent(initialValue: any, newValue: any) {
    switch (typeof initialValue) {
      case 'string':
      case 'number':
      case 'undefined':
        return initialValue !== newValue;
      case 'object':
        const initialJSON = JSON.stringify(initialValue);
        const newJSON     = JSON.stringify(newValue);
        return initialJSON !== newJSON;
      default:
        return false;
    }
  }

  /**
   * _showSavingBar
   * - Displays a quick saving progress bar below the item that is being saved
   * @param updatedValues 
   */
  private _showSavingBar(updatedValues: { [key: string]: any }, currValue: { [key: string]: any }, prevValue: { [key: string]: any }) {
    // Find the element that is being saved by using its formControlName (which gets lowercased when rendered to HTML)
    let el: HTMLElement | null = null;
    for (const prop in updatedValues) {
      // The photo element is weird because it uses the <app-upload-box> component and it isn't really a form control,
      // so we need to target it differently than other form controls
      if (prop === 'photo') {
        el = document.querySelector('app-upload-box');
      } else {
        const val = updatedValues[prop];
        if (typeof val === 'object' && val.length > 0) {
          el = this._getUpdatedItemFromArray(prop, currValue, prevValue);
        } else {
          el = document.querySelector('[formcontrolname="' + prop + '"]');
        }
      }
      if (el) { break; }
    }
    if (el && this._divEditPublic) {
      // Find the element's parent that is a firstChild of this._divEditPublic
      const offset  = this._findOffsetTop(el as HTMLElement, this._divEditPublic?.nativeElement, { top: 0, left: 0 });
      const top     = offset.top + el.offsetHeight - (parseFloat(window.getComputedStyle(el).paddingBottom) || 0);
      const left    = offset.left;
      const width   = el.offsetWidth;

      // Update the position of the progress bar
      setTimeout(() => {
        this._renderer.setStyle(this._divSaving?.nativeElement, 'top', top + 'px');
        this._renderer.setStyle(this._divSaving?.nativeElement, 'left', left + 'px');
        this._renderer.setStyle(this._divSaving?.nativeElement, 'width', width + 'px');
      }, 0);
    }
  }

  /**
   * _getUpdatedItemFromArray
   * - Attempts to find the actual HTMLElement that was updated within the array of items in the form array
   * @param prop 
   * @param currValue 
   * @param prevValue 
   * @returns 
   */
  private _getUpdatedItemFromArray(prop: string, currValue: { [key: string]: any }, prevValue: { [key: string]: any }): HTMLElement {
    const currArr = currValue[prop];
    const prevArr = prevValue[prop];
    if (currArr.length === prevArr.length) {
      for (let i = 0; i < currArr.length; i++) {
        const currVal = currArr[i];
        const prevVal = prevArr[i];
        switch (typeof currVal) {
          case 'string':
          case 'number':
            if (currVal !== prevVal) {
              return document.querySelector(`.${prop}-${i}`) as HTMLElement;
            }
          case 'object':
            for (const innerProp in currVal) {
              const currPropVal = currVal[innerProp];
              const prevPropVal = prevVal[innerProp];
              if (currPropVal !== prevPropVal) {
                return document.querySelector(`.${prop}-${i}`)?.querySelector(`[formcontrolname="${innerProp}"]`) as HTMLElement;
              }
            }
        }
      }
    }
    // Couldn't find the actual item element, so return the formarrayname element
    return document.querySelector('[formarrayname="' + prop + '"]') as HTMLElement;
  }

  /**
   * _findOffsetTop
   * - Determines where the indeterminate progress bar should be displayed for save purposes
   * @param el 
   * @param findTo 
   * @param offsetY 
   * @returns 
   */
  private _findOffsetTop(el: HTMLElement, findTo: HTMLElement, offset: { top: number; left: number }): { top: number; left: number } {
    offset.top  += el.offsetTop;
    offset.left += el.offsetLeft;
    if (el.offsetParent && el.offsetParent !== findTo) {
      return this._findOffsetTop(el.offsetParent as HTMLElement, findTo, offset);
    }
    return offset;
  }

  /**
   * Increases the saveInProgress counter and sets saving to true
   */
  private _saveStart() {
    this._savesInProgress++;
    this.saving       = true;
    this._saveStartMs = new Date().getTime();
  }

  /**
   * Decreases the saveInProgress counter and sets saving to false when
   * the count reaches 0. Also sets the updatedAt time
   */
  private async _saveEnd() {
    this._savesInProgress--;
    if (this._savesInProgress <= 0) {
      this._savesInProgress = 0;
      const saveTimeMs      = new Date().getTime() - this._saveStartMs;
      const closeMs         = saveTimeMs > 1000 ? 0 : 1000 - saveTimeMs;
      setTimeout(() => {
        this.saving = false;
        this._changeRef.markForCheck();
      }, closeMs);
    }
    const message = await lastValueFrom(this._translateService.get('CHANGES_SAVED'));
    this._toastService.add({ message, duration: 2000 });

    this._changeRef.markForCheck();
  }

  async openExperienceModal() {
    const kitchenExperience   = this.viewProfileData.kitchenExperience;
    const equipmentCategories = this.equipmentCategories;
    const equipmentExperience = this.viewProfileData.equipmentExperience;
    await ModalActions.openComponentModalNonFullScreen(this._modalCtrl, ModalKitchenExperienceComponent, { equipmentExperience, equipmentCategories, kitchenExperience });
  }

  async closeModal() {
    await this._modalCtrl.dismiss();
  }

  async handleMessageClick() {
    if (this.existingConversation) {
      this.navigateToConvo.emit(this.existingConversation);
      this.closeModal();
    } else {
      this.navigateToConvo.emit(this.viewProfileData?.userPlaces);
    }
  }

  onNameVisible(event: boolean) {
    this.nameVisible.emit(event);
  }
}
