import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'prettyHourDisplay'
})
export class PrettyHourDisplayPipe implements PipeTransform {

  transform(hour: string): string {
    return DateTime.fromObject({ hour: parseInt(hour, 10) }).toFormat('h a').toLowerCase();
  }

}
