import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from './user.service';
import { iCals } from '../components/calendar-sync/calendar-sync.component';

export interface IiCalCreateUser {
  provider: string;
  external_user_id: string;
  name: string;
  email: string
}

const calApiVersion = '/v1';

enum calApi {
  createUser = `${calApiVersion}/user`,
  getAccounts = `${calApiVersion}/accounts`,
  getBookings = `${calApiVersion}/bookings`,
  getCalendars = `${calApiVersion}/listing/get-calendars`,
  getWritableCalendars = `${calApiVersion}/listing/get-writeable-calendar`,
  setReadableCalendar = `${calApiVersion}/listing/add-readable-calendar`,
  removeReadableCalendar = `${calApiVersion}/listing/remove-readable-calendar`,
  setWriteableCalendar = `${calApiVersion}/listing/add-writeable-calendar`,
  removeWriteableCalendar = `${calApiVersion}/listing/remove-writeable-calendar`,
}

@Injectable({
  providedIn: 'root',
})
export class CalApiService {

  private _apiUrl = environment.calApiUrl;

  constructor(
    private _http: HttpClient,
  ) { }

  private _getHeaders() {
    return {
      headers: {
        'X-Parse-Session-Token': User.getCurrent().getSessionToken() as string,
      },
    };
  }

  async createUser(user: IiCalCreateUser): Promise<{ authUrl: string }> {
    return await firstValueFrom(this._http.post<{ authUrl: string }>(`${this._apiUrl}${calApi.createUser}`, user, this._getHeaders()));
  }

  async getBookingsByExternalId(listingId: string) {
    return await firstValueFrom(this._http.post(calApi.getBookings, { listingId }, this._getHeaders()));
  }

  async fetchAccounts(userId: string): Promise<{ hasAccounts: boolean }> {
    return await firstValueFrom(this._http.get<{ hasAccounts: boolean }>(`${this._apiUrl}${calApi.getAccounts}/${userId}`, this._getHeaders()));
  }

  async getCalendarsByExternalId(listingId: string): Promise<any[]> {
    const externalUserId = User.getCurrent().id;
    const result = await firstValueFrom(this._http.get(this._apiUrl + calApi.getCalendars + '/' + externalUserId + '/' + listingId, this._getHeaders()));
    return result as any[];
  }

  async getCalendarsByProvider(provider: string): Promise<iCals> {
    const externalUserId = User.getCurrent().id;
    const result = await firstValueFrom(this._http.get(this._apiUrl + calApi.getCalendars + '/' + externalUserId + '/' + provider, this._getHeaders()));
    return result as iCals;
  }

  async getWritableCalendar(listingId: string) {
    const result = await firstValueFrom(this._http.get(this._apiUrl + calApi.getWritableCalendars + '/' + listingId, this._getHeaders()));
    return result as any;
  }

  async setReadableCalendar(calendarId: string, listingId: string) {
    const userId = User.getCurrent().id;
    return await firstValueFrom(this._http.post(this._apiUrl + calApi.setReadableCalendar, { calendarId, userId, listingId }, this._getHeaders()));
  }

  async removeReadableCalendar(calendarId: string, listingId: string) {
    const userId = User.getCurrent().id;
    return await firstValueFrom(this._http.post(this._apiUrl + calApi.removeReadableCalendar, { calendarId, userId, listingId }, this._getHeaders()));
  }

  async setWriteableCalendar(listingId: string, calendarId: string) {
    return await firstValueFrom(this._http.post(this._apiUrl + calApi.setWriteableCalendar, { listingId, calendarId }, this._getHeaders()));
  }

  async removeWriteableCalendar(listingId: string) {
    return await firstValueFrom(this._http.post(this._apiUrl + calApi.removeWriteableCalendar, { listingId }, this._getHeaders()));
  }

  async deleteCalendarAccounts() {
    return await firstValueFrom(this._http.delete(this._apiUrl + calApi.getAccounts + '/' + User.getCurrent().id, this._getHeaders()));
  }
}
