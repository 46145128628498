import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { SearchLocation } from './search.service';

@Injectable({
  providedIn: 'root'
})
export class RequestMyCityService {

  private _cache: { [key: string]: boolean } = {};

  constructor() { }

  async requestMyCity(email: string, cityInfo: SearchLocation): Promise<Parse.Object> {
    return Parse.Cloud.run('requestMyCity', { email, cityInfo });
  }

  isCached(cityInfo: SearchLocation) {
    return this._cache[cityInfo.address];
  }

  cacheSuccess(cityInfo: SearchLocation) {
    this._cache[cityInfo.address] = true;
  }
}
