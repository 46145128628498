import { Injectable } from '@angular/core';
import { User } from './user.service';

interface IGoogleTag {
  'bookingId'?: string;
  'bookingSessionId'?: string;
  'currency'?: string;
  'date-booked'?: string;
  'deposit-cost'?: number;
  'email'?: string;
  'end-time'?: string;
  'event': string;
  'host'?: string;
  'items'?: any;
  'item_id'?: string;
  'item_name'?: string;
  'kitchenId'?: string;
  'kitchen'?: string;
  'listingId'?: string;
  'maker'?: string;
  'placeId'?: string;
  'result'?: string;
  'resultId'?: string;
  'search_term'?: string;
  'service-cost'?: number;
  'start-time'?: string;
  'start-checkout-time'?: Date;
  'social-media-source'?: string;
  'tax-cost'?: number;
  'transaction_id'?: string;
  'total-cost'?: number;
  'user_id'?: string;
  'value'?: number;
  'premium_type'?: string;
  'premium_upgraded_to'?: string;
  'enhanced_conversion_data'?: IEnahncedConversions;
}

interface IEnahncedConversions {
  'email': string;
  'phone_number': string;
}


@Injectable({
  providedIn: 'root'
})
export class GoogleTagService {

  private _dataLayer: IGoogleTag[] = [];
  private _user?: User;

  constructor() {
    this._dataLayer = (window as { [key: string]: any }).dataLayer || [];
  }
  
  setUser(user: User) {
    this._user = user;
  }

  add(data: IGoogleTag) {
    if(!this._user) this._user = User.getCurrent();
    
    if(!this._user?.isAnonymous()) {
      data.enhanced_conversion_data = {
        phone_number: this._user.phone,
        email: this._user.email
      };
    }

    this._dataLayer.push(data);
  }
}
