// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card {
  border-radius: 0;
}

::ng-deep ion-textarea .label-text {
  color: var(--ion-color-dark-shade);
}

ion-textarea {
  --color: var(--ion-color-dark);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1vZGFsLWNvbnRlbnQtd2l0aC10ZXh0YXJlYS5wYWdlLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxnQkFBQTtBQUNKOztBQUVBO0VBQ0ksa0NBQUE7QUFDSjs7QUFFQTtFQUNJLDhCQUFBO0FBQ0oiLCJmaWxlIjoibW9kYWwtY29udGVudC13aXRoLXRleHRhcmVhLnBhZ2Uuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbImlvbi1jYXJkIHtcbiAgICBib3JkZXItcmFkaXVzOiAwO1xufVxuXG46Om5nLWRlZXAgaW9uLXRleHRhcmVhIC5sYWJlbC10ZXh0IHtcbiAgICBjb2xvcjogdmFyKC0taW9uLWNvbG9yLWRhcmstc2hhZGUpO1xufVxuXG5pb24tdGV4dGFyZWEge1xuICAgIC0tY29sb3I6IHZhcigtLWlvbi1jb2xvci1kYXJrKTtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/modals/modal-content-with-textarea/modal-content-with-textarea.page.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAEA;EACI,kCAAA;AACJ;;AAEA;EACI,8BAAA;AACJ;AACA,4kBAA4kB","sourcesContent":["ion-card {\n    border-radius: 0;\n}\n\n::ng-deep ion-textarea .label-text {\n    color: var(--ion-color-dark-shade);\n}\n\nion-textarea {\n    --color: var(--ion-color-dark);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
