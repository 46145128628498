import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { Globals } from '../helpers/globals';
import { Category } from './categories';
import { User } from './user.service';

type PlaceGuideResponse = {
  placeInfo: {
    title: string;
    image: Parse.File;
  },
  guide: any | null;
};

export class PlaceVerificationChoice {
  static readonly CRC      = 'background';
  static readonly IDENTITY = 'identity';
}
@Injectable({
  providedIn: 'root'
})
export class Place extends Parse.Object {
  static readonly VERIFICATION_CHOICES = {
    id: 'identity',
    background: 'background',
  };

  constructor() {
    super('Place');
  }

  static getInstance() {
    return this;
  }

  distance(location: any, unit: string = 'km', round: boolean = false) {

    if (!location) {return null;}

    const geoPoint = new Parse.GeoPoint({
      latitude: location.latitude,
      longitude: location.longitude
    });

    const distance = unit === 'km'
      ? this.location.kilometersTo(geoPoint).toFixed(1)
      : this.location.milesTo(geoPoint).toFixed(1);
    
    return (round ? Math.round(distance) : distance) + ' ' + unit;
  }

  like(place: Place) {
    return Parse.Cloud.run('likePlace', { placeId: place.id });
  }

  isLiked(place: Place): Promise<boolean> {
    return Parse.Cloud.run('isPlaceLiked', { placeId: place.id });
  }

  isStarred(place: Place): Promise<boolean> {
    return Parse.Cloud.run('isPlaceStarred', { placeId: place.id });
  }

  createInCloud(payload: any = {}): Promise<any> {
    return Parse.Cloud.run('createPlace', payload);
  }

  updateInCloud(id: string, data: any = {}): Promise<Place> {
    return Parse.Cloud.run('updatePlace', { id, data });
  }

  delete() {
    return Parse.Cloud.run('deletePlace', { id: this.id });
  }

  loadOne(id: string): Promise<Place> {
    const query = new Parse.Query(Place);
    query.containedIn('status', [
      Globals.PLACE_STATUS_INCOMPLETE,
      Globals.PLACE_STATUS_PENDING,
      Globals.PLACE_STATUS_APPROVED,
      Globals.PLACE_STATUS_PRIVATE,
      Globals.PLACE_STATUS_DELISTED,
    ]);
    query.include('categories');
    query.include('facility');
    return query.get(id);
  }

  load(params: any = {}): Promise<Place[]> {
    return Parse.Cloud.run('getPlaces', params);
  }

  async loadGuide(id: string): Promise<PlaceGuideResponse> {
    return await Parse.Cloud.run('getGuide', { id });
  }

  count(params: any = {}): Promise<number> {

    const status = params.status || Globals.PLACE_STATUS_APPROVED;

    const query = new Parse.Query(Place);

    if (Array.isArray(status)) {
      query.containedIn('status', status);
    } else {
      query.equalTo('status', status);
    }

    if (params.ratingMin) {
      query.greaterThanOrEqualTo('ratingAvg', params.ratingMin);
    }

    if (params.ratingMax) {
      query.lessThanOrEqualTo('ratingAvg', params.ratingMax);
    }

    if (params.cat) {

      if (Array.isArray(params.cat)) {

        const categories = params.cat.map((id: string) => {
          const obj = new Category();
          obj.id = id;
          return obj;
        });

        if (categories.length) {
          query.containedIn('categories', categories);
        }
      } else if (typeof params.cat === 'string') {
        const category = new Category();
        category.id = params.cat;
        query.equalTo('categories', category);
      }
    }

    if (params.category) {
      query.equalTo('categories', params.category);
    }

    if (params.facility) {
      query.equalTo('facility', params.facility);
    }

    if (params.featured === '1') {
      query.equalTo('isFeatured', true);
    }

    if (params.user) {
      query.equalTo('user', params.user);
    }

    if (params.canonical && params.canonical !== '') {
      query.contains('canonical', params.canonical);
    }

    if (params.bounds) {

      const southwest = new Parse.GeoPoint(
        params.bounds[0].latitude,
        params.bounds[0].longitude
      );

      const northeast = new Parse.GeoPoint(
        params.bounds[1].latitude,
        params.bounds[1].longitude
      );

      query.withinGeoBox('location', southwest, northeast);

    } else if (params.latitude && params.longitude) {

      const point = new Parse.GeoPoint({
        latitude: params.latitude,
        longitude: params.longitude,
      });

      if (params.maxDistance) {
        if (params.unit === 'km') {
          query.withinKilometers('location', point, params.maxDistance / 1000);
        } else if (params.unit === 'mi') {
          query.withinMiles('location', point, params.maxDistance / 1609);
        }
      }

    } else {
      query.descending('createdAt');
    }

    query.include('categories');
    query.doesNotExist('deletedAt');

    return query.count();
  }

  loadFavorites(params: any = {}): Promise<Place[]> {

    const page = params.page || 0;
    const limit = params.limit || 100;

    const query = new Parse.Query(Place);
    query.equalTo('status', 'Approved');
    query.equalTo('likes', Parse.User.current());

    query.skip(page * limit);
    query.limit(limit);
    query.include('categories');
    query.doesNotExist('deletedAt');

    return query.find();
  }

  loadUserPlaces(params: any = {}): Promise<Place[]> {

    const page = params.page || 0;
    const limit = params.limit || 100;

    const query = new Parse.Query(Place);
    query.equalTo('user', Parse.User.current());
    query.skip(page * limit);
    query.limit(limit);
    query.include('categories');
    query.doesNotExist('deletedAt');
    query.descending('createdAt');

    return query.find();
  }

  loadStatistics(params: any = {}): Promise<{
    views: number;
    bookingSessions: number;
    likes: number; }> {
    return Parse.Cloud.run('getPlaceStatistics', params);
  }

  trackView(place: Place): Promise<any> {
    return Parse.Cloud.run('trackViewPlace', { placeId: place.id });
  }

  trackCall(place: Place): Promise<any> {
    return Parse.Cloud.run('trackCallPlace', { placeId: place.id });
  }

  placeSetToPrivate(placeId: string, password: string) {
    return Parse.Cloud.run('placeSetToPrivate', { placeId, password });
  }

  placeRemovePrivateAccess(placeId: string) {
    return Parse.Cloud.run('placeRemovePrivateAccess', { placeId });
  }

  placeDelist(placeId: string) {
    return Parse.Cloud.run('placeDelist', { placeId });
  }

  placeRelist(placeId: string) {
    return Parse.Cloud.run('placeRelist', { placeId });
  }

  get title(): string {
    return this.get('title');
  }

  set title(val) {
    this.set('title', val);
  }

  get description(): string {
    return this.get('description');
  }

  set description(val) {
    this.set('description', val);
  }

  get phone(): string {
    return this.get('phone');
  }

  set phone(val) {
    this.set('phone', val);
  }

  get website(): string {
    return this.get('website');
  }

  set website(val) {
    this.set('website', val);
  }

  get address(): string {
    return this.get('address');
  }

  set address(val) {
    this.set('address', val);
  }

  get canExtendBooking() {
    return this.get('canExtendBooking');
  }

  get category() {
    return this.get('category');
  }

  set category(val) {
    this.set('category', val);
  }

  get equipmentCategories() {
    return this.get('equipmentCategories');
  }

  get features() {
    return this.get('features');
  }

  get dietaryRestrictions() {
    return this.get('dietaryRestrictions');
  }

  get facility() {
    return this.get('facility');
  }

  set facility(val) {
    this.set('facility', val);
  }

  get capacity() {
    return this.get('capacity');
  }

  set capacity(val) {
    this.set('capacity', val);
  }

  get dineSafeCertificate() {
    return this.get('dineSafeCertificate');
  }

  get haccpCertificate() {
    return this.get('haccpCertificate');
  }

  get image() {
    return this.get('image');
  }

  set image(val) {
    this.set('image', val);
  }

  get images() {
    return this.get('images');
  }

  set images(val) {
    this.set('images', val);
  }

  get location() {
    return this.get('location');
  }

  set location(val) {
    const geoPoint = new Parse.GeoPoint({
      latitude: val.lat,
      longitude: val.lng
    });
    this.set('location', geoPoint);
  }

  get imageTwo() {
    return this.get('imageTwo');
  }

  get imageThree() {
    return this.get('imageThree');
  }

  get imageFour() {
    return this.get('imageFour');
  }

  get imageThumb() {
    return this.get('imageThumb');
  }

  get minimumBooking() {
    return this.get('minimumBooking');
  }

  set minimumBooking(val) {
    this.set('minimumBooking', val);
  }

  get bookingLeadTime() {
    return this.get('bookingLeadTime');
  }

  set bookingLeadTime(val) {
    this.set('bookingLeadTime', val);
  }

  get ratingCount() {
    return this.get('ratingCount');
  }

  get ratingTotal() {
    return this.get('ratingTotal');
  }

  get ratingAvg(): number {
    return this.get('ratingAvg');
  }

  get status() {
    return this.get('status');
  }

  get facebook() {
    return this.get('facebook');
  }

  get youtube() {
    return this.get('youtube');
  }

  get instagram() {
    return this.get('instagram');
  }

  get longDescription() {
    return this.get('longDescription');
  }

  get slug() {
    return this.get('slug');
  }

  get categories(): Category[] {
    return this.get('categories') || [];
  }

  get icon(): Parse.File {
    return this.get('icon');
  }

  get isFeatured(): boolean {
    return this.get('isFeatured');
  }

  get rating() {
    if (!this.ratingCount && !this.ratingTotal) {return 0;}
    return Math.round(this.ratingTotal / this.ratingCount);
  }

  get callCount(): number {
    return this.get('callCount') || 0;
  }

  get bookingSessionCount(): number {
    return this.get('bookingSessionCount') || 0;
  }

  get likeCount(): number {
    return this.get('likeCount') || 0;
  }

  get viewCount(): number {
    return this.get('viewCount') || 0;
  }

  get user(): User {
    return this.get('user');
  }

  get email(): string {
    return this.get('email');
  }

  get whatsapp() {
    return this.get('whatsapp');
  }

  get priceRange(): string {
    return this.get('priceRange');
  }

  get defaultRate(): number {
    return this.get('defaultRate');
  }

  set defaultRate(val: number) {
    this.set('defaultRate', val);
  }

  get placeRate() {
    return this.get('placeRate');
  }

  set placeRate(val) {
    this.set('placeRate', val);
  }

  get timeZone() {
    return this.get('timeZone');
  }

  set timeZone(timeZone: string) {
    this.set('timeZone', timeZone);
  }

  get taxRegionCode() {
    return this.get('taxRegionCode');
  }

  get insuranceRequired() {
    return this.get('insuranceRequired');
  }

  set insuranceRequired(val: number) {
    this.set('insuranceRequired', val);
  }

  get verificationChoice() {
    return this.get('verificationChoice');
  }

  get next2weeksAvailableHours() {
    return this.get('next2weeksAvailableHours');
  }

  get next2weeksHoursBooked() {
    return this.get('next2weeksHoursBooked');
  }

  get next2weeksPctBooked() {
    return this.get('next2weeksPctBooked');
  }

  get next2weeksDetails() {
    return this.get('next2weeksDetails');
  }

  get firstApprovedAt() {
    return this.get('firstApprovedAt');
  }

  get street(): string {
    return this.get('street');
  }

  set street(val: string) {
    this.set('street', val);
  }

  get street2(): string {
    return this.get('street2');
  }

  set street2(val: string) {
    this.set('street2', val);
  }

  get city(): string {
    return this.get('city');
  }

  set city(val: string) {
    this.set('city', val);
  }

  get province(): string {
    return this.get('province');
  }

  set province(val: string) {
    this.set('province', val);
  }

  get postalCode(): string {
    return this.get('postalCode');
  }

  set postalCode(val: string) {
    this.set('postalCode', val);
  }

  get country(): string {
    return this.get('country');
  }

  set country(val: string) {
    this.set('country', val);
  }
}

Parse.Object.registerSubclass('Place', Place);
