import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  UserBookingPrefFragment,
  FeatureItemFragment,
  DietaryItemFragment,
  EquipmentCategoryItemFragment,
  GetAvailablePlaceDietaryGQL,
  GetAvailablePlaceEquipmentCategoriesGQL,
  GetAvailablePlaceFeaturesGQL,
  GetBookingPreferencesGQL,
  UpdateBookingPreferencesGQL,
  UpdateUserBookingPreferenceFieldsInput,
  CreateDefaultUserBookingPrefGQL,
  Scalars,
} from 'src/generated/graphql';
import { LocationAddress } from '../interfaces/location-address';

@Injectable({
  providedIn: 'root'
})
export class Preference {

  private _unit                      = '';
  private _lang                      = '';
  private _isDarkModeEnabled         = false;
  private _location: LocationAddress = { address: '', latitude: 0, longitude: 0 };
  private _defaultBookingPref!: UserBookingPrefFragment;
  private _booking: UserBookingPrefFragment | null                      = null;
  private _allPlaceEquipmentCategories: EquipmentCategoryItemFragment[] = [];
  private _allPlaceFeatures: FeatureItemFragment[]                      = [];
  private _allPlaceDietary: DietaryItemFragment[]                       = [];

  private readonly _bookingPrefsChanged = new BehaviorSubject<UserBookingPrefFragment | null>(null);

  constructor(
    private _getBookingPreferences: GetBookingPreferencesGQL,
    private _setBookingPreferences: UpdateBookingPreferencesGQL,
    private _getAvailablePlaceFeatures: GetAvailablePlaceFeaturesGQL,
    private _getAvailablePlaceEquipmentCategories: GetAvailablePlaceEquipmentCategoriesGQL,
    private _getAvailablePlaceDietary: GetAvailablePlaceDietaryGQL,
    private _createDefaultPrefs: CreateDefaultUserBookingPrefGQL,

  ) {
    // All Features List
    this._allPlaceFeatures = [];

    // All Equipment List
    this._allPlaceEquipmentCategories = [];

    // All Dietary List
    this._allPlaceDietary = [];
    
    // set default booking preferences:
    this._defaultBookingPref = {
      objectId:       '',
      updatedAt:      null,
      createdAt:      null,
      productionTime: 5,
      teamCount:      2,
      equipmentCategories: {edges: []},
      features: {edges: []},
      dietary: {edges: []},
    };

    this.booking = this._defaultBookingPref;
  }

  loadUserBookingPreferences(userId: string): Promise<UserBookingPrefFragment> {
    return new Promise((resolve, reject) => {
      try {
        // Changes this to a refecth because the user preferences were being cached
        this._getBookingPreferences.watch().refetch({ userId })
          .then(({ data }) => {
            this._booking = data.user.bookingPreference || {...this._defaultBookingPref};
            resolve(this._booking);
          })
          .catch(error => reject(error));
      } catch (error) {
        reject(error);
      }
    });
  }

  updateUserBookingPreferences(
    prefId: string,
    fields: UpdateUserBookingPreferenceFieldsInput
  ) {
    return this._setBookingPreferences.mutate({
      preferenceId: prefId,
      fields
    }).subscribe((resp) => {
      this.booking = resp.data?.updateUserBookingPreference?.userBookingPreference  || {...this._defaultBookingPref};
    }, (err) => {console.error(err);});
  }

  loadAllAvailableEquipmentCategories(): Promise<EquipmentCategoryItemFragment[]> {
    return new Promise((resolve, reject) => {
      try {
        this._getAvailablePlaceEquipmentCategories.watch().valueChanges.subscribe((response) => {
          this._allPlaceEquipmentCategories = response.data.equipmentCategories.edges as EquipmentCategoryItemFragment[];
          resolve(this._allPlaceEquipmentCategories);
        });
      } catch (error) {
        reject(error);
      }
    });
  }


  loadAllAvailableFeatures(): Promise<FeatureItemFragment[]> {
    return new Promise((resolve, reject) => {
      try {
        this._getAvailablePlaceFeatures.fetch().subscribe((response) => {
          this._allPlaceFeatures = response.data.features.edges as FeatureItemFragment[];
          resolve(this._allPlaceFeatures);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  loadAllAvailableDietary(): Promise<DietaryItemFragment[]> {
    return new Promise((resolve, reject) => {
      try {
        this._getAvailablePlaceDietary.watch().valueChanges.subscribe((response) => {
          this._allPlaceDietary = response.data.dietaryRestrictions.edges as DietaryItemFragment[];
          resolve(this._allPlaceDietary);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  setBookingPrefsToDefault(userId: Scalars['ID']): Promise<UserBookingPrefFragment> {
    return new Promise((resolve, reject) => {
      try {
        this._createDefaultPrefs.mutate({userId}).subscribe((res) => {
          this.booking = res.data?.updateUser?.user.bookingPreference || {...this._defaultBookingPref};
          resolve(this._booking as UserBookingPrefFragment);
        }, (err) => {
          reject(err);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  resetBookingPrefs() {
    this.booking = this._defaultBookingPref;
  }

  get bookingPrefsChanged() {
    return this._bookingPrefsChanged;
  }

  get allPlaceFeatures() {
    return this._allPlaceFeatures;
  }

  get allPlaceEquipmentCategories() {
    return this._allPlaceEquipmentCategories;
  }

  get allPlaceDietary() {
    return this._allPlaceDietary;
  }

  get booking() {
    return this._booking as UserBookingPrefFragment;
  }

  set booking(prefs: UserBookingPrefFragment) {
    this._booking = prefs;
    this._bookingPrefsChanged.next(this._booking);
  }

  get bookingDefault() {
    return this._defaultBookingPref;
  }

  get unit() {
    return this._unit;
  }

  set unit(val) {
    this._unit = val;
  }

  get lang() {
    return this._lang;
  }

  set lang(val) {
    this._lang = val;
  }

  get isDarkModeEnabled() {
    return this._isDarkModeEnabled;
  }

  set isDarkModeEnabled(val) {
    this._isDarkModeEnabled = val;
  }

  get location() {
    return this._location;
  }

  set location(val) {
    this._location = val;
  }
}
