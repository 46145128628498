import { Injectable } from '@angular/core';
import * as Parse from 'parse';

@Injectable({
  providedIn: 'root'
})
export class Review extends Parse.Object {

  constructor() {
    super('Review');
  }

  static getInstance() {
    return this;
  }

  loadUserReviewForPlace(user: Parse.User, placeId: string): Promise<Review> {
    return new Promise((resolve, reject) => {
      const query = new Parse.Query(Review);
      query.equalTo('user', user);
      query.equalTo('place', {
        __type: 'Pointer',
        className: 'Place',
        objectId: placeId
      });

      query.first().then((data: Review | undefined) => resolve(data || new Review()), error => reject(error)
      );
    });
  }

  load(params: any = {}): Promise<Review[]> {

    return new Promise((resolve, reject) => {

      const query = new Parse.Query(Review);

      if (params.place) {
        if (typeof params.place === 'string') {
          query.equalTo('place', {
            __type: 'Pointer',
            className: 'Place',
            objectId: params.place
          });
        } else {
          query.equalTo('place', params.place);
        }
      }

      if (params.user) {
        query.equalTo('user', params.user);
      }

      query.descending('createdAt');
      query.include(['user', 'place']);
      query.doesNotExist('deletedAt');

      const limit = params.limit || 100;
      const page = params.page || 0;
      query.skip(page * limit);
      query.limit(limit);

      query.find().then((data: Review[]) => resolve(data), error => reject(error));
    });
  }

  get rating(): number {
    return this.get('rating');
  }

  set rating(val: number) {
    this.set('rating', val);
  }

  get comment(): string {
    return this.get('comment');
  }

  set comment(val: string) {
    this.set('comment', val);
  }

  get place(): any {
    return this.get('place');
  }

  get user(): any {
    return this.get('user');
  }

  get status(): string {
    return this.get('status');
  }

  get overall(): number {
    return this.get('overall');
  }

  set overall(val: number) {
    this.set('overall', val);
  }

  get cleanliness(): number {
    return this.get('cleanliness');
  }

  set cleanliness(val: number) {
    this.set('cleanliness', val);
  }

  get communication(): number {
    return this.get('communication');
  }

  set communication(val: number) {
    this.set('communication', val);
  }
}

Parse.Object.registerSubclass('Review', Review);
