import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayInArray'
})
export class ArrayInArrayPipe implements PipeTransform {

  transform(inputArray: any, checkAgainstArray: any[], parsePropId: string, checkAgainstProp: string): any[] {
    return inputArray.filter((item: any) => checkAgainstArray.find(checkAgainstItem => checkAgainstItem[checkAgainstProp] === item[parsePropId] || item.get(parsePropId)));
  }

}
