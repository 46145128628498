import { Pipe, PipeTransform } from '@angular/core';
import { CalendarDate } from '../pages/booking-cart/components/cart-dates-and-times/booking-calendar/calendar-date';
import { ICalendarDate } from '../components-standalone/calendar/calendar.component';
import { DateTime } from 'luxon';

@Pipe({
  name: 'isBookingDateUnavailable'
})
export class IsBookingDateUnavailablePipe implements PipeTransform {

  transform(date: CalendarDate | ICalendarDate, month: number, minBookingTime: number): boolean {
    const day = DateTime.now().day;
    return date.month === month && (
      date.isTotalTimeTooShort
        || date.day >= day && date.maxConsecutiveHours < minBookingTime
        || date.day >  day && date.slots.length === 0
    );
  }
}
