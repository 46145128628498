import { Pipe, PipeTransform } from '@angular/core';
import { Place } from '../services/place.service';
import { Preference } from '../services/preference.service';
import { PlacePrefCacheService } from '../services/place-pref-cache.service';
import { User } from '../services/user.service';

export interface IPrefInfo {
  match: number;
  color: string;
}

@Pipe({
  name: 'userPlacePrefMatch'
})
export class UserPlacePrefMatchPipe implements PipeTransform {

  private readonly _NO_USER_PREFS    = -1;
  private readonly _COLOR_GREEN_500  = '#377C23';
  private readonly _COLOR_GREY_800   = '#B0B093';

  constructor(
    private _ps: Preference,
    private _placePrefCacheService: PlacePrefCacheService,
  ) {}

  transform(place: Place): IPrefInfo {
    // If the user is NOT logged in or has no preferences, return the original array
    const prefs = this._ps.booking;
    const user  = User.getCurrent();
    if (!user.username || !prefs || !prefs.objectId) { return { match: this._NO_USER_PREFS, color: this._COLOR_GREY_800 }; }

    // Get from cache (if it doesn't already exist in the cache, it adds it)
    const match = Math.round(this._placePrefCacheService.fromCache(place.id, place, prefs).matchesOverallWeighted * 100);
    const color = match === 0
      ? this._COLOR_GREY_800
      : this._COLOR_GREEN_500;

    return { match, color };
  }

}
