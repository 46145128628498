import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNoEmojis]'
})
export class NoEmojisDirective implements OnDestroy, OnInit {

  private _sub = Subscription.EMPTY;

  constructor(
    private _formControl: NgControl,
  ) {}

  ngOnInit(): void {
    if (!this._formControl) {return;}
    
    this._sub = this._formControl.control?.valueChanges.subscribe(() => {
      const pattern = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;
      if (this._formControl.control?.value?.match(pattern)) {
        this._formControl.control.patchValue(this._formControl.control.value.replace(pattern, ''));
      }
    }) || Subscription.EMPTY;
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }
}
