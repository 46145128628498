// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 85vh;
}
:host .container ion-icon {
  font-size: 64px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImVtcHR5LXZpZXcuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGFBQUE7RUFDQSxtQkFBQTtFQUNBLHVCQUFBO0VBQ0Esa0JBQUE7RUFDQSxZQUFBO0FBQ0Y7QUFFSTtFQUNFLGVBQUE7QUFBTiIsImZpbGUiOiJlbXB0eS12aWV3LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICB0ZXh0LWFsaWduOiBjZW50ZXI7XG4gIGhlaWdodDogODV2aDtcblxuICAuY29udGFpbmVyIHtcbiAgICBpb24taWNvbiB7XG4gICAgICBmb250LXNpemU6IDY0cHg7XG4gICAgfVxuICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/empty-view/empty-view.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,YAAA;AACF;AAEI;EACE,eAAA;AAAN;AACA,4jBAA4jB","sourcesContent":[":host {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  height: 85vh;\n\n  .container {\n    ion-icon {\n      font-size: 64px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
