import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BasePageModal } from 'src/app/pages/base-page-modal/base-page-modal';
import { IModalDetails } from '../i-modal-details';
import { ModalActions } from '../modal-actions';

@Component({
  selector: 'app-modal-content-only',
  templateUrl: './modal-content-only.page.html',
  styleUrls: ['./modal-content-only.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalContentOnlyPage extends BasePageModal implements OnInit {

  @Input() modalDetails!: IModalDetails;

  safeHtml: SafeHtml | null = null;

  constructor(
    injector: Injector,
    private _sanitizer: DomSanitizer,
    private _changeRef: ChangeDetectorRef
  ) {
    super(injector);
  }

  ngOnInit() {
    this.safeHtml = this._sanitizer.bypassSecurityTrustHtml(this.modalDetails.content);
    this._changeRef.markForCheck();
  }

  buttonClick(action: string) {
    this.modalCtrl.dismiss({ action });
  }

  closeModal() {
    this.modalCtrl.dismiss({ action: ModalActions.GO_BACK });
  }
}
