import { Pipe, PipeTransform } from '@angular/core';
import { PreferenceMatchCalculator } from '../helpers/preference-match-calculator';
import { LocationAddress } from '../interfaces/location-address';
import { PlacePrefCacheService } from '../services/place-pref-cache.service';
import { Place } from '../services/place.service';
import { Preference } from '../services/preference.service';
import { User } from '../services/user.service';
import { IsPlaceNewPipe } from './is-place-new.pipe';

interface IPlaceMatchInfo {
  place: Place;
  matches: PreferenceMatchCalculator;
}

/**
 * placeOrderByUserPrefs pipe
 * - Attempts to sort the place list based on user perferences
 * - If a preference match is equal between places, you can fall-back
 *   to use distance sorting by passing in the location property (2nd param)
 * - If a location is not included OR the distance matches, it sorts by title
 * - Usage example ... *ngFor="let place of places | placeOrderByUserPrefs : location"
 */
@Pipe({
  name: 'placeOrderByUserPrefs'
})
export class PlaceOrderByUserPrefsPipe implements PipeTransform {

  constructor(
    private _ps: Preference,
    private _placePrefCacheService: PlacePrefCacheService,
    private _isPlaceNewPipe: IsPlaceNewPipe,
  ) {}

  transform(places: Place[], location?: LocationAddress): Place[] {
    // If the user is NOT logged in or has no preferences, return the original array
    const prefs = this._ps.booking;
    const user  = User.getCurrent();
    if (!user.username || !prefs || !prefs.objectId) {return places;}

    // Get the prefs and matches for each place
    const placeMatches: IPlaceMatchInfo[] = [];
    places.forEach((place) => {
      // Get from cache (if it doesn't already exist in the cache, it adds it)
      const matches = this._placePrefCacheService.fromCache(place.id, place, prefs);
      // Add the match info to the array
      placeMatches.push({ place, matches });
    });

    // Sort the array based on the matches
    const unit = this._ps.unit;
    placeMatches.sort((a: IPlaceMatchInfo, b: IPlaceMatchInfo) => this._orderByPreferences(a, b, location, unit));

    // Return the place portion of the sorted array
    return placeMatches.map(placeMatch => placeMatch.place);
  }

  /**
   * Sorts by preference matching
   * - If the preferences are close OR either places doesn't have availability, we sort by availableHours
   * - If the preferences are NOT close, we sort the higher match first
   * @param a 
   * @param b 
   * @returns 
   */
  private _orderByPreferences(a: IPlaceMatchInfo, b: IPlaceMatchInfo, location?: LocationAddress, unit?: string) {
    const order = a.matches.compare(b.matches, true);
    if (order >= -.1 && order <= .1) {
      // The preference match is close, so check the distance
      return this._orderByDistance(a, b, location, unit);
    }
    return order;
  }

  /**
   * Sorts by distance
   * - If the place is closer, sort it first
   * - If the places are the same distance, sort by available hours
   * @param a 
   * @param b 
   * @returns 
   */
  private _orderByDistance(a: IPlaceMatchInfo, b: IPlaceMatchInfo, location?: LocationAddress, unit?: string) {
    if (location && unit) {
      const aDist = parseFloat(a.place.distance(location, unit) || '0');
      const bDist = parseFloat(b.place.distance(location, unit) || '0');
      const order = aDist === bDist ? 0 : aDist < bDist ? -1 : 1;
      if (order !== 0) {
        return order;
      }
    }
    return this._orderByAvailableHours(a, b);
  }

  /**
   * Sorts by availability
   * - If the available hours are greater than the other, sort it first
   * - If the hours are equal (or within a set limit), sort by title
   * @param a 
   * @param b 
   */
  private _orderByAvailableHours(a: IPlaceMatchInfo, b: IPlaceMatchInfo) {
    const order = a.matches.compareAvailableHours(b.matches);
    if (order === 0) {
      return this._orderByTitle(a, b);
    }
    return order;
  }

  /**
   * Sorts by title
   * - sort alphabetically
   * @param a 
   * @param b 
   * @returns 
   */
  private _orderByTitle(a: IPlaceMatchInfo, b: IPlaceMatchInfo) {
    return a.place.title < b.place.title ? -1 : 1;
  }
}

