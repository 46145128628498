// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item {
  --ion-color-base: var(--ion-color-white-tint);
}

ion-thumbnail {
  min-width: 60px !important;
  min-height: 60px !important;
}
ion-thumbnail img {
  border-radius: 4px;
  width: 60px !important;
  height: 60px !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImluZm8td2luZG93LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSw2Q0FBQTtBQUNGOztBQUVBO0VBQ0UsMEJBQUE7RUFDQSwyQkFBQTtBQUNGO0FBQ0U7RUFDRSxrQkFBQTtFQUNBLHNCQUFBO0VBQ0EsdUJBQUE7QUFDSiIsImZpbGUiOiJpbmZvLXdpbmRvdy5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiaW9uLWl0ZW0ge1xuICAtLWlvbi1jb2xvci1iYXNlOiB2YXIoLS1pb24tY29sb3Itd2hpdGUtdGludCk7XG59XG5cbmlvbi10aHVtYm5haWwge1xuICBtaW4td2lkdGg6IDYwcHggIWltcG9ydGFudDtcbiAgbWluLWhlaWdodDogNjBweCAhaW1wb3J0YW50O1xuXG4gIGltZyB7XG4gICAgYm9yZGVyLXJhZGl1czogNHB4O1xuICAgIHdpZHRoOiA2MHB4ICFpbXBvcnRhbnQ7XG4gICAgaGVpZ2h0OiA2MHB4ICFpbXBvcnRhbnQ7XG4gIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components/info-window/info-window.scss"],"names":[],"mappings":"AAAA;EACE,6CAAA;AACF;;AAEA;EACE,0BAAA;EACA,2BAAA;AACF;AACE;EACE,kBAAA;EACA,sBAAA;EACA,uBAAA;AACJ;AACA,gqBAAgqB","sourcesContent":["ion-item {\n  --ion-color-base: var(--ion-color-white-tint);\n}\n\nion-thumbnail {\n  min-width: 60px !important;\n  min-height: 60px !important;\n\n  img {\n    border-radius: 4px;\n    width: 60px !important;\n    height: 60px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
