import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isHourAm'
})
export class IsHourAmPipe implements PipeTransform {

  transform(hour: string): boolean {
    const h = parseInt(hour, 10);
    return h < 12;
  }

}
