// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.disabled {
  color: var(--ion-color-grey-400);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFkZHJlc3Mtb2JmdXNjYXRvci5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLGFBQUE7RUFDQSxtQkFBQTtFQUNBLHVCQUFBO0FBQ0o7O0FBRUE7RUFDSSxnQ0FBQTtBQUNKIiwiZmlsZSI6ImFkZHJlc3Mtb2JmdXNjYXRvci5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5idG4tY29udGFpbmVyIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiByb3c7XG4gICAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG59XG5cbi5kaXNhYmxlZCB7XG4gICAgY29sb3I6IHZhcigtLWlvbi1jb2xvci1ncmV5LTQwMCk7XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/components/address-obfuscator/address-obfuscator.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,gCAAA;AACJ;AACA,4hBAA4hB","sourcesContent":[".btn-container {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n}\n\n.disabled {\n    color: var(--ion-color-grey-400);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
