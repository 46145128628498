// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content > div {
  margin: 0 auto;
  max-width: 400px;
  text-align: center;
}

ion-select {
  width: 126px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJvb2tpbmctY2xvc2VkLWRhdGVzLWFuZC10aW1lcy5wYWdlLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxjQUFBO0VBQ0EsZ0JBQUE7RUFDQSxrQkFBQTtBQUNKOztBQUNBO0VBQ0ksWUFBQTtBQUVKIiwiZmlsZSI6ImJvb2tpbmctY2xvc2VkLWRhdGVzLWFuZC10aW1lcy5wYWdlLnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJpb24tY29udGVudCA+IGRpdiB7XG4gICAgbWFyZ2luOiAwIGF1dG87XG4gICAgbWF4LXdpZHRoOiA0MDBweDtcbiAgICB0ZXh0LWFsaWduOiBjZW50ZXI7XG59XG5pb24tc2VsZWN0IHtcbiAgICB3aWR0aDogMTI2cHg7XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/pages/booking-closed-dates-and-times/booking-closed-dates-and-times.page.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,gBAAA;EACA,kBAAA;AACJ;;AACA;EACI,YAAA;AAEJ;AACA,4gBAA4gB","sourcesContent":["ion-content > div {\n    margin: 0 auto;\n    max-width: 400px;\n    text-align: center;\n}\nion-select {\n    width: 126px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
