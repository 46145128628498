import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'empty-view',
  templateUrl: './empty-view.html',
  styleUrls: ['./empty-view.scss']
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class EmptyView {

  @Input() text = '';
  @Input() icon = 'alert';

  constructor() {}

}
