import { Component, Input, OnInit, inject } from '@angular/core';
import { ComponentBase } from 'src/app/components-standalone/component-base';
import { CalApiService } from 'src/app/services/cal-api.service';
import { User } from 'src/app/services/user.service';


// TODO: - Check if user has accounts on CAL API
//      - If user has accounts

export interface iCals { [key: string]: any[] }

@Component({
  selector: 'app-calendar-sync',
  templateUrl: './calendar-sync.component.html',
  styleUrls: ['./calendar-sync.component.scss'],
})
export class CalendarSyncComponent extends ComponentBase implements OnInit {
  @Input() listingId: string = '';
  isModalOpen = false;
  selectedProvider?: string;
  hasConnectedAccounts = false; // Set to true if accounts are connected
  selectedWriteCalendar?: any; // ID of the selected calendar to write new events to
  previousSyncCalendars: any[] = []; // IDs of previously synced calendars
  selectedSyncCalendars: any[] = []; // IDs of selected calendars to sync
  calendars: iCals = {};
  user = User.getCurrent();
  private _calApiService = inject(CalApiService);
  get providers() { return Object.keys(this.calendars); }

  async ngOnInit(): Promise<void> {
    // Check if user has accounts on CAL API
    const checkForAccounts = await this._calApiService.fetchAccounts(this.user.id);
    if (checkForAccounts.hasAccounts) {
      await this._fetchAccountAndCalendars();
    }
  }

  private async _fetchAccountAndCalendars() {
    const calendars = await this._calApiService.getCalendarsByExternalId(this.listingId);

    for (const calendar of calendars) {
      if (!this.calendars[calendar.provider]) {
        this.calendars[calendar.provider] = [];
      }
      
      this.calendars[calendar.provider].push(calendar);
    }

    for (const provider of this.providers) {
      this.selectedSyncCalendars.push(...this.calendars[provider].filter(calendar => calendar.synced));
      const writeCalendar = await this._calApiService.getWritableCalendar(this.listingId);
      if (writeCalendar) this.selectedWriteCalendar = this.calendars[provider].find(calendar => calendar.id === writeCalendar.id);
      else this.selectedWriteCalendar = null;
    }

    this.previousSyncCalendars = this.selectedSyncCalendars;

    this.hasConnectedAccounts = true;
  }

  openModal() {
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
  }

  async openCalendar(provider: string) {
    const {id, name, email} = User.getCurrent();
  
    this.selectedProvider = provider;
    // TODO: Remove in next patch by putting this logic in the service
    switch(provider) {
      case 'outlook': {
        this.showToast('Outlook is coming soon, we\'ve recorded your interest!');
        return;
      }
      case 'icloud': {
        this.showToast('iCloud is coming soon, we\'ve recorded your interest!');
        return;
      }
    }
  
    // Initiate authentication flow for the selected calendar type
    const {authUrl} = await this._calApiService.createUser({
      provider,
      external_user_id: id,
      name,
      email,
    });
  
    // open a new window with the authUrl
    if (!authUrl) {
      this.showAlert('Error! Failed to get authentication URL');
      return;
    }
  
    const authWindow = window.open(authUrl, '_blank');
  
    // Poll the auth window to check if it has been closed
    const newCalendars = await this.pollAuthWindow(authWindow);
  
    this.calendars[provider] = await Promise.all(newCalendars.map(async (calendar: any) => {
      return await calendar;
    }));

    this.selectedSyncCalendars = this.calendars[provider].filter(calendar => calendar.synced);
    this.previousSyncCalendars = this.selectedSyncCalendars;

    if (this.calendars[provider].length > 0) {
      this.hasConnectedAccounts = true;
    }
  
    // Update the UI to reflect the new calendars
    this.closeModal();
  }

  // async syncCalendars() {
  //   // Get the selected calendars
  //   const selectedCalendars = this.selectedSyncCalendars;

  //   // Call the API to sync the selected calendars
  //   await this._calApiService.syncCalendars(selectedCalendars);

  //   // Update the UI to reflect the synced calendars
  //   // ...

  //   // Close the modal
  //   this.closeModal();
  // }

  // async disconnectCalendar(calendarId: string) {
  //   // Call the API to disconnect the calendar
  //   await this._calApiService.deleteCalendar(calendarId);

  //   // Remove the calendar from the list
  //   this.calendars = this.calendars.filter(calendar => calendar.id !== calendarId);

  //   // Update the UI to reflect the disconnected calendar
  //   // ...

  //   // Set hasConnectedAccounts to false if no more calendars are connected
  //   if (this.calendars.length === 0) {
  //     this.hasConnectedAccounts = false;
  //   }

  //   // Close the modal
  //   this.closeModal();
  // }

  async connectCalendar(provider: string) {
    // Open the modal
    this.openModal();

    // Set the selected calendar type
    this.selectedProvider = provider;

    // Call the API to get the calendars for the selected type
    const newCalendars = await this._calApiService.getCalendarsByProvider(provider);

    // Add the new calendars to the existing ones
    this.calendars = {...this.calendars, ...newCalendars};

    // Update the UI to reflect the new calendars
    // ...

    // Set hasConnectedAccounts to true if not already
    if (!this.hasConnectedAccounts && this.calendars[provider].length > 0) {
      this.hasConnectedAccounts = true;
    }

    // Close the modal
    this.closeModal();

    // You can now use 'this.calendars' to present to the customer or further process
  }

  async pollAuthWindow(authWindow: Window | null): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      const int = setInterval(async () => {
        try {
          if (authWindow?.closed) {
            // Call the API to get the calendars
            const newCalendars = await this._calApiService.getCalendarsByExternalId(this.listingId);
            clearInterval(int);
            resolve(newCalendars);
          }
        } catch (error) {
          clearInterval(int);
          reject(error);
        }
      }, 500);
    });
  }

  async updateSyncedCalendars() {
    const addedCalendars = this.selectedSyncCalendars.filter(c => !this.previousSyncCalendars.some(pc => pc.id === c.id));
    addedCalendars.forEach(async calendar => await this._calApiService.setReadableCalendar(calendar.id, this.listingId));
    const removedCalendars = this.previousSyncCalendars.filter(c => !this.selectedSyncCalendars.some(sc => sc.id === c.id));
    removedCalendars.forEach(async calendar => await this._calApiService.removeReadableCalendar(calendar.id, this.listingId));
    this.previousSyncCalendars = this.selectedSyncCalendars;
    this.showToast('Calendars synced!');
  }

  async updateWriteCalendar() {
    if (this.selectedWriteCalendar) {
      await this._calApiService.setWriteableCalendar(this.listingId, this.selectedWriteCalendar.id);

      // Set the writeable calendar to be readable as well
      await this._calApiService.setReadableCalendar(this.selectedWriteCalendar.id, this.listingId);
      this.selectedSyncCalendars.push(this.selectedWriteCalendar);
    }
    else await this._calApiService.removeWriteableCalendar(this.listingId);
    this.showToast('Write Calendar updated and synced!');
  }

  async deleteAllAccounts() {
    await this._calApiService.deleteCalendarAccounts();
    this.hasConnectedAccounts = false;
    this.showToast('All accounts disconnected!');
    this.closeModal();
  }
}

