import { PreferenceMatch } from '../components/section-preview-card/section-preview-card.model';

export class PreferenceMatchCalculator {

  private readonly _HOUR_DIFF_TO_IGNORE         = 5;
  private readonly _NUM_ITEMS                   = 5;

  private readonly _WEIGHT_CAPACITY             = 1;
  private readonly _WEIGHT_PRODUCTION_TIME      = 2;
  private readonly _WEIGHT_DIETARY_RESTRICTIONS = 2;
  private readonly _WEIGHT_EQUIPMENT_CATEGORIES = 3;
  private readonly _WEIGHT_FEATURES             = 1;

  capacity            = new PreferenceMatch('', false);
  productionTime      = new PreferenceMatch('', false);
  dietaryRestrictions = new PreferenceMatch('', false);
  equipmentCategories = new PreferenceMatch('', false);
  features            = new PreferenceMatch('', false);
  availableHours      = 0;
  availabilityByTime: { [key: string]: number | null } = {};

  private _userProductionTime = 0;

  constructor(
    userTeamCount:            number,
    userProductionTime:       number,
    userDietaryRestrictions:  any[],
    userEquipmentCategories:  any[],
    userFeatures:             any[],
    placeTeamCount:           number,
    placeProductionTime:      number,
    placeDietaryRestrictions: any[],
    placeEquipmentCategories: any[],
    placeFeatures:            any[],
    availableHours:           number,
    availabilityByTime:       any
  ) {
    this.capacity            = this._matchCapacity(placeTeamCount, userTeamCount);
    this.productionTime      = this._matchTime(placeProductionTime, userProductionTime);
    this.dietaryRestrictions = this._matchDietary(placeDietaryRestrictions, userDietaryRestrictions);
    this.equipmentCategories = this._matchEquipment(placeEquipmentCategories, userEquipmentCategories);
    this.features            = this._matchFeatures(placeFeatures, userFeatures);
    this.availableHours      = availableHours || 0;
    this.availabilityByTime  = availabilityByTime;
    this._userProductionTime = userProductionTime;
  }

  /**
     * Returns an array containing the preference match items to
     * be used on the SectionPreviewCardComponent
     */
  get asArray() {
    return [
      this.productionTime,
      this.equipmentCategories,
      this.capacity,
      this.dietaryRestrictions,
      this.features
    ];
  }

  /**
     * Returns true when the place has available hours
     */
  get hasAvailability() {
    return this.availableHours > 0;
  }

  /**
     * Returns true is the prefs are a 100% match
     */
  get matchesAll() {
    return this.capacity.matches
            && this.productionTime.matches
            && this.dietaryRestrictions.matches
            && this.equipmentCategories.matches
            && this.features.matches;
  }

  /**
     * Returns the non-weighted match percentage
     */
  get matchesOverall() {
    return (
      this.capacity.percentMatch +
            this.productionTime.percentMatch +
            this.dietaryRestrictions.percentMatch +
            this.equipmentCategories.percentMatch +
            this.features.percentMatch
    ) / this._NUM_ITEMS;
  }

  /**
     * Returns the weighted match percentage
     */
  get matchesOverallWeighted() {
    return (
      this.capacity.percentMatch            * this._WEIGHT_CAPACITY +
            this.productionTime.percentMatch      * this._WEIGHT_PRODUCTION_TIME +
            this.dietaryRestrictions.percentMatch * this._WEIGHT_DIETARY_RESTRICTIONS +
            this.equipmentCategories.percentMatch * this._WEIGHT_EQUIPMENT_CATEGORIES +
            this.features.percentMatch            * this._WEIGHT_FEATURES
    ) / this._totalWeight;
  }

  private get _totalWeight() {
    return this._WEIGHT_CAPACITY + this._WEIGHT_PRODUCTION_TIME + this._WEIGHT_DIETARY_RESTRICTIONS + this._WEIGHT_EQUIPMENT_CATEGORIES + this._WEIGHT_FEATURES;
  }

  /**
     * Compares the matches from one of this class to another of this class
     * @param other 
     * @returns 
     */
  compare(other: PreferenceMatchCalculator, useWeighted: boolean = false) {
    // Check for full matches
    const thisMatchesAll  = this.matchesAll;
    const otherMatchesAll = other.matchesAll;
    if (thisMatchesAll  && !otherMatchesAll) {return -1;}
    if (otherMatchesAll && !thisMatchesAll)  {return 1;}

    // Check overall match percentage
    const thisOverall  = useWeighted ? this.matchesOverallWeighted  : this.matchesOverall;
    const otherOverall = useWeighted ? other.matchesOverallWeighted : other.matchesOverall;
    if (thisOverall  !== otherOverall) {return otherOverall - thisOverall;}

    // Tied
    return 0;
  }

  /**
     * Compares the available hours from one of this class to another of this class
     * @param other 
     * @returns 
     */
  compareAvailableHours(other: PreferenceMatchCalculator) {
    // Get the specific slot availability based on the user's production time
    const thisAvailability  = this.availabilityByTime  ? this.availabilityByTime['bt' + this._userProductionTime]   : null;
    const otherAvailability = other.availabilityByTime ? other.availabilityByTime['bt' + other._userProductionTime] : null;

    // If both are NULL or are equal do not order
    if (!thisAvailability && !otherAvailability || thisAvailability === otherAvailability) {
      return 0;
    }

    // If one or the other is NULL or 0, order by NOT null first
    if (thisAvailability  && !otherAvailability) {return -1;}
    if (!thisAvailability && otherAvailability)  {return 1;}

    // Esentially tied
    const diff = Math.abs((thisAvailability || 0) - (otherAvailability || 0));
    if (diff <= this._HOUR_DIFF_TO_IGNORE) {return 0;}

    return (thisAvailability || 0) > (otherAvailability || 0) ? -1 : 1;
  }

  /**
     * Calculates matches for capacity
     * @param sectionFeatures 
     * @param prefFeatures 
     * @returns 
     */
  private _matchCapacity(sectionCapacity: number, prefTeamSize: number) {
    const isMatch = sectionCapacity >= prefTeamSize;
    return new PreferenceMatch(
      'chef-hat',
      isMatch,
      2,
      isMatch,
      isMatch ? 1 : 0,
      'Staff'
    );
  }

  /**
     * Calculates matches for production time
     * @param sectionFeatures 
     * @param prefFeatures 
     * @returns 
     */
  private _matchTime(sectionMinTime: number, prefProductionTime: number) {
    const isMatch = sectionMinTime <= prefProductionTime;
    return new PreferenceMatch(
      'clock',
      isMatch,
      Math.min(prefProductionTime, 12),
      isMatch,
      isMatch ? 1 : 0,
      'Booking time'
    );
  }

  /**
     * Calculates matches for dietary restrictions
     * @param sectionFeatures 
     * @param prefFeatures 
     * @returns 
     */
  private _matchDietary(sectionDietary: any[], prefDietary: any[]) {
    const { isMatch, isPartial, pctMatch } = this._getArrayMatchInfo(prefDietary, sectionDietary);
    return new PreferenceMatch(
      'gluten-free',
      isMatch,
      undefined,
      isPartial,
      pctMatch,
      'Dietary restrictions'
    );
  }

  /**
     * Calculates matches for equipment categories
     * @param sectionFeatures 
     * @param prefFeatures 
     * @returns 
     */
  private _matchEquipment(sectionEquipment: any[], prefEquipment: any[]) {
    const { isMatch, isPartial, pctMatch } = this._getArrayMatchInfo(prefEquipment, sectionEquipment);
    return new PreferenceMatch(
      'oven',
      isMatch,
      undefined,
      isPartial,
      pctMatch,
      'Equipment'
    );
  }

  /**
     * Calculates matches for features
     * @param sectionFeatures 
     * @param prefFeatures 
     * @returns 
     */
  private _matchFeatures(sectionFeatures: any[], prefFeatures: any[]) {
    const { isMatch, isPartial, pctMatch } = this._getArrayMatchInfo(prefFeatures, sectionFeatures);
    return new PreferenceMatch(
      'kitchen',
      isMatch,
      undefined,
      isPartial,
      pctMatch,
      'Features'
    );
  }

  /**
     * Returns the match information for array type matching
     * - isMatch   : TRUE when all user prefs are available
     * - isPartial : TRUE when at least one user pref is available
     * - pctMatch  : % of user prefs that are available
     * @param prefs 
     * @param sectionHas 
     * @returns 
     */
  private _getArrayMatchInfo(prefs: any[], sectionHas: any[]) {
    const isMatch   = this._matchPrefsToSection(prefs, sectionHas);
    const isPartial = this._matchPartialPrefsToSection(prefs, sectionHas);
    const pctMatch  = isMatch ? 1 : this._matchPercentage(prefs, sectionHas);
    return { isMatch, isPartial, pctMatch };
  }

  /**
     * Returns true if the section contains ALL items in the user preferences
     * @param prefs 
     * @param sectionHas 
     * @returns 
     */
  private _matchPrefsToSection(prefs: any[], sectionHas: any[]) {
    if (!prefs || !sectionHas)            {return false;}
    if (prefs.length > sectionHas.length) {return false;}
    return prefs.every(pref => sectionHas.some(item => (item.objectId || item.id) === pref.node.objectId));
  }

  /**
     * Returns true if the section contains ANY items in the user preferences
     * @param prefs 
     * @param sectionHas 
     * @returns 
     */
  private _matchPartialPrefsToSection(prefs: any[], sectionHas: any[]) {
    if (!prefs || !sectionHas) {return false;}
    return prefs.some(pref => sectionHas.some(item => (item.objectId || item.id) === pref.node.objectId));
  }

  /**
     * Returns the percentage of matches from the user prefs
     * @param prefs 
     * @param sectionHas 
     * @returns 
     */
  private _matchPercentage(prefs: any[], sectionHas: any[]) {
    if (!prefs || !sectionHas) {return 0;}
    let matches = 0;
    prefs.forEach((pref) => {
      const index = sectionHas.findIndex(item => (item.objectId || item.id) === pref.node.objectId);
      if (index >= 0) {
        matches++;
      }
    });
    return matches / prefs.length;
  }
}
