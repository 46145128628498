export interface IQueryByPointer {
  __type:    string;
  className: string;
  objectId:  string;
}

export class Globals {
  static readonly PLACE_STATUS_APPROVED   = 'Approved';
  static readonly PLACE_STATUS_PENDING    = 'Pending';
  static readonly PLACE_STATUS_INCOMPLETE = 'Incomplete';
  static readonly PLACE_STATUS_REJECTED   = 'Rejected';
  static readonly PLACE_STATUS_EXPIRED    = 'Expired';
  static readonly PLACE_STATUS_PRIVATE    = 'Private';
  static readonly PLACE_STATUS_DELISTED   = 'Delisted';

  // V1 Payment Status
  static V1_STATUS_PAID                  = 'Paid';
  // V2 Payment Statuses
  static V2_STATUS_SAVED_CARD_PAID       = 'Payment success with saved card';
  // New-new (lol) status' using holds AND saved cards
  static V3_STATUS_READY_FOR_HOLD        = 'v3-Ready for hold';
  static V3_STATUS_HOLD_SUCCESS          = 'v3-Hold success';
  static V3_STATUS_HOLD_FAILURE          = 'v3-Hold failure';
  static V3_STATUS_HOLD_REMOVAL_SUCCESS  = 'v3-Hold removal success';
  static V3_STATUS_HOLD_REMOVAL_FAILURE  = 'v3-Hold removal failure';
  static V3_STATUS_PAYMENT_ATTEMPT       = 'v3-Payment attempt';
  static V3_STATUS_PAYMENT_SUCCESS       = 'v3-Payment success';
  static V3_STATUS_PAYMENT_FAILURE       = 'v3-Payment failure';
  static V3_STATUS_NO_CARD               = 'v3-No card on file';
  static V3_STATUS_MANUAL_REQUIRED       = 'v3-Manual payment required';
  static V3_STATUS_CANCELLED_BEFORE_HOLD = 'v3-Booking cancelled before hold';
  static V3_STATUS_CANCELLED_AFTER_HOLD  = 'v3-Booking cancelled after hold';


  static readonly I18N_HTML = '/assets/i18n/html/';
  static readonly I18N_JSON = '/assets/i18n/json/';

  static readonly SKIP_AFTER_SAVE  = 'skipAfterSave';
  static readonly SKIP_BEFORE_SAVE = 'skipBeforeSave';

  static getTermsAndConditionsUri(lang: string) {
    return Globals.I18N_HTML + 'terms/' + lang + '.html';
  }

  static getPrivacyPolicyUri(lang: string) {
    return Globals.I18N_HTML + 'privacy/' + lang + '.html';
  }

  static getProvincesUri(lang: string, country: string) {
    return Globals.I18N_JSON + 'provinces/' + country.toLowerCase() + '/' + lang + '.json';
  }

  static getMetadescription(lang: string) {
    return Globals.I18N_JSON + 'metadescriptions/' + lang + '.json';
  }

  static roundWithPrecision(value: number, precision: number = 0): string {
    const multiplier = Math.pow(10, precision);
    return (Math.round(value * multiplier) / multiplier).toFixed(precision);
  }

  static numberOnlyValidator(event: KeyboardEvent) {
    if (!/[0-9,]/.test(event.key)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  static queryByPointer(className: string, objectId: string): IQueryByPointer {
    return {
      __type: 'Pointer',
      className,
      objectId
    };
  }

  // TODO: We need to allow failed promises that involve the API to return an error if there is no API response
  static timedPromise(timeout: number, callback: (success: any, error: any) => any) {
    return new Promise((resolve, reject) => {
      // Set up the timeout
      const timer = setTimeout(() => {
        reject(new Error(`Promise timed out after ${timeout} ms`));
      }, timeout);

      // Set up the real work
      callback(
        (value: any) => {
          clearTimeout(timer);
          resolve(value);
        },
        (error: any) => {
          clearTimeout(timer);
          reject(error);
        }
      );
    });
  }
}
