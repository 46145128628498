import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter, AfterViewInit, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { PreferenceOptions } from './preference-options.model';
import { User } from '../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Preference } from 'src/app/services/preference.service';
import { TimePlusCleaningPipe } from 'src/app/pipes/time-plus-cleaning.pipe';
import { ProfileCompleteService } from 'src/app/services/profile-complete.service';
import { SectionPref } from './section-pref';
import { ToastSchedulerService } from 'src/app/services/toast-scheduler.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-preference-card',
  templateUrl: './preference-card.component.html',
  styleUrls: ['./preference-card.component.scss'],
  standalone: false,
})
export class PreferenceCardComponent implements OnInit, OnChanges, AfterViewInit {

  readonly MODE_PREF_SCREEN  = 'pref';
  readonly MODE_OTHER_SCREEN = 'other';

  @Input() openFromHidden = false;
  @Input() startOpen      = false;
  @Input() initialIndex   = -1;
  @Output() closed        = new EventEmitter<void>();


  mode = this.MODE_OTHER_SCREEN;
  user!: User;
  resp: any;
  bookingPreferences: Preference['booking'];

  equipmentPrefs: SectionPref[] = [];
  dietaryPrefs: SectionPref[]   = [];
  featurePrefs: SectionPref[]   = [];

  preferences: PreferenceOptions[] = [];
  currentlySelected = -1;
  isOpen            = false;
  selectedName      = '';
  titleText         = '';

  private _transClose       = '';
  private _transPrefAdded   = '';
  private _transPrefRemoved = '';
  private _transPrefUpdated = '';

  private _fnWhenComplete: (() => void) | null = null;

  @ViewChildren('segmentButton', { read: ElementRef }) private _segmentButtons!: QueryList<ElementRef>;

  constructor(
    protected translate: TranslateService,
    public preference: Preference,
    private _timePlusCleaningPipe: TimePlusCleaningPipe,
    private _profileCompletService: ProfileCompleteService,
    private _toastScheduler: ToastSchedulerService,
  ) {
    this.bookingPreferences = this.preference.booking || this.preference.bookingDefault;
  }

  async ngOnInit() {
    this.user = User.getCurrent();
    if (this.user.isAnonymous()) {return;}

    this._transClose       = await lastValueFrom(this.translate.get('CLOSE'));
    this._transPrefAdded   = await lastValueFrom(this.translate.get('PREF_ADDED'));
    this._transPrefRemoved = await lastValueFrom(this.translate.get('PREF_REMOVED'));
    this._transPrefUpdated = await lastValueFrom(this.translate.get('PREF_UPDATED'));
  }

  ngAfterViewInit() {
    if (this.user.isAnonymous()) {return;}
    this.preferences = [];
    this.preference
      .loadUserBookingPreferences(this.user.id)
      .then((preference) => {
        this.bookingPreferences = preference;
        // in order to allow for instant access to the translations
        this.translate.get('init').subscribe(() => {
          this.preferences = [
            {
              tab: 'hours',
              name: this.translate.instant('HOURS'),
              icon: 'hours',
              segmentButtonNumber: this._timePlusCleaningPipe.transform(this.bookingPreferences.productionTime),
              value: this.bookingPreferences.productionTime,
              unit: this.translate.instant('HOUR_BOOKINGS'),
              helperText: this.translate.instant('HOW_MANY_HOURS'),
            },
            {
              tab: 'equipment',
              name: this.translate.instant('EQUIPMENT'),
              icon: 'equipment',
              value: this.bookingPreferences.equipmentCategories.edges?.length || 0,
              unit: this.translate.instant('EQUIPMENT'),
              helperText: this.translate.instant('WHAT_EQUIPMENT'),
            },
            {
              tab: 'staff',
              name: this.translate.instant('STAFF'),
              icon: 'staff',
              value: this.bookingPreferences.teamCount,
              unit: this.translate.instant('STAFF'),
              helperText: this.translate.instant('HOW_MANY_STAFF'),
            },
            {
              tab: 'dietary',
              name: this.translate.instant('DIETARY'),
              icon: 'dietary',
              value: this.bookingPreferences.dietary.edges?.length || 0,
              unit: this.translate.instant('DIETARY'),
              helperText: this.translate.instant('WHAT_DIETARY'),
            },
            {
              tab: 'features',
              name: this.translate.instant('FEATURES'),
              icon: 'features',
              value: this.bookingPreferences.features.edges?.length || 0,
              unit: this.translate.instant('FEATURES'),
              helperText: this.translate.instant('WHAT_FEATURES'),
            },
          ];
        });

        this.setUpCard();
        if (this.initialIndex > -1) {
          this.clickedSegment(this.initialIndex);
        }
          
        if (this._fnWhenComplete) {
          // So the animation CSS will trigger correctly
          setTimeout(() => {
            if (this._fnWhenComplete) {
              this._fnWhenComplete();
            }
          }, 10);
        }

      }, err => console.error(err));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.startOpen && changes.startOpen.currentValue === true) {
      this.mode              = this.MODE_PREF_SCREEN;
      this.isOpen            = true;
      this.currentlySelected = 0;
      this._fnWhenComplete   = this.selectSegment;
    }
    if (changes.openFromHidden && changes.openFromHidden.currentValue === true) {
      this.isOpen          = false;
      this._fnWhenComplete = this.toggleOpen;
    }
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;

    // Set the initial open state to the first preference if a preference isn't already selected
    if (this.isOpen) {
      this.currentlySelected = Math.max(this.currentlySelected, 0);
      this.selectSegment();
    } else {
      this._resetNameAndTitle();
      this.closed.emit();
    }
  }

  selectSegment() {
    if (this.currentlySelected >= 0) {
      const pref        = this.preferences[this.currentlySelected];
      this.selectedName = pref.name;
      this.titleText    = pref.helperText;
    }
  }

  clickedSegment(index: number) {
    this.isOpen            = true;
    this.currentlySelected = index;
    this.selectSegment();
    // Ensure the tab item is in view
    setTimeout(() => {
      const segmentButton = this._segmentButtons.get(index) as ElementRef;
      // This does horizontal scrolling and won't break ion-content
      segmentButton.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, 450);
  }

  timeUpdated(productionTime: number) {
    this._updateSegmentButtonValue('hours', productionTime);
    this.preference.updateUserBookingPreferences(
      this.bookingPreferences.objectId, { productionTime },
    );
    this._profileCompletService.refreshProfileCompletion();
    this._showToast(this._transPrefUpdated + ': ' + productionTime);
  }

  equipmentUpdated(sectionPref: SectionPref) {
    const valChange = sectionPref.hasPref ? 1 : -1;
    this._updateSegmentButtonValue('equipment', valChange);
    this.preference.updateUserBookingPreferences(
      this.bookingPreferences.objectId, {
        equipmentCategories: this.getGraphQlAddOrRemove(sectionPref),
      });
    this._profileCompletService.refreshProfileCompletion();
    this._showToast(this._getMsg(valChange));
  }

  staffUpdated(teamCount: number) {
    this._updateSegmentButtonValue('staff', teamCount);
    this.preference.updateUserBookingPreferences(
      this.bookingPreferences.objectId, { teamCount },
    );
    this._profileCompletService.refreshProfileCompletion();
    this._showToast(this._transPrefUpdated + ': ' + teamCount);
  }

  dietaryUpdated(sectionPref: SectionPref) {
    const valChange = sectionPref.hasPref ? 1 : -1;
    this._updateSegmentButtonValue('dietary', valChange);
    this.preference.updateUserBookingPreferences(
      this.bookingPreferences.objectId, {
        dietary: this.getGraphQlAddOrRemove(sectionPref),
      },
    );
    this._profileCompletService.refreshProfileCompletion();
    this._showToast(this._getMsg(valChange));
  }

  featureUpdated(sectionPref: SectionPref) {
    const valChange = sectionPref.hasPref ? 1 : -1;
    this._updateSegmentButtonValue('features', valChange);
    this.preference.updateUserBookingPreferences(
      this.bookingPreferences.objectId, {
        features: this.getGraphQlAddOrRemove(sectionPref),
      },
    );
    this._profileCompletService.refreshProfileCompletion();
    this._showToast(this._getMsg(valChange));
  }

  private _updateSegmentButtonValue(tab: string, value: number) {
    const i = this.preferences.findIndex(el => el.tab === tab);

    switch (tab) {
      // Hours needs to show time with cleaning
      case 'hours':
        this.preferences[i].segmentButtonNumber = this._timePlusCleaningPipe.transform(value);
        this.preferences[i].value               = value;
        break;

      // Preferences button value represents an array
      case 'equipment':
      case 'features':
      case 'dietary':
        this.preferences[i].value += value;
        break;

      // Staff is displaying the actual staffCount value
      default:
        this.preferences[i].value = value;
        break;
    }
  }

  private _resetNameAndTitle() {
    this.selectedName = '';
    this.titleText = '';
  }

  getGraphQlAddOrRemove(sectionPref: SectionPref) {

    // If remove or add 
    switch (sectionPref.hasPref) {
      case false:
        return {
          remove: [sectionPref.objectId],
        };

      default:
        return {
          add: [sectionPref.objectId],
        };
    }
  }

  setUpCard() {
    // Add equipment categories from database
    const equipmentCategories = this.preference?.allPlaceEquipmentCategories;
    equipmentCategories.forEach((equipment) => {
      // Check if user has pref
      const node    = equipment.node;
      const hasPref = this.preference.booking.equipmentCategories.edges?.some(e => e?.node?.objectId === node?.objectId) || false;
      this.equipmentPrefs.push(
        new SectionPref(
          node?.objectId || '',
          node?.icon || '',
          node?.title || '',
          node?.deletedAt || '',
          hasPref,
        ),
      );
    });

    // Add features from database
    const features = this.preference?.allPlaceFeatures;
    features.forEach((feature) => {
      // Check if user has pref
      const node    = feature.node;
      const hasPref = this.preference.booking.features.edges?.some(e => e?.node?.objectId === node?.objectId);
      this.featurePrefs.push(
        new SectionPref(
          node?.objectId || '',
          node?.icon || '',
          node?.title || '',
          node?.deletedAt || '',
          hasPref,
        ),
      );
    });

    // Add equipment categories from database
    const dietaryRestrictions = this.preference?.allPlaceDietary;
    dietaryRestrictions.forEach((dietary) => {
      // Check if user has pref
      const node    = dietary.node;
      const hasPref = this.preference.booking.dietary.edges?.some(e => e?.node?.objectId === node?.objectId);
      this.dietaryPrefs.push(
        new SectionPref(
          node?.objectId || '',
          node?.icon || '',
          node?.title || '',
          node?.deletedAt || '',
          hasPref,
        ),
      );
    });
  }

  private _getMsg(valChange: number) {
    return valChange > 0 ? this._transPrefAdded : this._transPrefRemoved;
  }

  private async _showToast(message: string) {
    const buttons = [this._transClose];
    this._toastScheduler.add({ buttons, message });
  }
}
