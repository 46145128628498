import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localhostImageFix'
})
export class LocalhostImageFixPipe implements PipeTransform {

  transform(url: string): string {
    return url?.indexOf('localhost') > -1 ? 'https://api-dev.syzl.io/api/files' + url.substring(url.lastIndexOf('/', url.lastIndexOf('/') - 1)) : url;
  }

}
