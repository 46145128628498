import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, NgZone, OnChanges, OnDestroy, Output, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Place } from 'src/app/services/place.service';
import { PlaceListingFragment } from 'src/generated/graphql';

import Swiper, { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

import { Gallery, ImageItem, ThumbnailsPosition, ImageSize } from 'ng-gallery';
import { ImageOptimizationPipe } from 'src/app/pipes/image-optimization.pipe';

interface IPlaceLike {
  image: ImageItem;
  images: ImageItem[];
}

@Component({
  selector: 'app-swiper-carousel-and-lightbox',
  templateUrl: './swiper-carousel-and-lightbox.component.html',
  styleUrls: ['./swiper-carousel-and-lightbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ImageOptimizationPipe]
})
export class SwiperCarouselAndLightboxComponent implements OnChanges, AfterViewInit, OnDestroy {

  isSelectedImageIndex = 0;

  /**
   * Preferred input method is an single array of FileInfo objects
   */
  @Input() images: ImageItem[] = [];

  /**
   * If a Place is passed in instead of an image array, it will overwrite the
   * images array with the image and images in the Place object
   */
  @Input() place: Place | PlaceListingFragment | null = null;

  /**
   * If a PlaceLike object is passed in instead of an image array, it will overwrite the
   * images array with the image and images in the PlaceLike object
   */
  @Input() placeLike: IPlaceLike | null = null;

  /**
   * If non default swiper config options are required, they can be passed in using
   * Swiper's config settings
   */
  @Input() swiperConfig: SwiperOptions = {
    navigation: false,
    pagination: false
  };

  @Input() pushToHistory = true;
  @Input() borderRadius  = 10;
  @Input() height        = 200;

  /**
   * The slideChange event is wrapped in ngZone, so consumers of this component don't need
   * to wrap the event
   */
  @Output() slideChange = new EventEmitter<number>();

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  private _photoSwipe: any | null = null;

  private _popStateListener: ((e: PopStateEvent) => void) | null = null;
  private _win!: Window;

  constructor(
    private _el: ElementRef,
    private _renderer: Renderer2,
    private _ngZone: NgZone,
    public gallery: Gallery,
    private _imageOptimization: ImageOptimizationPipe,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this._win = this._document.defaultView as Window;
  }

  ngOnChanges(changes: SimpleChanges) {
    // Convert the data in the Place object to an images array
    if (changes.place) {
      // Fix for graphql images
      const placeValues = changes.place.currentValue;
      let tempArray = [];

      if ((this.place as { [key: string]: any }).__typename !== undefined) {
        this.place?.images.forEach((image: any) => {
          tempArray.push({
            src: this._imageOptimization.transform(image.value.url),
            thumb: this._imageOptimization.transform(image.value.url, 'w_119,h_88,c_scale')
          });
        });

      } else {
        tempArray = this.place?.images || [];
      }

      const placeImages = [{
        src: this._imageOptimization.transform(placeValues.image.url),
        thumb: this._imageOptimization.transform(placeValues.image.url, 'w_119,h_88,c_scale')
      }].concat(tempArray);

      this.images = placeImages.map(image =>
        new ImageItem({src: image.src, thumb: image.thumb, alt: 'Image of kitchen listing space'})
      );

      this.Lightbox();
    }

    // Convert the data in the PlaceLike object to an images array
    if (changes.placeLike) {
      const placeImage = this.placeLike?.image as ImageItem || null;
      
      this.images = ([] as ImageItem[]).concat(placeImage ? [placeImage] : []).concat(this.placeLike?.images || []);
    }

    // Ionic doesn't seem to recognize desktop on the first call ...
    // So we add a "bonus" call here so the next one works correctly
    this.swiperConfig = Object.assign({}, this.swiperConfig, {
      autoHeight: true,
      height:     this.height,
      navigation: false,
      pagination: this.images ? this.images.length > 1 : false,
    });
  }

  Lightbox() {
    const lightboxGalleryRef = this.gallery.ref('imageGallery');

    lightboxGalleryRef.setConfig({
      imageSize: ImageSize.Contain,
      thumbPosition: ThumbnailsPosition.Top,
    });

    lightboxGalleryRef.load(this.images);
  }

  /**
   * Autoheight doesn't 100% work, so we need to override it a bit here
   */
  ngAfterViewInit() {
    const el = this._el.nativeElement;
    const bg = el.querySelector('.bg-img');
    const windowWidth = this._win.innerWidth;
    if (windowWidth >= 768) {
      this.height = 448;
    }
    if (bg) {
      this._renderer.setStyle(bg, 'border-radius', this.borderRadius);
      this._renderer.setStyle(bg, 'height', this.height + 'px');
    }
    const wrapper = el.querySelector('.swiper-wrapper');
    if (wrapper) {
      this._renderer.setStyle(wrapper, 'height', this.height + 'px');
    }
  }

  ngOnDestroy() {
    if (this._popStateListener) {
      this._unsubscribeFromBackButton();
    }
  }

  /**
   * Called when the lightbox is opened
   * @param photoSwipe 
   */
  onGalleryInit(photoSwipe: any) {
    this._ngZone.run(() => {
      this._subscribeToBackButton(photoSwipe);
    });

  }

  /**
   * Called when the lightbox closes
   * - When the user clicking the X button, outside the image, or by clicking the browser back button
   */
  onGalleryDestroy() {
    this._ngZone.run(() => {
      if (this._popStateListener) {
        this._unsubscribeFromBackButton();
      }
    });
  }

  /**
   * Called when the Swiper object switches slides
   * @param swiper 
   */
  onSlideChange(swiper: Swiper[], indexName: string) {
    this._ngZone.run(() => {
      this.slideChange.emit(swiper[0].activeIndex);
      this.isSelectedImageIndex = swiper[0].activeIndex;
    });

    (this as { [key: string]: any })[indexName] = swiper[0].activeIndex;
  }

  /**
   * When the lightbox opens, we add a popstate listener incase the user clicks the back
   * button within the browser. Instead of causing a back navigation event, we'll simply
   * close the lightbox
   */
  private _subscribeToBackButton(photoSwipe: any) {
    if (this.pushToHistory) {
      this._win.history.pushState(null, this._document.title, this._win.location.href);
    }
    this._photoSwipe       = photoSwipe;
    this._popStateListener = (): void => {
      this._photoSwipe?.close();
      this._win.removeEventListener('popstate', this._popStateListener as () => void);
      this._photoSwipe       = null;
      this._popStateListener = null;
    };
    this._win.addEventListener('popstate', this._popStateListener);
  }

  slideNext() {
    this.swiper?.swiperRef.slideNext(100);
  }

  
  slidePrev() {
    this.swiper?.swiperRef.slidePrev(100);
  }

  /**
   * When the lightbox closes, we need to remove the popstate listener
   */
  private _unsubscribeFromBackButton() {
    if (this.pushToHistory) {
      this._win.history.back();
    }
  }
}
