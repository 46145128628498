// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-textarea {
  --border-radius: 4px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1vZGFsLWNvbnRlbnQtd2l0aC1yYWRpby1hbmQtdGV4dGFyZWEuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxvQkFBQTtBQUNKIiwiZmlsZSI6Im1vZGFsLWNvbnRlbnQtd2l0aC1yYWRpby1hbmQtdGV4dGFyZWEuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJpb24tdGV4dGFyZWEge1xuICAgIC0tYm9yZGVyLXJhZGl1czogNHB4O1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/modals/modal-content-with-radio-and-textarea/modal-content-with-radio-and-textarea.component.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;AACJ;AACA,wYAAwY","sourcesContent":["ion-textarea {\n    --border-radius: 4px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
