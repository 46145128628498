import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SyzlIconService } from 'src/app/services/syzl-icon.service';

@Component({
  selector: 'app-enhanced-empty-view',
  templateUrl: './enhanced-empty-view.component.html',
  styleUrls: ['./enhanced-empty-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnhancedEmptyViewComponent implements OnChanges {

  @Input() title        = '';
  @Input() text         = '';
  @Input() icon         = '';
  @Input() iconNum      = 0;
  @Input() iconType     = 'color';
  @Input() iconHeight   = 160;
  @Input() iconWidth    = 160;
  @Input() buttonText   = '';
  @Input() needCloseBtn = false;
  @Input() isModal      = false;
  @Input() textIsHtml   = false;
  @Output() buttonClick = new EventEmitter<void>();
  @Output() closeClick  = new EventEmitter<void>();

  constructor(
    private _modalCtrl: ModalController
  ) { }

  ngOnChanges() {
    if (this.iconNum === 0) {this.iconNum = SyzlIconService.NO_NUMBER;}
  }

  async onButtonClick() {

    if (!this.isModal) {
      this.buttonClick.emit();
    } else {
      await this._modalCtrl.dismiss({action: 'confirm'});
    }
  }

  onCloseClick() {
    this.closeClick.emit();
  }
}
