// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card {
  border-radius: 0;
}

ion-input {
  --color: var(--ion-color-dark);
}

ion-select,
ion-input {
  --placeholder-color: var(--ion-color-grey-300);
  --placeholder-opacity: 1;
}

ion-select::part(text) {
  color: var(--ion-color-dark);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1vZGFsLWFkZC1oZWxwZXIucGFnZS5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksZ0JBQUE7QUFDSjs7QUFFQTtFQUNJLDhCQUFBO0FBQ0o7O0FBRUE7O0VBRUksOENBQUE7RUFDQSx3QkFBQTtBQUNKOztBQUVBO0VBQ0ksNEJBQUE7QUFDSiIsImZpbGUiOiJtb2RhbC1hZGQtaGVscGVyLnBhZ2Uuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbImlvbi1jYXJkIHtcbiAgICBib3JkZXItcmFkaXVzOiAwO1xufVxuXG5pb24taW5wdXQge1xuICAgIC0tY29sb3I6IHZhcigtLWlvbi1jb2xvci1kYXJrKTtcbn1cblxuaW9uLXNlbGVjdCwgXG5pb24taW5wdXQge1xuICAgIC0tcGxhY2Vob2xkZXItY29sb3I6IHZhcigtLWlvbi1jb2xvci1ncmV5LTMwMCk7XG4gICAgLS1wbGFjZWhvbGRlci1vcGFjaXR5OiAxO1xufVxuXG5pb24tc2VsZWN0OjpwYXJ0KHRleHQpIHtcbiAgICBjb2xvcjogdmFyKC0taW9uLWNvbG9yLWRhcmspO1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/modals/modal-add-helper/modal-add-helper.page.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAEA;EACI,8BAAA;AACJ;;AAEA;;EAEI,8CAAA;EACA,wBAAA;AACJ;;AAEA;EACI,4BAAA;AACJ;AACA,4tBAA4tB","sourcesContent":["ion-card {\n    border-radius: 0;\n}\n\nion-input {\n    --color: var(--ion-color-dark);\n}\n\nion-select, \nion-input {\n    --placeholder-color: var(--ion-color-grey-300);\n    --placeholder-opacity: 1;\n}\n\nion-select::part(text) {\n    color: var(--ion-color-dark);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
