// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card {
  border-radius: 0;
}

::ng-deep ion-textarea .label-text {
  color: var(--ion-color-dark-shade);
}

ion-textarea {
  --color: var(--ion-color-dark);
}

.invalid {
  --border-color: var(--ion-color-danger);
}

ion-select::part(container) {
  font-size: 16px;
  color: var(--ion-color-dark);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1vZGFsLWNhbmNlbGxhdGlvbi5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGdCQUFBO0FBQ0Y7O0FBRUE7RUFDRSxrQ0FBQTtBQUNGOztBQUVBO0VBQ0UsOEJBQUE7QUFDRjs7QUFFQTtFQUNFLHVDQUFBO0FBQ0Y7O0FBR0U7RUFDRSxlQUFBO0VBQ0EsNEJBQUE7QUFBSiIsImZpbGUiOiJtb2RhbC1jYW5jZWxsYXRpb24uY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJpb24tY2FyZCB7XG4gIGJvcmRlci1yYWRpdXM6IDA7XG59XG5cbjo6bmctZGVlcCBpb24tdGV4dGFyZWEgLmxhYmVsLXRleHQge1xuICBjb2xvcjogdmFyKC0taW9uLWNvbG9yLWRhcmstc2hhZGUpO1xufVxuXG5pb24tdGV4dGFyZWEge1xuICAtLWNvbG9yOiB2YXIoLS1pb24tY29sb3ItZGFyayk7XG59XG5cbi5pbnZhbGlkIHtcbiAgLS1ib3JkZXItY29sb3I6IHZhcigtLWlvbi1jb2xvci1kYW5nZXIpO1xufVxuXG5pb24tc2VsZWN0e1xuICAmOjpwYXJ0KGNvbnRhaW5lcikge1xuICAgIGZvbnQtc2l6ZTogMTZweDtcbiAgICBjb2xvcjogdmFyKC0taW9uLWNvbG9yLWRhcmspO1xuICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/modals/modal-cancellation/modal-cancellation.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,kCAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,uCAAA;AACF;;AAGE;EACE,eAAA;EACA,4BAAA;AAAJ;AACA,g2BAAg2B","sourcesContent":["ion-card {\n  border-radius: 0;\n}\n\n::ng-deep ion-textarea .label-text {\n  color: var(--ion-color-dark-shade);\n}\n\nion-textarea {\n  --color: var(--ion-color-dark);\n}\n\n.invalid {\n  --border-color: var(--ion-color-danger);\n}\n\nion-select{\n  &::part(container) {\n    font-size: 16px;\n    color: var(--ion-color-dark);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
