import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, inject } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ModalActions } from 'src/app/modals/modal-actions';
import { RequestMyCityService } from 'src/app/services/request-my-city.service';
import { User } from 'src/app/services/user.service';
import { GoogleTagService } from 'src/app/services/google-tag.service';
import { SearchLocation, SearchService } from 'src/app/services/search.service';
import { ComponentBase } from 'src/app/components-standalone/component-base';

@Component({
  selector: 'app-notify-when-in-city',
  templateUrl: './notify-when-in-city.component.html',
  styleUrls: ['./notify-when-in-city.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotifyWhenInCityComponent extends ComponentBase implements AfterViewInit, OnChanges {

  @Input() cityName: string | undefined = undefined;
  @Input() modalCtrl!: ModalController;

  @Output() showLoadingView    = new EventEmitter<string>();
  @Output() dismissLoadingView = new EventEmitter<void>();

  isContentViewVisible = true;
  isErrorViewVisible   = false;
  isSuccessViewVisible = false;

  btnNotifyText = '';
  msgNoKitchens = '';
  successText   = '';

  private _cityInfo: SearchLocation | null = null;
  private _lastEmail = '';

  private _requestMyCityService = inject(RequestMyCityService);
  private _searchService        = inject(SearchService);
  private _translate            = inject(TranslateService);
  private _changeRef            = inject(ChangeDetectorRef);
  private _googleTag            = inject(GoogleTagService);

  ngAfterViewInit() {
    this._cityInfo = this._searchService.getFilterProp(SearchService.PROP_SEARCH_LOCATION) as SearchLocation;
    this._setText();
    if (this._requestMyCityService.isCached(this._cityInfo)) {
      this._showSuccessView();
    } else {
      this._showContentView();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cityName) {
      this._cityInfo = this._searchService.getFilterProp(SearchService.PROP_SEARCH_LOCATION) as SearchLocation;
      this._setText();
    }
  }

  generateLead() {
    this._googleTag.add({
      event: 'generate_lead',
      currency: 'CAD',
      value: 0
    });
  }

  async onNotifyMe() {
    const user = User.getCurrent();
    if (user.firstName) {
      // Logged in, update Contact record in Hubspot
      this._requestMyCity(user.email);

      this.generateLead();
    } else {
      // Not logged in, show email popup
      const trans = await this._translate.get(['CAPTURE_EMAIL_TITLE', 'CAPTURE_EMAIL_MESSAGE', 'EMAIL', 'GO_BACK', 'CAPTURE_EMAIL_CONFIRM']).toPromise();
      const modal = await ModalActions.openModalWithEmail(this.modalCtrl, {
        title:   trans.CAPTURE_EMAIL_TITLE,
        content: trans.CAPTURE_EMAIL_MESSAGE,
        textarea: {
          label:       trans.EMAIL,
          placeholder: '',
          defaultText: this._lastEmail,
        },
        buttons: [
          {
            action: ModalActions.GO_BACK,
            text:   trans.GO_BACK,
            fill:   'outline'
          },
          {
            action: ModalActions.CONFIRM,
            text:   trans.CAPTURE_EMAIL_CONFIRM
          }
        ]
      });
      const { data } = await modal.onDidDismiss();
      if (data.action === ModalActions.CONFIRM) {
        this._lastEmail = data.msg;
        this._requestMyCity(data.msg);

        this.generateLead();
      }
    }
  }

  private async _setText() {
    const baseText = await this.getTrans('NO_KITCHENS_FOUND_BTN_BASE');
    const baseMsg  = await this.getTrans('NO_KITCHENS_FOUND_MSG_BASE');
    const endMsg   = await this.getTrans('NO_KITCHENS_FOUND_MSG_END');

    const parts    = this._cityInfo?.address.split(',') || [];
    const isGeo    = parts.length === 2 && !isNaN(parseInt(parts[0])) && !isNaN(parseInt(parts[1]));
    const isCity   = parts.length > 0   && !isGeo;
    const cityName = isCity && isNaN(parseInt(parts[0]))
      ? parts[0]
      : parts[1];

    this.btnNotifyText = isCity
      ? baseText + cityName
      : baseText + await this.getTrans('NO_KITCHENS_FOUND_BTN_DEFAULT');

    this.msgNoKitchens = isCity
      ? baseMsg + cityName + '. ' + endMsg
      : baseMsg + await this.getTrans('NO_KITCHENS_FOUND_MSG_DEFAULT') + endMsg;

    this._changeDetectorRef.markForCheck();
  }
   
  /**
   * Calls the 
   * @param email 
   */
  private async _requestMyCity(email: string) {
    try {
      const trans = await this._translate.get('REQUEST_CITY_ADD').toPromise();
      this.showLoadingView.emit(trans);
      
      const response = await this._requestMyCityService.requestMyCity(email, this._cityInfo as SearchLocation);
      if (response.id) {
        this._requestMyCityService.cacheSuccess(this._cityInfo as SearchLocation);
        this._showSuccessView();
      } else {
        this._showErrorView();
      }
    } catch (error) {
      this._showErrorView();
    }
    this.dismissLoadingView.emit();
    this._changeRef.markForCheck();
  }

  private _showContentView() {
    this.isContentViewVisible = true;
    this.isErrorViewVisible   = false;
    this.isSuccessViewVisible = false;
    this._changeRef.markForCheck();
  }

  private _showErrorView() {
    this.isContentViewVisible = false;
    this.isErrorViewVisible   = true;
    this.isSuccessViewVisible = false;
    this._changeRef.markForCheck();
  }

  private async _showSuccessView() {
    this.isContentViewVisible = false;
    this.isErrorViewVisible   = false;
    this.isSuccessViewVisible = true;

    const trans      = await this._translate.get('REQUEST_CITY_SUCCESS').toPromise();
    this.successText = trans.replace('[CITY]', this._cityInfo?.address);
    this._changeRef.markForCheck();
  }
}
