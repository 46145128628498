// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

ion-button {
  height: 100%;
  margin: 0;
  width: 70px;
  min-width: 70px;
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  --border-radius: 0;
}
ion-button ion-img {
  width: 24px;
}

.disabled {
  color: var(--ion-color-dark) !important;
  opacity: 100% !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInBob25lLW51bWJlci5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGNBQUE7QUFDRjs7QUFFQTtFQUNFLFlBQUE7RUFDQSxTQUFBO0VBQ0EsV0FBQTtFQUNBLGVBQUE7RUFDQSxrQkFBQTtFQUNBLGdCQUFBO0VBQ0EsZ0JBQUE7RUFDQSxtQkFBQTtFQUNBLGtCQUFBO0FBQ0Y7QUFDRTtFQUNFLFdBQUE7QUFDSjs7QUFHQTtFQUNFLHVDQUFBO0VBQ0Esd0JBQUE7QUFBRiIsImZpbGUiOiJwaG9uZS1udW1iZXIuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XG4gIGRpc3BsYXk6IGJsb2NrO1xufVxuXG5pb24tYnV0dG9uIHtcbiAgaGVpZ2h0OiAxMDAlO1xuICBtYXJnaW46IDA7XG4gIHdpZHRoOiA3MHB4O1xuICBtaW4td2lkdGg6IDcwcHg7XG4gIC0tcGFkZGluZy1zdGFydDogMDtcbiAgLS1wYWRkaW5nLWVuZDogMDtcbiAgLS1wYWRkaW5nLXRvcDogMDtcbiAgLS1wYWRkaW5nLWJvdHRvbTogMDtcbiAgLS1ib3JkZXItcmFkaXVzOiAwO1xuXG4gIGlvbi1pbWcge1xuICAgIHdpZHRoOiAyNHB4O1xuICB9XG59XG5cbi5kaXNhYmxlZCB7XG4gIGNvbG9yOiB2YXIoLS1pb24tY29sb3ItZGFyaykgIWltcG9ydGFudDtcbiAgb3BhY2l0eTogMTAwJSAhaW1wb3J0YW50O1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components-standalone/phone-number/phone-number.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,YAAA;EACA,SAAA;EACA,WAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;AACF;AACE;EACE,WAAA;AACJ;;AAGA;EACE,uCAAA;EACA,wBAAA;AAAF;AACA,w7BAAw7B","sourcesContent":[":host {\n  display: block;\n}\n\nion-button {\n  height: 100%;\n  margin: 0;\n  width: 70px;\n  min-width: 70px;\n  --padding-start: 0;\n  --padding-end: 0;\n  --padding-top: 0;\n  --padding-bottom: 0;\n  --border-radius: 0;\n\n  ion-img {\n    width: 24px;\n  }\n}\n\n.disabled {\n  color: var(--ion-color-dark) !important;\n  opacity: 100% !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
