// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card {
  padding: 16px;
  display: flex;
  flex-direction: column;
  max-width: 320px;
  cursor: default;
}
ion-card ion-icon {
  margin-left: auto;
  cursor: pointer;
}
ion-card > p {
  color: var(--ion-color-dark);
  align-self: flex-start;
  cursor: text;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1vZGFsLWtpdGNoZW4tZXhwZXJpZW5jZS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLGFBQUE7RUFDQSxhQUFBO0VBQ0Esc0JBQUE7RUFDQSxnQkFBQTtFQUNBLGVBQUE7QUFDSjtBQUNJO0VBQ0ksaUJBQUE7RUFDQSxlQUFBO0FBQ1I7QUFFSTtFQUNJLDRCQUFBO0VBQ0Esc0JBQUE7RUFDQSxZQUFBO0FBQVIiLCJmaWxlIjoibW9kYWwta2l0Y2hlbi1leHBlcmllbmNlLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiaW9uLWNhcmQge1xuICAgIHBhZGRpbmc6IDE2cHg7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgIG1heC13aWR0aDogMzIwcHg7XG4gICAgY3Vyc29yOiBkZWZhdWx0O1xuXG4gICAgaW9uLWljb24ge1xuICAgICAgICBtYXJnaW4tbGVmdDogYXV0bztcbiAgICAgICAgY3Vyc29yOiBwb2ludGVyO1xuICAgIH1cblxuICAgID4gcCB7XG4gICAgICAgIGNvbG9yOiB2YXIoLS1pb24tY29sb3ItZGFyayk7XG4gICAgICAgIGFsaWduLXNlbGY6IGZsZXgtc3RhcnQ7XG4gICAgICAgIGN1cnNvcjogdGV4dDtcbiAgICB9XG59XG5cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/modals/modal-kitchen-experience/modal-kitchen-experience.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,eAAA;AACJ;AACI;EACI,iBAAA;EACA,eAAA;AACR;AAEI;EACI,4BAAA;EACA,sBAAA;EACA,YAAA;AAAR;AACA,o3BAAo3B","sourcesContent":["ion-card {\n    padding: 16px;\n    display: flex;\n    flex-direction: column;\n    max-width: 320px;\n    cursor: default;\n\n    ion-icon {\n        margin-left: auto;\n        cursor: pointer;\n    }\n\n    > p {\n        color: var(--ion-color-dark);\n        align-self: flex-start;\n        cursor: text;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
