import { ChangeDetectionStrategy, Component, ElementRef, Injector, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IonItem, IonTextarea } from '@ionic/angular';
import { BasePageModal } from 'src/app/pages/base-page-modal/base-page-modal';
import { IModalCheckboxForm } from '../i-modal-checkbox-form';
import { IModalDetails } from '../i-modal-details';
import { ModalActions } from '../modal-actions';

@Component({
  selector: 'app-modal-content-with-radio-and-textarea',
  templateUrl: './modal-content-with-radio-and-textarea.component.html',
  styleUrls: ['./modal-content-with-radio-and-textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalContentWithRadioAndTextareaComponent extends BasePageModal implements OnInit {

  @Input() modalDetails!: IModalDetails;

  safeHtml: SafeHtml | null = null;
  msg = '';
  form: IModalCheckboxForm[] = [];

  @ViewChild('ionItem', {read: ElementRef}) private _ionItem!: ElementRef;
  @ViewChild(IonTextarea) private _textArea!: IonTextarea;

  constructor(
    injector: Injector,
    private _sanitizer: DomSanitizer,
    private _renderer: Renderer2
  ) {
    super(injector);
  }

  ngOnInit() {
    const modalDetailsDefaults = { required: true };
    this.modalDetails.textarea = Object.assign({}, modalDetailsDefaults, this.modalDetails.textarea);
    this.safeHtml              = this._sanitizer.bypassSecurityTrustHtml(this.modalDetails.content);
    this.form                  = this.modalDetails.checkboxForm || [];
  }

  async buttonClick(action: string) {
    const validated = this.form.some(item => item.isChecked);
    if (action === ModalActions.GO_BACK || !this.modalDetails.textarea?.required || this.modalDetails.textarea.required && this.msg && validated) {
      this.modalCtrl.dismiss({
        action,
        msg: this.msg,
        form: this.form,
      });
    } else {
      this._renderer.addClass(this._ionItem.nativeElement, 'invalid');
      this._textArea.setFocus();
    }
  }

}
