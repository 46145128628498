import { CommonModule } from '@angular/common';
import { Component, Injector, Input, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ExperienceComponent } from 'src/app/components-standalone/experience/experience.component';
import { IEquipmentExperience } from 'src/app/components/profile-editor/profile-editor.component';
import { BasePageModal } from 'src/app/pages/base-page-modal/base-page-modal';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { Equipment } from 'src/app/services/equipment.service';

interface IEquipmentExperienceDisplay {
  id: string;
  icon: string;
  title: string;
  experience: number;
}

@Component({
  standalone: true,
  selector: 'app-modal-kitchen-experience',
  templateUrl: './modal-kitchen-experience.component.html',
  styleUrls: ['./modal-kitchen-experience.component.scss'],
  imports: [
    IonicModule,
    CommonModule,
    ExperienceComponent,
    TranslateModule,
    PipesModule
  ]
})
export class ModalKitchenExperienceComponent extends BasePageModal implements OnInit {
  @Input() kitchenExperience                           = 0;
  @Input() equipmentCategories: any                    = [];
  @Input() equipmentExperience: IEquipmentExperience[] = [];

  displayExperience: IEquipmentExperienceDisplay[] | null        = [];
  constructor(
    private _injector: Injector
  ) {
    super(_injector);
  }

  ngOnInit() {
    const showArray = [];
    for (const category of this.equipmentCategories) {
      const equipmentCategoryId = category.id;
      const userExperience      = this.equipmentExperience.find((item: any) => item.equipmentCategoryId === equipmentCategoryId) || { equipmentCategoryId, experience: 0 };

      if (userExperience.experience > 0) {
        showArray.push({
          id: category.id,
          icon: category.get('icon'),
          title: category.get('title'),
          experience: userExperience.experience
        });
      }
    }

    this.displayExperience = showArray;
  }

  async closeModal() {
    this.modalCtrl.dismiss();
  }

}
