/* eslint-disable no-bitwise */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { environment } from 'src/environments/environment';

export interface IObfuscatedAddress {
  address: string;
  geo: {
    latitude: number;
    longitude: number;
  };
  isObfuscated: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AddressObfuscatorService {

  constructor(
    private _http: HttpClient
  ) { }

  /**
   * Load the obfuscated address
   * @param placeId 
   * @returns 
   */
  load(placeId: string): Promise<IObfuscatedAddress> {
    return Parse.Cloud.run('getObfuscatedAddress', { placeId });
  }

  /**
   * Gets the static Google map with a circle drawn to represent the general location
   * https://stackoverflow.com/questions/36506668/google-static-map-draw-a-circle
   * @param latitude 
   * @param longitude 
   * @param radius 
   * @param zoom 
   * @returns 
   */
  getStaticMapImage(latitude: number, longitude: number, radius: number, zoom: number, width: number, hideLabels: boolean = true): Promise<string> {
    const height = Math.round(width * 3 / 4);
    return new Promise((resolve, reject) => {
      const params: any = {
        key: environment.googleMapsApiKey,
        style: 'feature:poi|element:labels|visibility:' + (hideLabels ? 'off' : 'on'),
        center: `${latitude},${longitude}`,
        size: `${width}x${height}`,
        zoom: `${zoom}`,
      };
      if(hideLabels) {
        params.path = `fillcolor:0xFF2C451A|color:0xFF2C45FF|enc:${this._drawCirclePath(
          latitude,
          longitude,
          radius
        )}`;
      } else {
        params.markers = `icon:https://storage.googleapis.com/syzl-prod/pin-new-32x32.png|${latitude},${longitude}`;
      }

      this._http
        .get('https://maps.googleapis.com/maps/api/staticmap', {
          params,
          responseType: 'blob',
        })
        .subscribe({
          next: (imgBlob) => {
            const reader = new FileReader();
            reader.readAsDataURL(imgBlob as Blob);
            reader.onloadend = () => {
              resolve(reader.result?.toString() || '');
            };
          },
          error: error => reject(error)
        });
    });
  }

  /**
   * Helper function for static map
   * https://stackoverflow.com/questions/36506668/google-static-map-draw-a-circle
   * @param lat 
   * @param lng 
   * @param radius 
   * @param detail 
   * @returns 
   */
  private _drawCirclePath(lat: number, lng: number, radius: number, detail = 8) {
    const R  = 6371;

    const pi = Math.PI;

    lat = lat * pi / 180;
    lng = lng * pi / 180;
    const d = radius / R;

    const points: any = [];
    let i = 0;

    for (i = 0; i <= 360; i += detail) {
      const brng = i * pi / 180;

      let plat = Math.asin(
        Math.sin(lat) * Math.cos(d) +
                Math.cos(lat) * Math.sin(d) * Math.cos(brng)
      );
      const plng =
            (lng +
                Math.atan2(
                  Math.sin(brng) * Math.sin(d) * Math.cos(lat),
                  Math.cos(d) - Math.sin(lat) * Math.sin(plat)
                )) *
                180 /
            pi;
      plat = plat * 180 / pi;

      const currentPoints: any = [plat, plng];
      points.push(currentPoints);
    }

    return this._createEncodings(points);
  }

  /**
   * Helper function for static map
   * https://stackoverflow.com/questions/36506668/google-static-map-draw-a-circle
   * @param coords 
   * @returns 
   */
  private _createEncodings(coords: any[][]) {
    let i = 0;

    let plat = 0;
    let plng = 0;

    let encodedPoints = '';

    for (i = 0; i < coords.length; ++i) {
      const lat = coords[i][0];
      const lng = coords[i][1];

      encodedPoints += this._encodePoint(plat, plng, lat, lng);

      plat = lat;
      plng = lng;
    }

    return encodedPoints;
  }

  /**
   * Helper function for static map
   * https://stackoverflow.com/questions/36506668/google-static-map-draw-a-circle
   * @param plat 
   * @param plng 
   * @param lat 
   * @param lng 
   * @returns 
   */
  private _encodePoint(plat: number, plng: number, lat: number, lng: number) {
    let dlng = 0;
    let dlat = 0;

    const late5 = Math.round(lat * 1e5);
    const plate5 = Math.round(plat * 1e5);

    const lnge5 = Math.round(lng * 1e5);
    const plnge5 = Math.round(plng * 1e5);

    dlng = lnge5 - plnge5;
    dlat = late5 - plate5;

    return this._encodeSignedNumber(dlat) + this._encodeSignedNumber(dlng);
  }

  /**
   * Helper function for static map
   * https://stackoverflow.com/questions/36506668/google-static-map-draw-a-circle
   * @param num 
   * @returns 
   */
  private _encodeSignedNumber(num: number) {
    let sgnNum = num << 1;

    if (num < 0) {
      sgnNum = ~sgnNum;
    }

    return this._encodeNumber(sgnNum);
  }

  /**
   * Helper function for static map
   * https://stackoverflow.com/questions/36506668/google-static-map-draw-a-circle
   * @param num 
   * @returns 
   */
  private _encodeNumber(num: number) {
    let encodeString = '';

    while (num >= 0x20) {
      encodeString += String.fromCharCode((0x20 | num & 0x1f) + 63);
      num >>= 5;
    }
    encodeString += String.fromCharCode(num + 63);

    return encodeString;
  }
}
