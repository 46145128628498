import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, Optional, Self, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { SyzlIconService } from 'src/app/services/syzl-icon.service';

interface IStepValue {
  display: string;
  value: number;
}

@Component({
  standalone: true,
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    DirectivesModule,
  ],
})
export class ExperienceComponent implements ControlValueAccessor, OnInit, OnChanges, AfterViewInit {

  private readonly _DEFAULT_WIDTH = 224;

  @Input() iconName   = '';
  @Input() iconNum    = SyzlIconService.NO_NUMBER;
  @Input() iconType   = 'color';
  @Input() isViewOnly = false;
  @Input() title      = '';
  @Input() steps: IStepValue[] = [
    { display: 'EXP_NONE', value: 0 },
    { display: 'EXP_NOVICE', value: 1 },
    { display: 'EXP_INTERMEDIATE', value: 2 },
    { display: 'EXP_PROFICIENT', value: 3 },
    { display: 'EXP_EXPERT', value: 4 },
  ];
  @Input() viewValue = -1;
  isDisabled = false;
  value: any;
  onChange: any;
  onTouched: any;

  displayContainerWidth = this._DEFAULT_WIDTH;
  stepDisplay           = this.steps[0].display;
  stepValue             = this.steps[0].value;

  @ViewChild('displayContainer', { static: true }) private _displayContainer!: ElementRef<HTMLDivElement>;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private _changeRef: ChangeDetectorRef,
  ) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    this._updateDisplay();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.viewValue && this.viewValue > -1) {
      this.writeValue(this.viewValue);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.displayContainerWidth = this._displayContainer.nativeElement.offsetWidth || this._DEFAULT_WIDTH;
      this._changeRef.markForCheck();
    }, 0);
  }

  updateStep(e: any) {
    this.value = e.detail.value;
    if (this.onChange) {
      this.onChange(this.value);
    }
    this._updateDisplay();
  }

  blur() {
    this.onTouched();
  }

  writeValue(value: any): void {
    this.value = value;
    this._updateDisplay();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this._changeRef.markForCheck();
  }

  private _updateDisplay() {
    const step       = this.steps[this.value || 0];
    this.stepDisplay = step.display;
    this.stepValue   = step.value;
    this._changeRef.markForCheck();
  }
}
