import { Injectable } from '@angular/core';
import OneSignal from 'onesignal-cordova-plugin';
import { User } from './user.service';
import { environment } from 'src/environments/environment';
import { NotificationsPreferencesService } from './notifications-preferences.service';
import { Platform } from '@ionic/angular';
import { OneSignalWebWrapperService } from './one-signal-web-wrapper.service';
import { BehaviorSubject } from 'rxjs';
import * as Parse from 'parse';

@Injectable({
  providedIn: 'root'
})
export class OnesignalService {

  private _isInitialized           = false;
  private _isFailureAlertExpanded$ = new BehaviorSubject<boolean>(false);
  private _initializationFailed$   = new BehaviorSubject<boolean>(false);

  constructor(
    private _oneSignalWebWrapper: OneSignalWebWrapperService,
    private _noficiationPreferenceService: NotificationsPreferencesService,
    private _platform: Platform,
  ) { }

  get isFailureAlertExpanded$() {
    return this._isFailureAlertExpanded$.asObservable();
  }

  get initializationFailed$() {
    return this._initializationFailed$.asObservable();
  }

  get didFail() {
    return this._initializationFailed$.getValue();
  }

  toggleAlertExpansion() {
    this._isFailureAlertExpanded$.next(!this._isFailureAlertExpanded$.getValue());
  }

  async setupOneSignalWeb() {

    if (!this._isInitialized) {
      const appId = environment.oneSignal.appId;
      if (this._isCapacitor) {
        OneSignal.setAppId(appId);
      } else {
        const initResponse = await this._oneSignalWebWrapper.init({
          appId,
          allowLocalhostAsSecureOrigin: process.env.NODE_ENV === 'development',
          webhooks: {
            'cors': true,
            'notification.displayed': environment.oneSignal.webhookUrl
          }
        });
        if (initResponse.error) {
          this._initializationFailed$.next(true);
          this.toggleAlertExpansion();
        }
      }
      this.listenOnSubscription();
      this._isInitialized = true;
    }

    this._completeInitialization();
  }

  listenOnSubscription() {
    if (this.didFail) { return; }

    if (this._isCapacitor) {
      // Need to check if enabled

    } else {
      try {
        this._oneSignalWebWrapper.on('subscriptionChange', async (isSubscribed) => {
          // isSubscribed will be a boolean value returned by OneSignal
          if (isSubscribed) {
            this.assignExternalId();
          }
        });
    
    
        this._oneSignalWebWrapper.isPushNotificationsEnabled( async (isEnabled) => {
          // if the user is not enabled on OneSignal, we will adjust their preferences in our DB accordingly
          if (!isEnabled) {
            const user = User.getCurrent();
            if (!user.isAnonymous) {
              const userNotificationPref = user.notificationPreference;
              userNotificationPref.set('enabledInApp', false);
              await userNotificationPref.save();
            }
          }
        });
      } catch (error) {
        console.warn(error);
      }
    }
  }

  async getSmsEnabled(): Promise<boolean> {
    const user = await Parse.User.current()?.fetchWithInclude('notificationPreference');
    return user?.get('notificationPreference')?.get('enabledSms') || false;
  }

  async assignExternalId() {
    if (this.didFail) { return; }

    //Check OneSignal if this device is already bound to a Syzl user id
    const externalIdExists = await this._oneSignalWebWrapper.getExternalUserId();
    const userId           = User.getCurrent().id;

    if (externalIdExists !== userId) {
      try {
        const noVal = this._isCapacitor
          ? OneSignal.setExternalUserId(userId)
          : await this._oneSignalWebWrapper.setExternalUserId(userId);
      } catch (error) {
        console.warn(error);
      }
    }
  }

  async assignPhoneNumber() {
    if (this.didFail) { return; }

    if (!User.getCurrent().isAnonymous() && User.getCurrent().get('phone')) {
      const subscribed = await this.subscribeOnSignUp(User.getCurrent().get('phone'));
      return subscribed;
    }
  }

  async unassignPhoneNumber() {
    if (this.didFail) { return; }

    try {
      const noVal = this._isCapacitor
        ? OneSignal.logoutSMSNumber()
        : await this._oneSignalWebWrapper.logoutSMS();
    } catch (error) {
      console.warn(error);
    }
  }

  async subscribeOnSignUp(phoneNumber: string) {
    if (this.didFail) { return; }

    let subscribed: string | null = '';
    try {
      subscribed = this._isCapacitor
        ? await new Promise<string | null>((resolve, reject) => OneSignal.setSMSNumber(phoneNumber, undefined, () => resolve(phoneNumber), () => reject(null)))
        : await this._oneSignalWebWrapper.setSMSNumber(phoneNumber);
    } catch (error) {
      console.warn(error);
    }
    return subscribed;
  }

  private async _completeInitialization() {

    if (this.didFail) { return; }

    const externalId  = await this._oneSignalWebWrapper.getExternalUserId();
    const user        = User.getCurrent();
    const userId      = user.id;

    // check user's username && check notificationPreference

    // if user name does not exist, we are not logged in, don't do anything
    // if notification preference doesn't exist, create a new one
    // check if OneSignal bound ID is equal to current logged in User
    if (externalId !== userId) {
      // if it isn't check the current user preference
      const prefId = user.notificationPreference?.id;
      if (prefId) {
        const userNotificationPref = await this._noficiationPreferenceService.findById(prefId);

        if (userNotificationPref.get('enabledInApp')) {
          try {
            const noVal = this._isCapacitor
              ? OneSignal.setExternalUserId(userId)
              : await this._oneSignalWebWrapper.setExternalUserId(userId);
          } catch (error) {
            console.warn(error);
          }
        }
      } else {
        if (user.firstName) {
          await this._noficiationPreferenceService.createDefaultNotificationsPref();
        }
      }
    }
  }

  private get _isCapacitor() {
    return this._platform.is('capacitor');
  }

}
