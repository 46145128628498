export class SectionPref {
  objectId   = '';
  icon       = '';
  name       = '';
  hasPref    = false;
  deletedAt: string | null = null;

  constructor(objectId: string, icon: string, name: string, hasPref: boolean = false, deletedAt: string) {
    this.objectId  = objectId;
    this.icon      = icon;
    this.name      = name;
    this.hasPref   = hasPref;
    this.deletedAt = deletedAt;
  }
}
