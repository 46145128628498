import { DateTime } from 'luxon';

export class ClosedDateAndTime {
  static readonly END_OF_DAY      = 'End of day';
  static readonly END_OF_DAY_TIME = '23:59';

  objectId = '';
  yyyyMmDd = '';
  fromTime = '';
  toTime   = '';
    
  constructor(objectId: string, yyyyMmDd: string, fromTime: string, toTime: string) {
    this.objectId = objectId;
    this.yyyyMmDd = yyyyMmDd;
    this.fromTime = fromTime;
    this.toTime   = toTime;
  }

  /**
     * Returns a new ClosedDateAndTime object from a similar object
     * @param obj 
     * @returns 
     */
  static newFromSelf(obj: ClosedDateAndTime) {
    return new ClosedDateAndTime(obj.objectId, obj.yyyyMmDd, obj.fromTime, obj.toTime);
  }

  /**
     * Returns the time to display on screen
     * @param time 
     * @returns 
     */
  static timeToDisplay(time: string) {
    if (time === ClosedDateAndTime.END_OF_DAY_TIME) {return ClosedDateAndTime.END_OF_DAY;}

    const key  = parseInt(time, 10);
    const amPm = key < 12 ? ' am' : ' pm';
    const hour = key === 0 || key === 12
      ? '12'
      : key < 12
        ? key
        : key - 12;
    const display = hour + amPm;
    return display;
  }

  /**
     * Returns true when the object is invalid
     */
  get invalid() {
    const fromTime = parseInt(this.fromTime, 10);
    const toTime   = parseInt(this.toTime, 10);
    return this.yyyyMmDd === ''
            || this.fromTime === ''
            || this.toTime   === ''
            || fromTime < 0
            || toTime < 0;
  }

  /**
     * Returns a DateTime object based on the yyyyMmDd and timeZone value
     * @param timeZone 
     * @returns 
     */
  getDateTime(timeZone: string) {
    const year  = parseInt(this.yyyyMmDd.substring(0, 4), 10);
    const month = parseInt(this.yyyyMmDd.substring(5, 7), 10);
    const day   = parseInt(this.yyyyMmDd.substring(8), 10);
    return DateTime.fromObject({ year, month, day }, { zone: timeZone });
  }
}
