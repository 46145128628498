// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-input {
  --color: var(--ion-color-dark);
}

span {
  visibility: hidden;
  opacity: 0;
}
span.showError {
  color: var(--ion-color-danger);
  visibility: visible;
  opacity: 100%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1vZGFsLWNvbnRlbnQtd2l0aC1pbnB1dC5wYWdlLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSw4QkFBQTtBQUNKOztBQUVBO0VBQ0ksa0JBQUE7RUFDQSxVQUFBO0FBQ0o7QUFDSTtFQUNJLDhCQUFBO0VBQ0EsbUJBQUE7RUFDQSxhQUFBO0FBQ1IiLCJmaWxlIjoibW9kYWwtY29udGVudC13aXRoLWlucHV0LnBhZ2Uuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbImlvbi1pbnB1dCB7XG4gICAgLS1jb2xvcjogdmFyKC0taW9uLWNvbG9yLWRhcmspO1xufVxuXG5zcGFuIHtcbiAgICB2aXNpYmlsaXR5OiBoaWRkZW47XG4gICAgb3BhY2l0eTogMDtcbiAgICBcbiAgICAmLnNob3dFcnJvciB7XG4gICAgICAgIGNvbG9yOiB2YXIoLS1pb24tY29sb3ItZGFuZ2VyKTtcbiAgICAgICAgdmlzaWJpbGl0eTogdmlzaWJsZTtcbiAgICAgICAgb3BhY2l0eTogMTAwJTtcbiAgICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/modals/modal-content-with-input/modal-content-with-input.page.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;AACJ;;AAEA;EACI,kBAAA;EACA,UAAA;AACJ;AACI;EACI,8BAAA;EACA,mBAAA;EACA,aAAA;AACR;AACA,wrBAAwrB","sourcesContent":["ion-input {\n    --color: var(--ion-color-dark);\n}\n\nspan {\n    visibility: hidden;\n    opacity: 0;\n    \n    &.showError {\n        color: var(--ion-color-danger);\n        visibility: visible;\n        opacity: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
