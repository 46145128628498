import { Injectable } from '@angular/core';
import { OneSignal } from 'onesignal-ngx';
import OneSignalNative from 'onesignal-cordova-plugin'; // For native usage
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

import { NotificationsPreferencesService } from './notifications-preferences.service';
import { User } from './user.service';
@Injectable({
  providedIn: 'root',
})
export class OnesignalService {
  private _isInitialized           = false;
  private _isFailureAlertExpanded$ = new BehaviorSubject<boolean>(false);
  private _initializationFailed$   = new BehaviorSubject<boolean>(false);

  constructor(
    private _oneSignal: OneSignal, // from onesignal-ngx for web
    private _platform: Platform,
    private _notificationPrefService: NotificationsPreferencesService,
  ) { }

  get isFailureAlertExpanded$() {
    return this._isFailureAlertExpanded$.asObservable();
  }

  get initializationFailed$() {
    return this._initializationFailed$.asObservable();
  }

  get didFail() {
    return this._initializationFailed$.getValue();
  }

  get isPushSubscribed() {
    return this._oneSignal.User.PushSubscription.optedIn;
  }

  toggleAlertExpansion() {
    this._isFailureAlertExpanded$.next(!this._isFailureAlertExpanded$.getValue());
  }

  // --------------------------------------------------------------------------------
  // WEB INIT
  // --------------------------------------------------------------------------------
  async setupOneSignalWeb() {
    try {
      // The onesignal-ngx lib auto-loads the SDK, but you can still call init if needed:
      await this._oneSignal.init({
        appId: environment.oneSignal.appId,
        allowLocalhostAsSecureOrigin: !environment.production,
        webhooks: {
          'notification.displayed': environment.oneSignal.webhookUrl,
        },
      });

      this._oneSignal.User.PushSubscription.addEventListener(
        'change',
        async (subscriptionChange) => {
          const isSubscribed = subscriptionChange.current.optedIn;
          if (isSubscribed) {
            await this._updateNotificationPref();
          }
        });

      this._oneSignal.Notifications.addEventListener('permissionChange', (permission) => {
        console.log('Permission changed:', permission);
      });

    } catch (err) {
      console.error('OneSignal web initialization failed', err);
      this._initializationFailed$.next(true);
      this.toggleAlertExpansion();
    }

    await this._completeInitialization();
    this._isInitialized = true;
  }

  async showPushPermissionPrompt() {
    const notificationPermission = this._oneSignal.Notifications.permission;
    const isOptedIn = this._oneSignal.User.PushSubscription.optedIn;

    if (!isOptedIn || !notificationPermission) {
      await this._oneSignal.Slidedown.promptPush({ force: true });
    }
  }

  // --------------------------------------------------------------------------------
  // External User ID
  // --------------------------------------------------------------------------------
  async assignExternalUserId() {
    if (this.didFail) { return; }
    const userId = User.getCurrent()?.id;
    if (!userId) return;

    if (this._isCapacitor) {
      OneSignalNative.setExternalUserId(userId);
    } else {
      await this._oneSignal.login(userId);
    }
  }

  logout() {
    this._oneSignal.logout();
  }

  // --------------------------------------------------------------------------------
  // COMPLETE INIT
  // --------------------------------------------------------------------------------
  private async _completeInitialization() {
    if (this.didFail) { return; }

    const user = User.getCurrent();
    if (!user) return;

    await this.showPushPermissionPrompt();
    this._isInitialized = true;
  }

  private async _updateNotificationPref() {
    const user = User.getCurrent();
    const prefId = user.notificationPreference?.id;
    const optedIn = this._oneSignal.User.PushSubscription.optedIn;

    if (prefId) {
      const userNotificationPref = await this._notificationPrefService.findById(prefId);

      if (userNotificationPref.get('enabledInApp')) {
        await this.assignExternalUserId();
      }

      await userNotificationPref.save({ enabledInApp: optedIn });
    } else {
      if (user.firstName) {
        const newDefaultPrefs = await this._notificationPrefService.createDefaultNotificationsPref();
        await newDefaultPrefs.save({ enabledInApp: optedIn });
      }
    }
  }

  // --------------------------------------------------------------------------------
  // HELPER
  // --------------------------------------------------------------------------------

  private get _isCapacitor() {
    return this._platform.is('capacitor');
  }
}
