/* eslint-disable max-lines */
import { AfterViewInit, ApplicationRef, Component, Inject, Injector, NgZone } from '@angular/core';
import { environment } from '../environments/environment';
import { LocalStorage } from './services/local-storage';
import { User } from './services/user.service';
import { Installation } from './services/installation';
import { AudioService } from './services/audio-service';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';
import { nanoid } from 'nanoid';
import { App } from '@capacitor/app';
import { BasePage } from './pages/base-page/base-page';
import { NavigationEnd } from '@angular/router';
import { Keyboard, KeyboardStyle } from '@capacitor/keyboard';
import { isPlatform } from '@ionic/angular';
import OneSignal from 'onesignal-cordova-plugin';
import NotificationReceivedEvent from 'onesignal-cordova-plugin/dist/NotificationReceivedEvent';
import { OpenedEvent } from 'onesignal-cordova-plugin/dist/models/NotificationOpened';
import { NotificationPage } from './pages/notification/notification.page';
import { Globals } from './helpers/globals';
import { ConversationsService } from './services/conversations.service';
import { IdentityService } from './services/identity.service';
import { OnesignalService } from './services/onesignal.service';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, first } from 'rxjs/operators';
import { concat, interval } from 'rxjs';
import { ModalActions } from './modals/modal-actions';
import { ParseInitializer } from './parse-initializer';
import { ProfilePreviewPage } from './pages/profile-preview/profile-preview.page';
import { StripeIdentityService } from './services/stripe-identity.service';
import { SignInSocialCompletionComponent } from './pages/sign-in-social-completion/sign-in-social-completion.component';
import { TabRouteName, TabRouteUrl } from './services/tab.service';
import { HubspotService } from './services/hubspot.service';
import { DOCUMENT } from '@angular/common';
import { NgxPendoService } from 'ngx-pendo';
import { MaintenanceService } from './services/maintenance.service';
import { ModalStatics } from './services/modal.service';
import { Place } from './services/place.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: false,
})
export class AppComponent extends BasePage implements AfterViewInit {
  isUnderMaintenance = false;

  constructor(
    injector: Injector,
    private _storage: LocalStorage,
    private _userService: User,
    private _installationService: Installation,
    private _audioService: AudioService,
    private _conversationService: ConversationsService,
    private _ngZone: NgZone,
    private _identityService: IdentityService,
    private _stripeIdentityService: StripeIdentityService,
    private _oneSignalService: OnesignalService,
    private _appRef: ApplicationRef,
    private _swUpdate: SwUpdate,
    private _hubspotService: HubspotService,
    private _ngxPendoService: NgxPendoService,
    private _maintenanceService: MaintenanceService,
    private _placeService: Place,
    @Inject(DOCUMENT) private _doc: Document,
  ) {
    super(injector);
    this._doc.defaultView?.localStorage.setItem('parseIsInitialized', 'false');
    this.initializeApp();
    this._initializeAppUpdater();
  }

  ngAfterViewInit() {
    // Remove the loader element,
    // The loader element in AppComponent will remain on screen
    setTimeout(() => {
      const loader = document.querySelector('.app-loader');
      loader?.remove();
    }, 1000);
  }

  enableMenuSwipe(): boolean {
    return true;
  }

  async initializeApp() {

    this.translate.setDefaultLang(environment.defaultLang);

    this.setupParse();
    this.setupEvents();

    if (Capacitor.isNativePlatform()) {
      await this.setupStatusBar();
      this.setupNativeAudio();
      SplashScreen.hide();
    } else {
      this.setupFacebookWeb();
    }

    this.loadUser();
    this._conversationService.init();
    await this.checkForMaintenance();
  }

  async loadUser() {
    try {

      this.showLoadingView({ showOverlay: false });

      let user = User.getCurrent();

      if (!user) {
        user = await this._userService.loginAnonymously();
        await this._userService.becomeWithSessionToken(user.getSessionToken());
      }

      await this.setupDefaults();

      if (Capacitor.isNativePlatform()) {
        this.setupPush();
        this.setupOneSignal();
      }

      this.showContentView();
      this.updateInstallation();
      this.loadCurrentUser();

      // Get default preferences
      this.preference.loadAllAvailableFeatures();
      this.preference.loadAllAvailableEquipmentCategories();
      this.preference.loadAllAvailableDietary();

    } catch (error: any) {
      this.showErrorView();

      if (error.code === 209) {
        this.onLogOut();
      }
    }
  }

  setupFacebookWeb() {

    (this._doc.defaultView as { [key: string]: any }).fbAsyncInit = () => {
      (this._doc.defaultView as { [key: string]: any }).FB.init({
        appId: environment.fbId,
        cookie: true,
        xfbml: true,
        version: 'v5.0',
      });
    };

    // Load the SDK asynchronously
    (function(d, s, id) {
      if (d.getElementById(id)) {return;}

      const js = d.createElement(s) as HTMLScriptElement;
      js.id    = id;
      js.src   = 'https://connect.facebook.net/en_US/sdk.js';

      const fjs = d.getElementsByTagName(s)[0] as HTMLElement;
      fjs.parentNode?.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  async setupDefaults() {

    try {

      const supportedLangs = ['en', 'es', 'ar'];
      const browserLang = navigator.language.slice(0, 2);

      const promises = [
        this._storage.getLang(),
        this._storage.getUnit(),
        this._storage.getIsDarkModeEnabled(),
        this._storage.getIsPushEnabled(),
      ];

      let [
        lang,
        unit,
        isDarkModeEnabled,
        isPushEnabled,
      ] = await Promise.all(promises);

      if (lang === null && supportedLangs.indexOf(browserLang) !== -1) {
        lang = browserLang;
      }

      lang = lang || environment.defaultLang;

      if (lang === 'ar') {
        document.dir = 'rtl';
      } else {
        document.dir = 'ltr';
      }

      unit = unit || environment.defaultUnit;
      this._storage.setUnit(unit);
      this.preference.unit = unit;

      //Save language on user
      const user = User.getCurrent();
      user.lang  = lang;
      await user.save({ lang }, { context: { SKIP_AFTER_SAVE: Globals.SKIP_AFTER_SAVE, SKIP_BEFORE_SAVE: Globals.SKIP_BEFORE_SAVE } });

      this._storage.setLang(lang);
      this.translate.use(lang);
      this.preference.lang = lang;

      this.preference.isDarkModeEnabled = isDarkModeEnabled;

      if (isDarkModeEnabled) {
        this.toggleDarkTheme(isDarkModeEnabled);
      }

      this.notifications.isPushEnabled = isPushEnabled;

    } catch {
      this.preference.lang = environment.defaultLang;
      this.preference.unit = environment.defaultUnit;
    }

  }

  setupNativeAudio() {

    let path = 'pristine.mp3';

    if (this.isApple) {
      path = 'public/assets/audio/pristine.mp3';
    } else if (this.isAndroid) {
      path = '/assets/audio/pristine.mp3';
    }

    this._audioService.preload('ping', path);
  }

  setupEvents() {

    this._doc.defaultView?.addEventListener('user:login', () => {
      this.loadCurrentUser();
      this.updateInstallation();
    });

    this._doc.defaultView?.addEventListener('user:logout', () => {
      this.onLogOut();
    });

    this._doc.defaultView?.addEventListener('installation:update', (event: any) => {
      this.updateInstallation(event.detail);
    });

    this._doc.defaultView?.addEventListener('lang:change', (event: any) => {
      this.onChangeLang(event.detail);
    });

    this._doc.defaultView?.addEventListener('dark-mode:change', (event: any) => {
      const isDarkModeEnabled = event.detail;
      this.toggleDarkTheme(isDarkModeEnabled);
      this._storage.setIsDarkModeEnabled(isDarkModeEnabled);
      this.preference.isDarkModeEnabled = isDarkModeEnabled;
    });

    this._doc.defaultView?.addEventListener('conversation:start', async (event: any) => {
      const { payload } = event.detail;
      await this._startConversation(payload);
    });

    this._doc.defaultView?.addEventListener('public-profile:open', async (event: any) => {
      const { userId, user, isPreviewMode, existingConversation, placePayload, isConversation } = event.detail;
      await ModalActions.openFullScreenModal(
        this.modalCtrl,
        ProfilePreviewPage,
        {
          userId,
          user,
          isPreviewMode,
          existingConversation,
          placePayload,
          isConversation,
        });
    });

    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe((val) => {
        const url                      = (val as NavigationEnd).urlAfterRedirects;
        const urlParts                 = url.split('/');
        this.tabService.isSubPage      = urlParts.length > 2;
        this.tabService.currentTabName = (urlParts[1] || TabRouteName.search) as TabRouteName;
      });

    if (!environment.production) {
      const prefersDark = this._doc.defaultView?.matchMedia('(prefers-color-scheme: dark)');

      prefersDark?.addEventListener(
        'change',
        mediaQuery => this.toggleDarkTheme(mediaQuery.matches),
      );
    }
  }

  async toggleDarkTheme(isDarkModeEnabled: boolean) {

    // Add body class to body
    document.body.classList.toggle('dark', isDarkModeEnabled);

    // Update theme-color meta tag
    const style = this._doc.defaultView?.getComputedStyle(document.body);

    // When the dark class is applied,
    // the value of the --ion-color-primary
    // is set to a dark color (#222428)

    const primaryColor = style?.getPropertyValue('--ion-color-primary').trim();

    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', primaryColor as string);

    // Update keyboard style

    if (Capacitor.isNativePlatform()) {

      if (isDarkModeEnabled) {
        await Keyboard.setStyle({
          style: KeyboardStyle.Dark,
        });

        if (isPlatform('android')) {
          await StatusBar.setBackgroundColor({ color: environment.androidHeaderColor });
        } else {
          await StatusBar.setStyle({ style: Style.Dark });
        }

      } else {
        await Keyboard.setStyle({
          style: KeyboardStyle.Light,
        });

        if (isPlatform('android')) {
          await StatusBar.setBackgroundColor({ color: environment.androidHeaderColor });
        } else {
          await StatusBar.setStyle({ style: Style.Light });
        }
      }
    }

  }

  async onChangeLang(lang: string) {
    const user = User.getCurrent();
    
    try {
      await this.updateInstallation({ localeIdentifier: lang });
      await this._storage.setLang(lang);
      await user.save({ lang }, { context: { SKIP_AFTER_SAVE: Globals.SKIP_AFTER_SAVE, SKIP_BEFORE_SAVE: Globals.SKIP_BEFORE_SAVE } });
    } catch (error) {
      console.warn(error);
    }

    this._doc.defaultView?.location.reload();
  }

  async loadCurrentUser() {
    const user = User.getCurrent();

    try {
      await user?.fetch();
      await this.preference.loadUserBookingPreferences(user.id);
      
      if (user?.username) {
        this._stripeIdentityService.watchUser();
        this._identityService.initAutoRefresh();
        this._placeService.loadFavorites();

        if (!user?.isAnonymous()) {
          if (!Capacitor.isNativePlatform()) {
            this._oneSignalService.setupOneSignalWeb();
          }

          this.setupHubspotChat();
          this._ngxPendoService.initialize({
            id: user.id,
            name: user.name,
            email: user.email,
            role: 'customer',
          }, {
            id: 'syzlCustomer',
            name: 'Syzl Customer',
          });
        }

        // If the user hasn't completed their required sign-up steps (agreeing to terms and providing a phone number),
        // ensure that they do
        if (!user?.isAnonymous() && user?.isBasicIncomplete()) {
          const modal    = await ModalActions.open(
            this.modalCtrl,
            SignInSocialCompletionComponent,
            { user },
            undefined,
            false,
          );
          const response = await modal.onDidDismiss();
          if (response.data.action !== ModalStatics.CONFIRM || response.data.isIncomplete) {
            this.onLogOut();
            return;
          }
        }
      }
    } catch (error: any) {
      if (error.code === 209) {
        this.onLogOut();
      }
    }
  }

  setupParse() {
    // Slide.getInstance();
    // Review.getInstance();
    // Place.getInstance();
    // Category.getInstance();
    // User.getInstance();

    // Parse.initialize(environment.appId);
    // this._doc.defaultView?.localStorage.setItem("parseIsInitialized", "true");
    // (Parse as any).serverURL = environment.serverUrl;
    // (Parse as any).idempotency = true;
    ParseInitializer.init();
  }

  async presentNotificationModal(notification: any) {

    const modal = await this.modalCtrl.create({
      component: NotificationPage,
      componentProps: { notification },
    });

    await modal.present();

    const { data } = await modal.onDidDismiss();

    if (data) {

      let page = null;
      let queryParams = {};

      if (data.placeId) {
        page = TabRouteUrl.search + '/places/' + data.placeId;
      } else if (data.categoryId) {
        page = TabRouteUrl.search + '/places';
        queryParams = { cat: data.categoryId };
      }

      this.router.navigate([page], { queryParams });
    }
  }

  async setupHubspotChat() {
    if (this._doc.defaultView) {
      this._doc.defaultView.hsConversationsSettings = { loadImmediately: false };
      const { email, token } = await this._hubspotService.getIdentificationToken();
      this._doc.defaultView.hsConversationsSettings = {
        identificationEmail: email,
        identificationToken: token,
      };
    }
    this._doc.defaultView?.HubSpotConversations?.widget.load();
  }

  setupPush() {

    PushNotifications.addListener('registration', async (token: Token) => {

      const appInfo = await App.getInfo();

      const info = await Device.getInfo();
      const languageCode = await Device.getLanguageCode();
      const id = await this._storage.getInstallationObjectId();

      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const installationId = nanoid();

      const data: any = {
        channels: [],
        deviceToken: token.value,
        appName: appInfo.name,
        appVersion: appInfo.version,
        appIdentifier: appInfo.id,
        deviceType: info.platform,
        localeIdentifier: this.preference.lang || languageCode.value,
        timeZone: timezone,
        badge: 0,
      };

      if (!id) {
        data.installationId = installationId;
      }

      const user = User.getCurrent();
      data.user = user?.toPointer();

      const { objectId } = await this._installationService.save(id, data);

      await this._storage.setInstallationObjectId(id || objectId);

      const installation = await this._installationService.getOne(id || objectId);

      const granted: PermissionState = 'granted';
      const { receive } = await PushNotifications.checkPermissions();

      if (receive === granted && installation.isPushEnabled) {
        this._storage.setIsPushEnabled(true);
        this.notifications.isPushEnabled = true;
      }

    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', (error: any) => {
      console.warn('Error on registration: ' + JSON.stringify(error));
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      console.warn('Push received: ' + JSON.stringify(notification));

      if (environment.oneSignal?.appId) {
        return;
      }

      const notificationData = notification.data;

      /*
      if (this.isAndroid) {
        notificationData = JSON.parse(notification.data);
      }
      */

      this.presentNotificationModal(notificationData);
    });

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed', (action: ActionPerformed) => {
      if (environment.oneSignal?.appId) {
        return;
      }

      let notificationData = action.notification.data;

      if (isPlatform('android')) {
        notificationData = JSON.parse(action.notification.data.data);
      }

      let page: string | null = null;
      let queryParams         = {};

      if (notificationData.placeId) {
        page = TabRouteUrl.search + '/places/' + notificationData.placeId;
      } else if (notificationData.categoryId) {
        page = TabRouteUrl.search + '/places';
        queryParams = { cat: notificationData.categoryId };
      }

      if (page) {
        this._ngZone.run(() => {
          this.router.navigate([page], { queryParams });
        });
      }
    });
  }

  setupOneSignal() {

    const appId = environment?.oneSignal?.appId;

    if (appId) {

      if (this.isCapacitorNative) {
        App.addListener('appUrlOpen', (data) => {
          const url  = data.url || '';
          const slug = url.split('.syzl.io').pop();
          if (slug) {
            this.router.navigateByUrl(slug);
          }
        });
      }

      OneSignal.setAppId(appId);

      OneSignal.setNotificationWillShowInForegroundHandler((event: NotificationReceivedEvent) => {

        const notification = (event as any).notification;

        const notificationData: any = {
          ...notification.additionalData,
        };

        notificationData.title = notification.title;
        notificationData.body = notification.body;
        notificationData.image_url = notification.bigPicture;

        if (this.platform.is('ios') &&
          typeof notification.attachments === 'object' &&
          notification.attachments !== null) {
          for (const [_, value] of Object.entries(notification.attachments)) {
            notificationData.image_url = value;
          }
        }

        this.presentNotificationModal(notificationData);

        this._audioService.play('ping');

        event.complete(undefined);
      });

      OneSignal.setNotificationOpenedHandler((res: OpenedEvent) => {
        console.warn('[ONE_SIGNAL] push opened', res);

        const notificationData: any = res.notification.additionalData;

        let page: string | null = null;
        let queryParams         = {};

        if (notificationData.placeId) {
          page = TabRouteUrl.search + '/places/' + notificationData.placeId;
        } else if (notificationData.categoryId) {
          page = TabRouteUrl.search + '/places';
          queryParams = { cat: notificationData.categoryId };
        }

        if (page) {
          this._ngZone.run(() => {
            this.router.navigate([page], { queryParams });
          });
        }

      });
      
    }
  }

  async setupStatusBar() {
    if (this.platform.is('ios')) {
      await StatusBar.setStyle({ style: Style.Dark });
    } else {
      await StatusBar.setBackgroundColor({ color: environment.androidHeaderColor });
    }
    await StatusBar.show();
  }

  async updateInstallation(data: any = {}) {

    try {

      if (Capacitor.isNativePlatform()) {

        const payload: any = {
          user: null,
          ...data,
        };

        const id = await this._storage.getInstallationObjectId();

        if (!id) {
          return;
        }

        const user = User.getCurrent();
        payload.user = user?.toPointer();

        const res = await this._installationService.save(id, payload);
        console.warn('Installation updated', res);
      }

    } catch (error) {
      console.warn(error);
    }

  }

  async onLogOut() {

    try {

      await this.showLoadingView({ showOverlay: true });
      await this._userService.logout();
      this.preference.resetBookingPrefs();
      if (Capacitor.isNativePlatform()) {
        await this.updateInstallation();
      }
      await this.dismissLoadingView();

      this._doc.defaultView?.location.reload();

    } catch (err: any) {

      if (err.code === 209) {
        this._doc.defaultView?.location.reload();
      }

      this.dismissLoadingView();
    }

  }

  /**
   * Sets up an interval to check for a new app version every 6 hours
   */
  private async _initializeAppUpdater() {
    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()`.
    const appIsStable$                  = this._appRef.isStable.pipe(first(isStable => isStable === true));
    const everySixHours$                = interval(6 * 60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    // Check for updates every 6 hours
    everySixHoursOnceAppIsStable$.subscribe(async () => {
      try {
        await this._swUpdate.checkForUpdate();
      } catch (err) {
        console.error('Failed to check for updates:', err);
      }
    });

    // Subscribe to version updates
    this._swUpdate
      .versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(() => this._promptToUpdateApp());
  }

  /**
   * Prompt the user to reload the page
   */
  private async _promptToUpdateApp() {
    const trans = await this.getTrans(['APP_UPDATE_TITLE', 'APP_UPDATE_TEXT', 'APP_UPDATE_CONFIRM', 'APP_UPDATE_DENY']);
    const modal = await ModalActions.openModalContentOnly(this.modalCtrl, {
      title:   trans.APP_UPDATE_TITLE,
      content: trans.APP_UPDATE_TEXT,
      buttons: [
        {
          action: ModalStatics.GO_BACK,
          text:   trans.APP_UPDATE_DENY,
          color:  'danger',
          fill:   'outline',
        },
        {
          action: ModalStatics.CONFIRM,
          text:   trans.APP_UPDATE_CONFIRM,
        },
      ],
    });
    const { data } = await modal.onDidDismiss();
    if (data.action === ModalStatics.CONFIRM) {
      document.location.reload();
    } else {
      setTimeout(() => this._promptToUpdateApp(), 10 * 60 * 1000);
    }
  }

  private async _startConversation(payload: any) {
    const trans = await this.getTrans(['MESSAGE_HOST', 'MESSAGE_LOWER_CASE', 'MESSAGE_HOST_PLACEHOLDER', 'CANCEL', 'SEND_MESSAGE', 'ABOUT_LOWERCASE']);
    const { placeId, placeTitle, hostId, hostName } = payload;
    const modal = await ModalActions.openModalWithTextArea(this.modalCtrl, {
      title: trans.MESSAGE_HOST,
      content: '',
      textarea: {
        label: `${trans.MESSAGE_LOWER_CASE} ${hostName} ${trans.ABOUT_LOWERCASE} ${placeTitle}`,
        placeholder: trans.MESSAGE_HOST_PLACEHOLDER,
      },
      buttons: [
        {
          action: ModalStatics.GO_BACK,
          text: trans.CANCEL,
          fill: 'outline',
        },
        {
          action: ModalStatics.CONFIRM,
          text: trans.SEND_MESSAGE,
          color: 'primary',
        },
      ],
    });

    const { data } = await modal.onDidDismiss();

    if (data.action === ModalStatics.CONFIRM) {

      const conversationSid = await this._conversationService.messageHost(
        placeId    || '',
        placeTitle || '',
        hostId     || '',
        data.msg,
        false,
      );
      if (conversationSid) {
        const action = await this.showConfirm('Message sent! Do you want to go to the conversation?', {
          OK: 'Yes',
          CANCEL: 'No',
        });

        if (action) {
          const topModal = await this.modalCtrl.getTop();
          await topModal?.dismiss();
          this.navigateTo(`${this.tabService.currentTabUrl}/conversation/${conversationSid}`);
        }
      }
    }
  }

  async checkForMaintenance() {
    if (await this._maintenanceService.getCurrentMaintenance()) this.isUnderMaintenance = true;
  }
}
