import { Pipe, PipeTransform } from '@angular/core';

interface IGoogleDisplayAddress {
  street: string;
  city: string;
  province: string;
  country: string;
}

@Pipe({
  name: 'googleAddressFormatSplit'
})
export class GoogleAddressFormatSplitPipe implements PipeTransform {

  transform(address: string): IGoogleDisplayAddress {
    const splitAddress = address.split(', ');
    const street  = splitAddress[0];
    const city    = splitAddress[1];
    const province = splitAddress[2];
    const country = splitAddress[splitAddress.length - 1];
    return { street, city, province, country };
  }

}
