import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ChildWindowService } from 'src/app/services/child-window.service';
import { Preference } from 'src/app/services/preference.service';
import { TabRouteName, TabService } from 'src/app/services/tab.service';
import { User } from 'src/app/services/user.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {

  year = new Date().getFullYear();

  facebook: string | null      = null;
  instagram: string | null     = null;
  linkedin: string | null      = null;
  phone: string | null         = null;
  whatsapp: string | null      = null;
  email: string | null         = null;
  googlePlayUrl: string | null = null;
  appStoreUrl: string | null   = null;

  pageUrl = '';

  isLoggedIn = false;

  readonly LOGIN      = 'login';
  readonly LOGOUT     = 'logout';

  constructor(
    public preference: Preference,
    public appConfigService: AppConfigService,
    private _childWindowService: ChildWindowService,
    private _tabService: TabService,
    @Inject(DOCUMENT) private _doc: Document
  ) { }

  ngOnInit() {
    window.addEventListener('user:login', this._onLogin);
    this._onLogin();
    this.loadData();
  }

  ngOnDestroy(): void {
    window.removeEventListener('user:login', this._onLogin);
  }

  async loadData() {
    try {
      const config       = await this.appConfigService.load();
      this.facebook      = config?.about?.facebook;
      this.instagram     = config?.about?.instagram;
      this.linkedin      = config?.about?.linkedin;
      this.phone         = config?.about?.phone;
      this.whatsapp      = config?.about?.whatsapp;
      this.email         = config?.about?.email;
      this.googlePlayUrl = config?.about?.googlePlayUrl;
      this.appStoreUrl   = config?.about?.appStoreUrl;
    } catch (error) { /* do nothing */ }
  }

  openPage(url: string) {
    switch (url) {
      case this.LOGIN:
        this._tabService.requestBaseRoute(TabRouteName.profile);
        break;
      case this.LOGOUT:
        window.dispatchEvent(new CustomEvent('user:logout'));
        break;
    }
  }

  openUrl(url: string, e: Event) {
    if (!url) {return;}
    Browser.open({ url });

    (e.currentTarget as HTMLElement)?.blur();
  }

  cookiesSettings() {
    const win = this._doc.defaultView;
    const hsp = (win as any)._hsp = (win as any)._hsp || [];

    if (hsp) {
      (win as any)._hsp.push(['showBanner']);
    }
  }

  private _onLogin = () => {
    this.isLoggedIn = User.getCurrent().isLoggedIn();
  };
}
