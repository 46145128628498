import { Component, Output, EventEmitter, ViewChild, ElementRef, Input, inject } from '@angular/core';
import { ActionSheetController, ActionSheetButton, IonicModule } from '@ionic/angular';
import { ParseFile } from 'src/app/services/parse-file-service';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { ComponentBase } from 'src/app/components-standalone/component-base';
import { lastValueFrom } from 'rxjs';
import { ConvertHeicService } from 'src/app/services/convert-heic.service';
import { MimeInfo, simpleMimeTypeSniffer } from 'src/app/helpers/simple-mime-type-sniffer';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from 'src/app/directives/directives.module';

@Component({
  selector: 'app-upload-box',
  templateUrl: './upload-box.component.html',
  styleUrls: ['./upload-box.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    IonicModule,
    CommonModule,
    DirectivesModule
  ]
})
export class UploadBoxComponent extends ComponentBase {
  @ViewChild('fileInput', { static: true }) fileInput!: ElementRef;
  @Input() text            = '';
  @Input() icon            = '';
  @Input() accept          = 'image/jpeg,image/png,image/gif';
  @Input() mainUploadError = false;
  @Input() file: any       = null;
  @Input() isUploadBtn     = false;
  @Input() btnText         = '';
  @Input() btnUploading    = '';
  @Input() btnIconColor    = 'primary';
  @Input() btnIconType     = '';
  @Input() btnFill         = 'clear';
  @Input() btnIcon         = true;
  @Input() expand?         = '';
  @Input() disabled        = false;
  @Input() btnIconName     = 'cloud-upload';
  @Input() isMainImg       = false;
  @Input() isPlaceImg      = false;
  @Input() isPlaceholder   = false;
  @Input() multiple        = false;
  @Output() fileUploaded   = new EventEmitter<Parse.File[] | null>();

  private readonly _KB_TO_MB_DIVISOR = 1048576; // 1024 Bytes in 1 KB ... and 1024 KB in 1 MB
  private readonly _MAX_FILE_SIZE_MB = 19;
  private _actionSheetCtrl           = inject(ActionSheetController);
  private _heicService               = inject(ConvertHeicService);
  readonly btnsLeft                  = 'absolute left-1 top-1';
  readonly btnsRight                 = 'absolute right-1 top-1';

  isUploading                        = false;

  onBoxTouched() {
    if (this.disabled) return;
    if (this._platform.is('capacitor')) {
      this.presentActionSheet();
    } else {
      this.fileInput.nativeElement.click();
    }
  }

  async onRemove() {
    const message = await lastValueFrom(this._translateService.get('CONFIRM_DELETE_IMAGE'));
    const confirm = await this.showConfirm(message);
    if (confirm) {
      this.file        = null;
      this.isUploading = false;
      this.fileUploaded.emit(null);
    }
  }

  /**
   * onFileChanged
   * - This component only accepts a single file
   * @param event 
   * @returns 
   */
  async onFileChanged(event: Event) {
    // Loop over the files to ensure they are all within the file size limits
    const filesToEmit: Parse.File[] = [];
    const el = event?.target as HTMLInputElement;
    if (el) {
      const files = el.files;
      const fileLength = files?.length || 0;

      for (let i = 0; i < fileLength; i++) {
        let file = files?.item(i);

        if (!file) {
          const trans = await lastValueFrom(this._translateService.get('ERROR_FILE_NOT_FOUND'));
          await this.showToast(trans);
          return;
        }
        if (this._isFileTooLarge(file?.size)) {
          await this._showFileTooLargeError(file?.size);
          return;
        }

        // Check for valid mime types
        const mimeInfo = await simpleMimeTypeSniffer.getInfo(file);
        if (!mimeInfo.isValid) {
          await this._showInvalidFileTypeError();
          return;
        }

        // Convert heic to JPEG
        if (mimeInfo.isHeic) {
          try {
            // This could take a while, so we set isUploading to true
            this.isUploading = true;
            const buffer = await this._heicService.convertToParseFile(file);
            const fileName = file.name.substring(0, file.name.lastIndexOf('.')) + '.jpg';
            file = new File([buffer], fileName, { type: 'image/jpeg' });
            // Update the mimeInfo from heic to jpeg
            simpleMimeTypeSniffer.setAsJPG(mimeInfo);
          } catch (error) {
            const trans = await lastValueFrom(this._translateService.get('ERROR_FILE_NO_HEIC'));
            await this.showToast(trans);
          } finally {
            this.isUploading = false;
          }
        }
        const uploadedFile = await this.doUpload(file, false, mimeInfo) as Parse.File;
        filesToEmit.push(uploadedFile);

      }
      this.fileUploaded.emit(filesToEmit);
    }
    el.value = ''; // Reset the input so it can be used again
  }

  convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader   = new FileReader();
    reader.onerror = reject;
    reader.onload  = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

  async chooseImage(source: CameraSource) {

    try {

      const image = await Camera.getPhoto({
        quality:            70,
        allowEditing:       false, // true,
        correctOrientation: true,
        resultType:         CameraResultType.Base64,
        source,
      });

      if (image && image.base64String) {
        if (this._isFileTooLarge(image.base64String.length)) {
          await this._showFileTooLargeError(image.base64String.length);
          return;
        }

        const jsFile = new File([this._b64toBlob(image.base64String, 'image/' + image.format)], 'image.jpg', { type: 'image/' + image.format });
        await this.doUpload(jsFile, false);
      }

    } catch (error) {
      console.warn(error);
    }

  }

  async chooseFile() {
    try {
      const filePicker = await FilePicker.pickFiles({
        multiple: this.multiple,
        readData: true,
        types:    this.accept.split(',')
      });

      const files = filePicker.files;
      if (files && files.length > 0) {
        // Check the file
        for (const file of files) {
          if (this._isFileTooLarge(file.size)) {
            await this._showFileTooLargeError(file.size);
            return;
          }

          if (file.data) {
            const parts = file.name.split('.');
            const ext = parts[parts.length - 1];
            const mimeType = file.mimeType;

            const jsFile = new File([atob(file.data)], file.name, { type: mimeType });
            await this.doUpload(jsFile, false, {
              ext,
              mimeType,
              isHeic: false,
              isImage: mimeType.startsWith('image'),
              isPDF: mimeType.endsWith('pdf'),
              isValid: true
            });
          }
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }

  async presentActionSheet() {

    const trans = await lastValueFrom(this._translateService.get([
      'PHOTO_LIBRARY',
      'CAMERA',
      'FILE_PICKER',
      'CANCEL',
      'CHOOSE_AN_OPTION']
    ));

    const buttons: ActionSheetButton[] = [];
    buttons.push({ text: trans.PHOTO_LIBRARY, handler: () => this.chooseImage(CameraSource.Photos) });
    buttons.push({ text: trans.CAMERA,        handler: () => this.chooseImage(CameraSource.Camera) });
    if (this.accept.indexOf('pdf') > -1) {
      buttons.push({ text: trans.FILE_PICKER, handler: () => this.chooseFile() });
    }
    buttons.push({ text: trans.CANCEL, role: 'cancel' });

    const actionSheet = await this._actionSheetCtrl.create({ header: trans.CHOOSE_AN_OPTION, buttons });

    return await actionSheet.present();

  }

  async doUpload(fileOrBase64: File | string, isBase64: boolean = true, mimeInfo?: MimeInfo) {
    let fileToBeUploaded: Parse.File | null = null;
    try {
      this.isUploading                      = true;
      fileToBeUploaded                      = await ParseFile.upload(fileOrBase64, isBase64, mimeInfo?.ext);
    } catch (error) {
      const trans                           = await lastValueFrom(this._translateService.get(['FILE_UPLOAD_ERROR', 'CLOSE']));
      await this.showToast(trans.FILE_UPLOAD_ERROR);
    } finally {
      this.isUploading                      = false;
      this._changeDetectorRef.markForCheck();
      return fileToBeUploaded;
    }
  }

  private _kbToMb(fileSize: number = 0) {
    return fileSize / this._KB_TO_MB_DIVISOR;
  }

  private _isFileTooLarge(fileSize: number = 0) {
    return this._kbToMb(fileSize) > this._MAX_FILE_SIZE_MB;
  }

  private async _showFileTooLargeError(fileSize: number = 0) {
    fileSize    = this._kbToMb(fileSize);
    const trans = await lastValueFrom(this._translateService.get('ERROR_FILE_TOO_LARGE', { fileSize }));
    await this.showToast(trans);
  }

  private async _showInvalidFileTypeError() {
    const trans = await lastValueFrom(this._translateService.get('ERROR_FILE_TYPE'));
    await this.showToast(trans);
  }

  private _b64toBlob(b64Data: string, contentType: string = '', sliceSize: number = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
      
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }
}
