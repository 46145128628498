import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { IBasePageModal } from '../pages/base-page-modal/i-base-page-modal';
import { ModalActions } from './modal-actions';

@Injectable({
  providedIn: 'root'
})
export class ModalManagerService {

  private _modals: IBasePageModal[] = [];
  private _lastClosedModalName      = '';
  private _history!: History;
  private _win!: Window;

  /**
   * Listen to popstate events on the window
   * @param _document 
   */
  constructor(
    @Inject(DOCUMENT) private _document: Document
  ) {
    this._win     = this._document.defaultView as Window;
    this._history = this._win.history;

    // Listen for navigation events
    this._win.addEventListener('popstate', () => {
      this._handleNavBack();
    });
  }

  /**
   * Modals are added to the array of open modals
   * - Modals are added in the CONSTRUCTOR of BasePageModal
   * - There is a 1 second delay is in the CONSTRUCTOR of BasePageModal to ensure
   *   the modals have an ID and that they have drawn themselves on the screen
   * @param modal 
   */
  add(modal: IBasePageModal) {
    // Add to the history state
    this._history.pushState({
      modal: true,
      name: modal.name
    }, '');
    // Add the modal to our array of modals
    this._modals.push(modal);
  }

  /**
   * Modals are removed from the array when ngOnDestroy fires
   * - IF your page has its own ngOnDestroy, you MUST call super.ngOnDestroy() to
   *   ensure the method within BasePageModal is called (e.g. booking-creation.page.ts)
   * @param modal 
   */
  remove(modal: IBasePageModal) {
    this._lastClosedModalName = '';
    // Find the modal from the Array
    const name  = modal.name;
    const index = this._modals.findIndex(item => item.name === name);
    // Remove the modal from the array
    if (index > -1) {
      if (this._isMyState(modal)) {
        this._lastClosedModalName = name;
        this._history.back();
      }
      this._modals.splice(index, 1);
    }
  }

  /**
   * When the Back navigation button is clicked, we need to check to see if there are any
   * open modals. If so, we close them.
   * - IF the new nav state does NOT contain modal=true, we can remove ALL open modals and reset our array
   * - IF the new nav state DOES contain modal=true, we try to close the modal and remove it from the array
   */
  private async _handleNavBack() {
    if (!this._isHistoryStateModal()) {
      for (let i = this._modals.length - 1; i >= 0; i--) {
        try {
          const modal = this._modals[i];
          await modal.modalCtrl.dismiss({ action: ModalActions.GO_BACK });
          this._modals.splice(i, 1);
        } catch (error) {
          console.warn(error);
        }
      }
      this._modals = [];
    } else {
      if (this._modals.length > 0) {
        const last  = this._modals.length - 1;
        const modal = this._modals[last];
        if (!this._isMyState(modal)) {
          try {
            modal.modalCtrl.dismiss({ action: ModalActions.GO_BACK });
          } catch (error) {}
          this._modals.splice(last, 1);
        }
        if (this._lastClosedModalName === modal.name) {
          this._history.back();
          this._modals.splice(last, 1);
        }
      }
    }
  }

  /**
   * Returns true when the History state has a property of modal=true
   * @returns 
   */
  private _isHistoryStateModal() {
    return this._history.state?.modal ? this._history.state.modal : false;
  }

  /**
   * Returns true when the current state's name matches the modal's name
   * @param modal 
   * @returns 
   */
  private _isMyState(modal: IBasePageModal) {
    const state = this._history.state;
    return state.modal && state.name === modal.name;
  }
}
