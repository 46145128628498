import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, Renderer2, ViewChild } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-syzl-logo',
  templateUrl: './syzl-logo.component.html',
  styleUrls: ['./syzl-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule
  ]
})
export class SyzlLogoComponent implements AfterViewInit, OnDestroy {

  @Input() logoType: 'color' | 'sugar' | 'lemon' | 'primary' | 'secondary' | 'white' | 'black' = 'color';

  readonly COLOR = 'color';
  readonly SUGAR = 'sugar';
  readonly LEMON = 'lemon';
  readonly PRIMARY   = 'primary';
  readonly SECONDARY = 'secondary';
  readonly WHITE     = 'white';
  readonly BLACK     = 'black';


  @ViewChild('svg') private _svg!: ElementRef;

  private _width    = 0;
  private _height   = 0;
  private _resizeObserver: ResizeObserver | null = null;

  constructor(
    private _el: ElementRef,
    private _render: Renderer2
  ) { }

  /**
   * Ensure we listen to events when the parent's size changes
   */
  ngAfterViewInit(): void {
    this._resizeObserver = new ResizeObserver(() => this._setSize());
    this._setSize();
  }

  ngOnDestroy(): void {
    this._resizeObserver?.unobserve(this._el.nativeElement);
  }

  private _setSize() {
    const el               = this._el.nativeElement  as HTMLElement;
    const elWidth          = el.clientWidth;
    const elHeight         = el.clientHeight;

    if (elWidth === this._width && elHeight === this._height) {
      return;
    }

    this._width            = elWidth;
    this._height           = elHeight;
    const svg              = this._svg?.nativeElement as SVGViewElement;
    const svgWidth         = svg.viewBox.baseVal.width;
    const svgHeight        = svg.viewBox.baseVal.height;
    const widthMultiplier  = svgHeight / svgWidth;
    const heightMultiplier = svgWidth  / svgHeight;
    const svgNewWidth      = heightMultiplier > 1 ? elWidth  : elWidth  * heightMultiplier;
    const svgNewHeight     = widthMultiplier  > 1 ? elHeight : elHeight * widthMultiplier;

    this._render.setStyle(svg, 'width',  svgNewWidth  + 'px');
    this._render.setStyle(svg, 'height', svgNewHeight + 'px');
  }
}
