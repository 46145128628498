// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
}

ion-card {
  margin: 0;
  border-radius: 10px;
}
ion-card.selected {
  border: 1px solid var(--ion-color-primary);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNlY3Rpb24tcHJldmlldy1jYXJkLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsY0FBQTtFQUNBLGtCQUFBO0FBQ0Y7O0FBRUE7RUFDRSxTQUFBO0VBQ0EsbUJBQUE7QUFDRjtBQUNFO0VBQ0UsMENBQUE7QUFDSiIsImZpbGUiOiJzZWN0aW9uLXByZXZpZXctY2FyZC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgZGlzcGxheTogYmxvY2s7XG4gIHBvc2l0aW9uOnJlbGF0aXZlO1xufVxuXG5pb24tY2FyZCB7XG4gIG1hcmdpbjogMDtcbiAgYm9yZGVyLXJhZGl1czogMTBweDtcblxuICAmLnNlbGVjdGVkIHtcbiAgICBib3JkZXI6IDFweCBzb2xpZCB2YXIoLS1pb24tY29sb3ItcHJpbWFyeSk7XG4gIH1cbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/section-preview-card/section-preview-card.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;AACF;;AAEA;EACE,SAAA;EACA,mBAAA;AACF;AACE;EACE,0CAAA;AACJ;AACA,gmBAAgmB","sourcesContent":[":host {\n  display: block;\n  position:relative;\n}\n\nion-card {\n  margin: 0;\n  border-radius: 10px;\n\n  &.selected {\n    border: 1px solid var(--ion-color-primary);\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
