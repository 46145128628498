import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreakpointService {

  private readonly _MEDIA_QUERIES: { [key: string]: string } = {
    xs: '(min-width: 0)',
    sm: '(min-width: 576px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 992px)',
    xl: '(min-width: 1200px)',
    isXs: '(max-width: 575.98px)',
    isSm: '(min-width: 576px) and (max-width: 767.98px)',
    isMd: '(min-width: 768px) and (max-width: 991.98px)',
    isLg: '(min-width: 992px) and (max-width: 1199.98px)',
    isPhone:           Breakpoints.Handset,
    isPhonePortrait:   Breakpoints.HandsetPortrait,
    isPhoneLandscape:  Breakpoints.HandsetLandscape,
    isTablet:          Breakpoints.Tablet,
    isTabletPortrait:  Breakpoints.TabletPortrait,
    isTabletLandscape: Breakpoints.TabletLandscape,
    isWeb:             Breakpoints.Web,
    isWebPortrait:     Breakpoints.WebPortrait,
    isWebLandscape:    Breakpoints.WebLandscape,
  };

  private _xs$ = new BehaviorSubject<boolean>(false);
  private _sm$ = new BehaviorSubject<boolean>(false);
  private _md$ = new BehaviorSubject<boolean>(false);
  private _lg$ = new BehaviorSubject<boolean>(false);
  private _xl$ = new BehaviorSubject<boolean>(false);

  private _isXs$ = new BehaviorSubject<boolean>(false);
  private _isSm$ = new BehaviorSubject<boolean>(false);
  private _isMd$ = new BehaviorSubject<boolean>(false);
  private _isLg$ = new BehaviorSubject<boolean>(false);
  private _isXl$ = new BehaviorSubject<boolean>(false);

  private _isPhone$           = new BehaviorSubject<boolean>(false);
  private _isPhonePortrait$   = new BehaviorSubject<boolean>(false);
  private _isPhoneLandscape$  = new BehaviorSubject<boolean>(false);

  private _isTablet$          = new BehaviorSubject<boolean>(false);
  private _isTabletPortrait$  = new BehaviorSubject<boolean>(false);
  private _isTabletLandscape$ = new BehaviorSubject<boolean>(false);

  private _isWeb$             = new BehaviorSubject<boolean>(false);
  private _isWebPortrait$     = new BehaviorSubject<boolean>(false);
  private _isWebLandscape$    = new BehaviorSubject<boolean>(false);

  constructor(
    private _observer: BreakpointObserver
  ) {
    this._observer
      .observe(Object.keys(this._MEDIA_QUERIES).map((key: string) => this._MEDIA_QUERIES[key]))
      .subscribe((breakpointState: BreakpointState) => this._setCurrentBreakpoints(breakpointState));
  }

  get xs$() { return this._xs$.asObservable(); }
  get sm$() { return this._sm$.asObservable(); }
  get md$() { return this._md$.asObservable(); }
  get lg$() { return this._lg$.asObservable(); }
  get xl$() { return this._xl$.asObservable(); }

  get isXs$() { return this._isXs$.asObservable(); }
  get isSm$() { return this._isSm$.asObservable(); }
  get isMd$() { return this._isMd$.asObservable(); }
  get isLg$() { return this._isLg$.asObservable(); }
  get isXl$() { return this._isXl$.asObservable(); }

  get isPhone$()           { return this._isPhone$.asObservable(); }
  get isPhonePortrait$()   { return this._isPhonePortrait$.asObservable(); }
  get isPhoneLandscape$()  { return this._isPhoneLandscape$.asObservable(); }

  get isTablet$()          { return this._isTablet$.asObservable(); }
  get isTabletPortrait$()  { return this._isTabletPortrait$.asObservable(); }
  get isTabletLandscape$() { return this._isTabletLandscape$.asObservable(); }

  get isWeb$()             { return this._isWeb$.asObservable(); }
  get isWebPortrait$()     { return this._isWebPortrait$.asObservable(); }
  get isWebLandscape$()    { return this._isWebLandscape$.asObservable(); }

  private _setCurrentBreakpoints(breakpointState: BreakpointState) {
    this._xs$.next(breakpointState.breakpoints[this._MEDIA_QUERIES.xs]);
    this._sm$.next(breakpointState.breakpoints[this._MEDIA_QUERIES.sm]);
    this._md$.next(breakpointState.breakpoints[this._MEDIA_QUERIES.md]);
    this._lg$.next(breakpointState.breakpoints[this._MEDIA_QUERIES.lg]);
    this._xl$.next(breakpointState.breakpoints[this._MEDIA_QUERIES.xl]);

    this._isXs$.next(breakpointState.breakpoints[this._MEDIA_QUERIES.isXs]);
    this._isSm$.next(breakpointState.breakpoints[this._MEDIA_QUERIES.isSm]);
    this._isMd$.next(breakpointState.breakpoints[this._MEDIA_QUERIES.isMd]);
    this._isLg$.next(breakpointState.breakpoints[this._MEDIA_QUERIES.isLg]);
    this._isXl$.next(breakpointState.breakpoints[this._MEDIA_QUERIES.xl]);

    this._isPhone$.next(breakpointState.breakpoints[this._MEDIA_QUERIES.isPhone]);
    this._isPhonePortrait$.next(breakpointState.breakpoints[this._MEDIA_QUERIES.isPhonePortrait]);
    this._isPhoneLandscape$.next(breakpointState.breakpoints[this._MEDIA_QUERIES.isPhoneLandscape]);

    this._isTablet$.next(breakpointState.breakpoints[this._MEDIA_QUERIES.isTablet]);
    this._isTabletPortrait$.next(breakpointState.breakpoints[this._MEDIA_QUERIES.isTabletPortrait]);
    this._isTabletLandscape$.next(breakpointState.breakpoints[this._MEDIA_QUERIES.isTabletLandscape]);

    this._isWeb$.next(breakpointState.breakpoints[this._MEDIA_QUERIES.isWeb]);
    this._isWebPortrait$.next(breakpointState.breakpoints[this._MEDIA_QUERIES.isWebPortrait]);
    this._isWebLandscape$.next(breakpointState.breakpoints[this._MEDIA_QUERIES.isWebLandscape]);
  }
}
