import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Optional, Output, Renderer2, Self, SimpleChanges, viewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, FormsModule, NgControl, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { IonInput, IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { parsePhoneNumber, getCountryCodeForRegionCode, PhoneNumberFormat, ParsedPhoneNumber } from 'awesome-phonenumber';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { PhoneNumberCountrySelectionComponent } from './components/phone-number-country-selection/phone-number-country-selection.component';
import { ISO_COUNTRY_CODES_CA_US } from './phone-number-codes';

@Component({
  standalone: true,
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    PhoneNumberCountrySelectionComponent,
    TranslateModule,
  ],
})
export class PhoneNumberComponent implements ControlValueAccessor, OnInit, OnChanges, OnDestroy {

  private readonly _DEFAULT_COUNTRY_CODE = ISO_COUNTRY_CODES_CA_US[0].code;
  private readonly _DEFAULT_DISPLAY      = 'national';
  private readonly _DEFAULT_SAVE_AS      = 'e164';
  private readonly _DEFAULT_REGION_CODE  = 1;

  @Input() bold = false;
  @Input() country: string | null             = '';
  @Input() displayNumberAs: PhoneNumberFormat = this._DEFAULT_DISPLAY;
  @Input() saveNumberAs: PhoneNumberFormat    = this._DEFAULT_SAVE_AS;
  @Input() showCompletionIcon                 = false;
  @Input() hostPhoneNumber?: number;
  @Input() isEditKitchen = false;

  @Output() countryChange = new EventEmitter<string>();

  countryCode = '';
  countryNum  = 1;
  pattern     = '';

  // These are required for [ngModel], [formGroup], and [formControlName]
  value: any;
  onChange: any;
  onTouched: any;

  formGroup = new FormGroup({
    country: new FormControl(''),
    num:     new FormControl('')
  }, { updateOn: 'change', validators: [phoneValidator] });

  private _ionInput = viewChild(IonInput, { read: ElementRef });
  private _destroy$ = new Subject<void>();

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private _el: ElementRef<HTMLElement>,
    private _modalCtrl: ModalController,
    private _changeRef: ChangeDetectorRef,
    private _renderer: Renderer2,
  ) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    this._listenToInputChanges();
    this._overrideFormMethods();
    this._setInitialValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.country && changes.country.currentValue) {
      this.formGroup.controls.country.patchValue(changes.country.currentValue);
      this.formGroup.markAsTouched();
      this.formGroup.updateValueAndValidity();
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  /**
   * Return a string containing only numeric values from the
   * phone.number form field.
   */
  get phoneNumberDigits(): string {
    return this.formGroup.controls.num.value?.replace(/\D/g, '') || '';
  }

  /**
   * Return an @see PhoneNumber value created from the
   * phoneNumberDigits and currently selected country code.
   */
  get phoneNumber(): ParsedPhoneNumber {
    const digits = this.phoneNumberDigits;
    const num    = parsePhoneNumber(digits, { regionCode: this.countryCode }) || parsePhoneNumber(digits);
    return num;
  }

  async openCountries() {
    const selected = this.countryCode;
    const modal    = await this._modalCtrl.create({
      component: PhoneNumberCountrySelectionComponent,
      componentProps: { selected },
      presentingElement: this._el.nativeElement as HTMLElement
    });
    await modal.present();

    const { data } = await modal.onWillDismiss();
    if (data && data !== '') {
      this.countryCode = data;
      this.countryNum  = getCountryCodeForRegionCode(data);
      this.formGroup.controls.country.patchValue(data);
      this.formGroup.controls.num.markAsTouched();
      this.formGroup.markAsTouched();
      this._changeRef.markForCheck();
    }
  }

  /* **********************************************************************
   * The following interface is required to use formControlName as an input
   * - It links these events to NgControl to automatically update the form
   * *********************************************************************/
  blur() {
    this.onTouched();
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup.disable();
    } else {
      this.formGroup.enable();
    }
  }

  /**
   * Sets up an listener on the phone number input
   * - When the number is updated, it is checked against the phone validator
   */
  private _listenToInputChanges() {
    let isFirstChange = true;
    this.formGroup.controls.num.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(300),
        takeUntil(this._destroy$)
      )
      .subscribe((val: string | null) => {
        const isPristine = this.ngControl.control?.pristine;
        const num        = this.phoneNumber;
        const displayAs  = num.number && num.number[this.displayNumberAs] ? num.number[this.displayNumberAs] : val;
        this.formGroup.controls.num.patchValue(displayAs, { emitEvent: false });
        
        if (num.valid && num.number?.input !== '') {
          const newCountry = parsePhoneNumber(num.number.e164);
          this.countryCode = newCountry.regionCode  || this._DEFAULT_COUNTRY_CODE;
          this.countryNum  = newCountry.countryCode || this._DEFAULT_REGION_CODE;
          const saveAs     = num.number[this.saveNumberAs] || val;
          this.onChange(saveAs);
        } else {
          this.onChange(null);
        }
        
        // Update the base control's touched property
        if (this.formGroup.controls.num.touched) {
          this.onTouched();
        }

        // If we're on the first change, we need to ensure the underlying form control
        // remains in a pristine state
        if (isPristine && isFirstChange) {
          this.ngControl.control?.markAsPristine();
          isFirstChange = false;
        }
        this._changeRef.markForCheck();
      });
    
    this.formGroup.controls.country.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this.countryCode = this.formGroup.controls.country.value || '';
      this.countryChange.emit(this.countryCode);
    });
  }

  /**
   * We need to catch and override the markAs... calls for the underlying formControl
   * When they are called, we need to also set this formControl to the same state
   */
  private _overrideFormMethods(...args: any[]) {
    if (this.ngControl.control) {
      // Override pristine behaviour
      const markAsPristine                  = this.ngControl.control?.markAsPristine;
      this.ngControl.control.markAsPristine = (opts?: { onlySelf?: boolean | undefined }) => {
        markAsPristine?.apply(this.ngControl.control, args as any);
        this.formGroup.markAsPristine(opts);
      };

      // Override ditry behaviour
      const markAsDirty                  = this.ngControl.control?.markAsDirty;
      this.ngControl.control.markAsDirty = (opts?: { onlySelf?: boolean | undefined }) => {
        markAsDirty?.apply(this.ngControl.control, args as any);
        this.formGroup.markAsDirty(opts);
        this._changeRef.markForCheck();
      };

      // Override markAllAsTouched
      const markAllAsTouched                  = this.ngControl.control?.markAllAsTouched;
      this.ngControl.control.markAllAsTouched = () => {
        markAllAsTouched?.apply(this.ngControl.control, args as any);
        this.formGroup.markAllAsTouched();
        this._changeRef.markForCheck();
      };

      // Override markAsPending
      const markAsPending                  = this.ngControl.control?.markAsPending;
      this.ngControl.control.markAsPending = (opts?: { onlySelf?: boolean | undefined; emitEvent?: boolean | undefined }) => {
        markAsPending?.apply(this.ngControl.control, args as any);
        this.formGroup.markAsPending(opts);
        this._changeRef.markForCheck();
      };

      // Override markAsTouched
      const markAsTouched                  = this.ngControl.control?.markAsTouched;
      this.ngControl.control.markAsTouched = (opts?: { onlySelf?: boolean | undefined }) => {
        markAsTouched?.apply(this.ngControl.control, args as any);
        this.formGroup.markAsTouched(opts);
        this._changeRef.markForCheck();
      };
      
      // Override markAsUntouched
      const markAsUntouched                  = this.ngControl.control?.markAsUntouched;
      this.ngControl.control.markAsUntouched = (opts?: { onlySelf?: boolean | undefined }) => {
        markAsUntouched?.apply(this.ngControl.control, args as any);
        this.formGroup.markAsUntouched(opts);
        this._changeRef.markForCheck();
      };

      // Override updateValueAndValidity
      const updateValueAndValidity                  = this.ngControl.control?.updateValueAndValidity;
      this.ngControl.control.updateValueAndValidity = (opts?: { onlySelf?: boolean | undefined; emitEvent?: boolean | undefined }) => {
        updateValueAndValidity?.apply(this.ngControl.control, args as any);
        this.formGroup.updateValueAndValidity(opts);
        this._changeRef.markForCheck();
      };
    }
  }

  /**
   * Sets up the initial values on the phone input and country selection
   */
  private _setInitialValues() {
    // Add validators to the num control
    if (this.ngControl.control?.hasValidator(Validators.required)) {
      this.formGroup.controls.num.addValidators([Validators.required]);
    }

    // Set the initial values
    // The inputs for this control won't change at runtime, so we don't need do to this in onChanges
    const phoneNumber = parsePhoneNumber(this.value || '');
    this.countryCode  = phoneNumber.regionCode  || this._DEFAULT_COUNTRY_CODE;
    this.countryNum   = phoneNumber.countryCode || this._DEFAULT_REGION_CODE;

    this.formGroup.controls.num.patchValue(this.value);
    this.formGroup.controls.country.patchValue(phoneNumber.regionCode || this._DEFAULT_COUNTRY_CODE);
  }
}

/**
 * Validates a FormGroup containing `country` and `number` fields that
 * are used to generate a @see PhoneNumber. Valid numbers are
 * determined by the PhoneNumber.isValid() method.
 */
const phoneValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const country = control.get('country');
  const num     = control.get('num');

  if (num && num.value && country && country.value) {
    const phoneNumber = parsePhoneNumber(num.value, { regionCode: country.value });
    if (!phoneNumber.valid) {
      return { invalidPhone: true };
    }
  }
  return null;
};
