import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { IYearMonthDay } from '../interfaces/i-year-month-day';

@Pipe({
  name: 'calendarDateDisplay'
})
export class CalendarDateDisplayPipe implements PipeTransform {

  transform(yearMonthDay: IYearMonthDay): string {
    const d = DateTime.fromObject({
      year: yearMonthDay.year,
      month: yearMonthDay.month,
      day: yearMonthDay.day
    });
    return d.weekdayLong + ', ' + d.monthLong + ' ' + d.day;
  }

}
