import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { UserBookingPrefFragment } from 'src/generated/graphql';

export enum UserRoles {
  customer = 'customer',
  super_admin = 'super_admin'
}

@Injectable({
  providedIn: 'root'
})
export class User extends Parse.User {

  constructor() {
    super();
  }

  static getInstance() {
    return this;
  }

  static getCurrent() {
    return User.current() as User;
  }

  isBasicIncomplete() {
    return !this.firstName
        || !this.lastName
        || !this.get('authorize')
        || !this.phone
        || !this.email
        || !this.get('terms');
  }

  isAnonymous(): boolean {
    return this.authData && Object.prototype.hasOwnProperty.call(this.authData, 'anonymous');
  }

  isLoggedIn(): boolean {
    const user      = User.getCurrent();
    const firstName = user.get('firstName');
    
    return firstName !== undefined && firstName !== null;
  }

  isLoggedInViaPassword() {
    return !this.authData;
  }

  loginAnonymously(): Promise<any> {
    return Parse.AnonymousUtils.logIn();
  }

  /**
   * Becomes with session token
   * @param sessionToken
   * @returns with session token
   */
  becomeWithSessionToken(sessionToken: string): Promise<any> {
    return User.become(sessionToken);
  }

  /**
   * Log in request
   * @param [data]
   * @returns in cloud
   */
  loginInCloud(data: any = {}): Promise<{ sessionToken: string }> {
    return Parse.Cloud.run('loginInCloud', data);
  }

  /**
   * Sends a sign up request to the api
   * @param [data]
   * @returns up in cloud
   */
  signUpInCloud(data: any = {}): Promise<{ sessionToken: string }> {
    return Parse.Cloud.run('signUpInCloud', data);
  }

  /**
   * Invite a user to complete a Certn criminal record check (CRC)
   */
  certnInvite(data: any = {}): Promise<{ certnId: string; certnStatus: string; certnUrl: string }> {
    return Parse.Cloud.run('certnInvite', data);
  }


  /**
   * Signs in
   * @param [data]
   * @returns Promise<User>
   */
  signIn(data: any = {}): Promise<User> {
    const user = new User();
    user.username = data.username;
    user.password = data.password;
    return user.logIn();
  }

  logout() {
    return User.logOut();
  }

  recoverPassword(email: string) {
    return User.requestPasswordReset(email);
  }

  loginViaFacebook(): Promise<User> {
    return new Promise((resolve, reject) => {
      (Parse.FacebookUtils.logIn(null) as any)
        .then((user: User) => resolve(user), (err: any) => reject(err));
    });
  }

  /**
   * Logs in with an auth provider
   * @param provider
   * @param [authData]
   * @param [extraData]
   * @returns with
   */
  loginWith(provider: string, authData: any = {}, extraData: any = {}): Promise<User> {
    const user: any = new User();
    user.set(extraData);
    return user._linkWith(provider, authData);
  }

  isFacebookLinked(): boolean {
    return Parse.FacebookUtils.isLinked(User.getCurrent());
  }

  linkFacebook(authData: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      Parse.FacebookUtils.link(User.getCurrent(), authData, {
        success: (res: any) => resolve(res), error: (err: any) => reject(err)
      });
    });
  }

  unlinkFacebook(): Promise<any> {
    return new Promise((resolve, reject) => {
      Parse.FacebookUtils.unlink(User.getCurrent(), {
        success: (res: any) => resolve(res), error: (err: any) => reject(err)
      });
    });
  }

  generateUsername(): Promise<{ username: string }> {
    return Parse.Cloud.run('getGeneratedName');
  }

  validateUniqueUserField(prop: string, val: string) {
    return Parse.Cloud.run('validateUniqueUserField', { prop, val });
  }

  get name(): string {
    return this.get('name');
  }

  set name(val) {
    this.set('name', val);
  }

  get firstName(): string {
    return this.get('firstName');
  }

  set firstName(val) {
    this.set('firstName', val);
  }

  get lastName(): string {
    return this.get('lastName');
  }

  set lastName(val) {
    this.set('lastName', val);
  }

  get email(): string {
    return this.get('email');
  }

  set email(val) {
    this.set('email', val);
  }

  get username(): string {
    return this.get('username');
  }

  set username(val) {
    this.set('username', val);
  }

  get password(): string {
    return this.get('password');
  }

  set password(val) {
    this.set('password', val);
  }

  get phone(): any {
    return this.get('phone');
  }

  set phone(val) {
    this.set('phone', val);
  }

  get photo(): any {
    return this.get('photo');
  }

  set photo(val) {
    this.set('photo', val);
  }

  get province(): string {
    return this.get('province');
  }

  set province(val: string) {
    this.set('province', val);
  }
 
  get insuredAmount() {
    return this.get('insuredAmount');
  }

  set insuredAmount(val: number) {
    this.set('insuredAmount', val);
  }

  get insurance(): any {
    return this.get('insurance');
  }

  set insurance(val) {
    this.set('insurance', val);
  }

  get foodSafety(): any {
    return this.get('foodSafety');
  }

  set foodSafety(val) {
    this.set('foodSafety', val);
  }

  get authData(): any {
    return this.get('authData');
  }

  set authData(val) {
    this.set('authData', val);
  }

  /**
   * Get/Set the ReCaptcha token
   */
  get captcha() {
    return this.get('captcha');
  }

  set captcha(token: string) {
    this.set('captcha', token);
  }

  /**
   * Get/Set the ReCaptcha action
   */
  get captchaAction() {
    return this.get('captchaAction');
  }

  set captchaAction(val: string) {
    this.set('captchaAction', val);
  }

  /**
   * Get/Set user preferences
   */
  get preferences() {
    return this.get('preferences');
  }

  set preferences(pref: UserBookingPrefFragment) {
    this.set('preferences', pref);
  }

  /**
   * Get user certnScore
   */
  get certnScore() {
    return this.get('certnScore');
  }

  get certnApplicationUrl() {
    return this.get('certnApplicationUrl');
  }

  /**
   * Get identity information
   */
  get identityStatus() {
    return this.get('identityStatus');
  }

  get identityReportId() {
    return this.get('identityReportId');
  }

  get identityLastError() {
    return this.get('identityLastError');
  }

  get insuranceDocumentId() {
    return this.get('insuranceDocumentId');
  }

  get insuranceStatus() {
    return this.get('insuranceStatus');
  }

  get insuranceInquiryId() {
    return this.get('insuranceInquiryId');
  }

  get insuranceDocumentUrl() {
    return this.get('insuranceDocumentUrl');
  }

  get foodSafetyDocumentId() {
    return this.get('foodSafetyDocumentId');
  }

  get foodSafetyStatus() {
    return this.get('foodSafetyStatus');
  }

  set foodSafetyStatus(val) {
    this.set('foodSafetyStatus', val);
  }

  get foodSafetyInquiryId() {
    return this.get('foodSafetyInquiryId');
  }

  get foodSafetyDocumentUrl() {
    return this.get('foodSafetyDocumentUrl');
  }

  get kitchenPreference() {
    return this.get('bookingPreference');
  }

  get notificationPreference() {
    return this.get('notificationPreference');
  }

  set notificationPreference(preference: any) {
    this.set('notificationPreference', preference);
  }

  get lang() {
    return this.get('lang');
  }

  set lang(lang: string) {
    this.set('lang', lang);
  }

  get areCallsEnabled(): boolean {
    return this.get('areCallsEnabled');
  }

  set areCallsEnabled(val: boolean) {
    this.set('areCallsEnabled', val);
  }

  get type(): string {
    return this.get('type');
  }

  set type(val: string) {
    this.set('type', val);
  }

}

Parse.Object.registerSubclass('_User', User);
