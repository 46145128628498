import { Pipe, PipeTransform } from '@angular/core';
import { getCountryCodeForRegionCode } from 'awesome-phonenumber';

@Pipe({
  standalone: true,
  name: 'countryCodeFromRegionCode'
})
export class CountryCodeFromRegionCodePipe implements PipeTransform {

  transform(regionCode: string): string {
    return '+' + getCountryCodeForRegionCode(regionCode);
  }

}
