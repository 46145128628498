import {
  Injectable,
  ComponentFactoryResolver,
  ApplicationRef,
  ComponentRef,
  ElementRef,
  Injector,
} from '@angular/core';

import { LightboxComponent } from '../components/lightbox/lightbox.component';

@Injectable({ providedIn: 'root' })
export class LightboxService {
  private _lightboxElement: ElementRef;

  constructor(
    private _applicationRef: ApplicationRef,
    private _injector: Injector,
    private _resolver: ComponentFactoryResolver
  ) {
    const lightbox: ComponentRef<LightboxComponent> = this._resolver
      .resolveComponentFactory(LightboxComponent)
      .create(this._injector);
    this._lightboxElement = lightbox.instance.element;
    this._applicationRef.attachView(lightbox.hostView);
    document.body.appendChild(lightbox.location.nativeElement);
  }

  getLightboxElement(): HTMLElement {
    return this._lightboxElement.nativeElement as HTMLElement;
  }
}
