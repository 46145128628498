import { Injectable } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';
import { ModalController, Platform } from '@ionic/angular';
import * as Parse from 'parse';
import { LocalStorage } from './local-storage';
import { LocationAddress } from '../interfaces/location-address';
import { GeocoderService } from './geocoder.service';
import { ModalActions } from '../modals/modal-actions';
import { ModalShareMyLocationComponent } from '../modals/modal-share-my-location/modal-share-my-location.component';
import { BehaviorSubject } from 'rxjs';
import { ModalStatics } from './modal.service';

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {

  private _isCheckingLocation = false;
  private _lastPosition: LocationAddress | null = null;
  private _lastPosition$ = new BehaviorSubject<LocationAddress | null>(null);

  constructor(
    private _platform: Platform,
    private _geocoderService: GeocoderService,
    private _storage: LocalStorage,
    private _modalCtrl: ModalController,
  ) { }
  
  get isCheckingLocation() {
    return this._isCheckingLocation;
  }

  get lastPosition() {
    return this._lastPosition;
  }

  get lastPosition$() {
    return this._lastPosition$.asObservable();
  }

  setCurrentPosition(location: LocationAddress): Promise<LocationAddress> {

    this._lastPosition = location;
    const point        = this.toParseGeoPoint(location);

    window.dispatchEvent(new CustomEvent('installation:update', {
      detail: { location: point },
    }));

    this._emitLastPosition();
    return this._storage.setLastPosition(location);
  }

  /**
   * init
   * - This should ONLY be called from AppComponent
   * - ALL other uses should be via the lastPosition$ BehaviorSubject
   * @returns 
   */
  async getCurrentPosition(): Promise<LocationAddress | null> {
    if (!this._lastPosition) {
      try {
        const savedPosition = await this._storage.getLastPosition();
        if (savedPosition) {
          this._lastPosition = savedPosition;
        }
      } catch (error) {
        console.warn(error);
      }
    }

    if (this._lastPosition) {
      this._emitLastPosition();
      return this._lastPosition;
    }

    let locationAddress: LocationAddress | null = null;
    let isGranted      = true;
    let permission     = { location: 'unknown', coarseLocation: 'unknown' };
    let skipSyzlPrompt = false;
    
    // Attempt to read the currentn permission status
    // All browsers, except Safari <= 15 support this
    this._isCheckingLocation = true;
    try {
      permission = await Geolocation.checkPermissions();
    } catch (error) {
      console.error(error);
      // Old browser that don't support permission status getters,
      // so we'll skip the pretty Syzl prompt step
      skipSyzlPrompt = true;
    }


    if(!skipSyzlPrompt && permission.location !== 'granted') {
      const modal  = await ModalActions.open(this._modalCtrl, ModalShareMyLocationComponent, null, ['modal-fullscreen', 'small-modal-popup']);
      const { data } = await modal.onWillDismiss();

      if (data.action === ModalStatics.CONFIRM){
        // For desktop and other web browsers, we do nothing and assume true.
        // - the next section will display the browser Allow/Deny box
        // For iOS/Android app, use Capacitor to request the permission
        if (this._platform.is('capacitor')) {
          const status = await Geolocation.requestPermissions();
          isGranted    = status.location === 'granted';
        }
      } else {
        isGranted = false;
      }
    }

    // Attempt to get the details address
    if (isGranted) {
      locationAddress = await this._tryDetailedLocationAddress();
    }

    // Try to get the location from the IP address
    if (locationAddress === null) {
      locationAddress = await this._tryCoarseLocationAddress();
    }

    if (!locationAddress) {
      this._isCheckingLocation = false;
      return null;
    }

    // Use the Geocoordinates to get the address
    try {
      const data = await this._geocoderService.reverse({
        lat: locationAddress.latitude,
        lng: locationAddress.longitude,
      });
      let address = '';

      if (data.results && data.results.length) {
        address = data.results[0].formatted_address;
      }

      locationAddress.address = address;
      this._lastPosition      = locationAddress;
      this._storage.setLastPosition(this._lastPosition);

    } catch (error) {
      console.error(error);
      this._isCheckingLocation = false;
      return null;
    }

    const point = this.toParseGeoPoint(locationAddress);

    window.dispatchEvent(new CustomEvent('installation:update', {
      detail: { location: point },
    }));

    this._isCheckingLocation = false;
    this._emitLastPosition();
    return this._lastPosition;
  }

  toParseGeoPoint(coords: any): Parse.GeoPoint {
    return new Parse.GeoPoint(coords.latitude, coords.longitude);
  }

  async geoToCountry(coords: { lat: any; lng: any }) {
    const { results } = await this._geocoderService.reverse({ lat: coords.lat, lng: coords.lng });
    const country = results[0].address_components.find((el: any) => el.types.includes('country'))?.short_name || '';
    return country;
  }

  private _emitLastPosition() {
    const val$ = this._lastPosition$.getValue();
    if (val$?.address !== this._lastPosition?.address) {
      this._lastPosition$.next(this._lastPosition);
    }
  }

  /**
   * Tries to get the detailed location address
   * @returns 
   */
  private async _tryDetailedLocationAddress(): Promise<LocationAddress | null> {
    try {
      const { coords } = await Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0,
      });
  
      return {
        latitude:  coords.latitude,
        longitude: coords.longitude,
        address:   '',
      };
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  /**
   * Tries to get the location from the IP address
   * @returns 
   */
  private async _tryCoarseLocationAddress(): Promise<LocationAddress | null> {
    try {
      const res = await this._geocoderService.ipToGeo();
      return {
        latitude:  Number(res.latitude),
        longitude: Number(res.longitude),
        address:   '',
      };
    } catch (error) {
      console.warn(error);
    }
    return null;
  }
}
