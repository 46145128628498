import { Pipe, PipeTransform } from '@angular/core';
import { CleaningHoursPipe } from './cleaning-hours.pipe';

@Pipe({
  name: 'timePlusCleaning'
})
export class TimePlusCleaningPipe implements PipeTransform {

  constructor(
    private _cleaningHoursPipe: CleaningHoursPipe
  ) {}

  transform(productionTime: number): number {
    if (!productionTime || typeof productionTime !== 'number' || productionTime < 1) {
      return 0;
    }
    return productionTime + this._cleaningHoursPipe.transform(productionTime);
  }

}
