import { Injectable } from '@angular/core';
import * as Parse from 'parse';

@Injectable({
  providedIn: 'root'
})
export class HubspotService {
  constructor() { }

  async getIdentificationToken(): Promise<{ token: string, email: string }> {
    return await Parse.Cloud.run('generateIdentificationToken');
  }
}
