import { AbstractControl, ValidatorFn } from '@angular/forms';

export function instagramHandleValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const instagramHandleRegex = /^[a-zA-Z0-9._]{1,30}$/;

    if (control.value && !instagramHandleRegex.test(control.value)) {
      return { 'invalidInstagramHandle': { value: control.value } };
    }

    return null;
  };
}