import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { delay, filter } from 'rxjs/operators';

interface IIntersection {
  entry: IntersectionObserverEntry;
  observer: IntersectionObserver;
}

@Directive({
  selector: '[appIntersectionObserver]'
})
export class IntersectionObserverDirective implements OnInit, OnDestroy, AfterViewInit {
  @Input() debounceTime = 0;
  @Input() threshold    = 1;
  @Output() visible     = new EventEmitter<boolean>();

  private _observer: IntersectionObserver | undefined;
  private _subject$ = new Subject<IIntersection>();

  constructor(
    private _elementRef: ElementRef
  ) { }
  
  ngOnInit(): void {
    this._createObserver();
  }

  ngAfterViewInit(): void {
    this._startObserver();
  }

  ngOnDestroy(): void {
    if (this._observer) {
      this._observer.disconnect();
      this._observer = undefined;
    }

    this._subject$.unsubscribe();
  }

  private async _isVisible(element: HTMLElement) {
    return new Promise((resolve) => {
      const observer = new IntersectionObserver(([entry]) => {
        resolve(entry.intersectionRatio > 0);
        observer.disconnect();
      });

      observer.observe(element);
    });
  }

  private _createObserver() {
    const options = {
      rootMargin: '0px',
      threshold: this.threshold
    };

    this._observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this._subject$.next({entry, observer});
        }

        if (entry.intersectionRatio === 0) {
          this._subject$.next({entry, observer});
        }
      });
    });
  }

  private _startObserver() {
    if (!this._observer) {
      return;
    }

    this._observer.observe(this._elementRef.nativeElement);

    this._subject$
      .pipe(delay(this.debounceTime), filter(Boolean))
      .subscribe(async ({ entry }: any) => {
        const target = entry.target as HTMLElement;
        const isStillVisible = await this._isVisible(target);

        if (isStillVisible) {
          this.visible.emit(true);
        } else {
          this.visible.emit(false);
        }
      });
  }
}
