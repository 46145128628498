import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dollarSign'
})
export class DollarSignPipe implements PipeTransform {

  transform(value: string, currency?: string): string {
    return value ? `${currency || ''}$${value}` : '';
  }

}
