export class MimeInfo {
  mimeType = 'unknown';
  ext      = '';
  isImage  = false;
  isHeic   = false;
  /* Remove word documents
  isMsNew  = false;
  isMsOld  = false;
  */
  isPDF    = false;
  isValid  = false;
}

enum MimeTypeMagicNumbers {
  GIF    = '47494638',
  HEIC   = '6674797068656963',
  HEIF   = '6674797068656966',
  JPG1   = 'ffd8ffe0',
  JPG2   = 'ffd8ffe1',
  JPG3   = 'ffd8ffe2',
  JPG4   = 'ffd8ffe3',
  JPG5   = 'ffd8ffe8',
  JPG6   = 'ffd8ffdb',
  JPG7   = 'ffd8ffee',
  /* Remove word documents
  MS_NEW = '504b0304',
  MS_OLD = 'd0cf11e0a1b11ae1',
  */
  PDF    = '255044462d',
  PNG    = '89504e47',
}

enum MimeTypes {
  GIF    = 'image/gif',
  HEIC   = 'image/heic',
  JPG    = 'image/jpg',
  /* Remove word documents
  MS_NEW = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  MS_OLD = 'application/msword', 
  */
  PDF    = 'application/pdf',
  PNG    = 'image/png',
}

enum MimeTypeExt {
  GIF    = 'gif',
  HEIC   = 'heic',
  JPG    = 'jpg',
  /* Remove word documents
  MS_NEW = 'docx',
  MS_OLD = 'doc', 
  */
  PDF    = 'pdf',
  PNG    = 'png',
}

/**
 * SimpleMimeTypeSniffer
 * - Syzl allows images (JPEGs, GIFs, PNGs, HEICs), PDFs, and WORD docs
 * - A file's mimeType is encoded in the first several bytes of the file. For images such as
 *   GIFs, PNGs, and JPEGs, it is the first 4 bytes. For HEIC files, the first four byte are empty,
 *   and the magic number is encoded at bytes 5 through 12 and it spells out ftypheic or ftypheif
 * - https://stackoverflow.com/questions/18299806/how-to-check-file-mime-type-with-javascript-before-upload
 * - https://en.wikipedia.org/wiki/List_of_file_signatures
 */
class SimpleMimeTypeSniffer {

  getInfo = async (file: File): Promise<MimeInfo> => {
    const buffer   = await file.slice(0, 16).arrayBuffer();
    const mimeInfo = new MimeInfo();
    this._isImage(buffer, mimeInfo);                      // Check for Image
    !mimeInfo.isValid && this._isPDF(buffer, mimeInfo);   // Check for PDF
    !mimeInfo.isValid && this._isHeic(buffer, mimeInfo);  // Check for Heic
    // !mimeInfo.isValid && this._isMsNew(buffer, mimeInfo); // Check for new MS files (will return true for .docx, .xlsx, .zip, etc.)
    // !mimeInfo.isValid && this._isMsOld(buffer, mimeInfo); // Check for old MS files (will return true for .doc, .xls, .msi, etc.)

    return mimeInfo;
  };

  /**
   * setAsJPG
   * - Converts the mimeInfo object to type JPEG (used when converting an heic to jpg)
   * @param mimeInfo 
   * @returns 
   */
  setAsJPG = (mimeInfo: MimeInfo) => this._setType(mimeInfo, MimeTypes.JPG, MimeTypeExt.JPG, 'isImage');

  /**
   * _isHeic
   * - Check for HEIC and HEIF files
   * @param buffer 
   * @param mimeInfo 
   * @returns 
   */
  private _isHeic = (buffer: ArrayBuffer, mimeInfo: MimeInfo): MimeInfo => {
    const signature = this._getSignature(buffer);
    if (signature.includes(MimeTypeMagicNumbers.HEIC) || signature.includes(MimeTypeMagicNumbers.HEIF)) {
      this._setType(mimeInfo, MimeTypes.HEIC, MimeTypeExt.HEIC, 'isHeic');
    }
    return mimeInfo;
  };

  /**
   * _isImage
   * - Check for GIF, JPG, and PNG
   * @param buffer 
   * @param mimeInfo 
   * @returns 
   */
  private _isImage = (buffer: ArrayBuffer, mimeInfo: MimeInfo): MimeInfo => {
    const signature = this._getSignatureForImage(buffer);
    switch (signature) {
      case MimeTypeMagicNumbers.PNG:
        this._setType(mimeInfo, MimeTypes.PNG, MimeTypeExt.PNG, 'isImage');
        break;
      case MimeTypeMagicNumbers.GIF:
        this._setType(mimeInfo, MimeTypes.GIF, MimeTypeExt.GIF, 'isImage');
        break;
      case MimeTypeMagicNumbers.JPG1:
      case MimeTypeMagicNumbers.JPG2:
      case MimeTypeMagicNumbers.JPG3:
      case MimeTypeMagicNumbers.JPG4:
      case MimeTypeMagicNumbers.JPG5:
      case MimeTypeMagicNumbers.JPG6:
      case MimeTypeMagicNumbers.JPG7:
        this._setType(mimeInfo, MimeTypes.JPG, MimeTypeExt.JPG, 'isImage');
        break;
    }
    return mimeInfo;
  };

  /**
   * _isMsNew
   * - Check the new MS file format (Note that this will also return true for other container file types (like ZIP))
   * @param buffer 
   * @param mimeInfo 
   * @returns 
   
  private _isMsNew = (buffer: ArrayBuffer, mimeInfo: MimeInfo): MimeInfo => {
    const signature = this._getSignatureForMsOld(buffer);
    if (signature === MimeTypeMagicNumbers.MS_NEW) {
      this._setType(mimeInfo, MimeTypes.MS_NEW, MimeTypeExt.MS_NEW, 'isMsNew');
    }
    return mimeInfo;
  };
  */

  /**
   * _isMsOld
   * - Check the old MS file format (Note that this will also return true for other container file types (like MSI))
   * @param buffer 
   * @param mimeInfo 
   * @returns 
   
  private _isMsOld = (buffer: ArrayBuffer, mimeInfo: MimeInfo): MimeInfo => {
    const signature = this._getSignatureForMsNew(buffer);
    if (signature === MimeTypeMagicNumbers.MS_OLD) {
      this._setType(mimeInfo, MimeTypes.MS_OLD, MimeTypeExt.MS_OLD, 'isMsOld');
    }
    return mimeInfo;
  };
  */

  /**
   * _isPDF
   * - Check for PDFs
   * @param buffer 
   * @param mimeInfo 
   * @returns 
   */
  private _isPDF = (buffer: ArrayBuffer, mimeInfo: MimeInfo): MimeInfo => {
    const signature = this._getSignatureForPDF(buffer);
    if (signature === MimeTypeMagicNumbers.PDF) {
      this._setType(mimeInfo, MimeTypes.PDF, MimeTypeExt.PDF, 'isPDF');
    }
    return mimeInfo;
  };

  /**
   * _setType
   * - Updates the MimeInfo object
   * @param mimeInfo 
   * @param mimeType 
   * @param ext 
   * @param boolPropName 
   */
  private _setType = (mimeInfo: MimeInfo, mimeType: string, ext: string, boolPropName: keyof MimeInfo) => {
    mimeInfo.mimeType = mimeType;
    mimeInfo.ext      = ext;
    for (const prop in mimeInfo) {
      if (prop.startsWith('is')) {
        (mimeInfo[prop as keyof MimeInfo] as boolean) = false;
      }
    }
    (mimeInfo[boolPropName] as boolean) = true;
    mimeInfo.isValid = true;
  };
  
  private _getSignature         = (buffer: ArrayBuffer) => Array.from(new Uint8Array(buffer)).map(byte => byte.toString(16).padStart(2, '0')).join('');
  private _getSignatureForImage = (buffer: ArrayBuffer) => this._getSignature(buffer.slice(0, 4));
  private _getSignatureForMsNew = (buffer: ArrayBuffer) => this._getSignature(buffer.slice(0, 4));
  private _getSignatureForMsOld = (buffer: ArrayBuffer) => this._getSignature(buffer.slice(0, 8));
  private _getSignatureForPDF   = (buffer: ArrayBuffer) => this._getSignature(buffer.slice(0, 5));
}

export const simpleMimeTypeSniffer = new SimpleMimeTypeSniffer();
