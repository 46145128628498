import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { TabRouteUrl } from './services/tab.service';
// import { PlaceholderPage } from './pages/placeholder/placeholder.page';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'stripe-return',
    loadChildren: () => import('./pages/stripe-return/stripe-return.module').then( m => m.StripeReturnPageModule)
  },
  {
    path: 'stripe-refresh',
    loadChildren: () => import('./pages/stripe-refresh/stripe-refresh.module').then( m => m.StripeRefreshPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('./pages/terms-and-conditions/terms-and-conditions.module').then( m => m.TermsAndConditionsPageModule)
  },
  {
    path: 'p/:id',
    loadChildren: () => import('./pages/public-profile/public-profile.module').then(m => m.PublicProfilePageModule)
  },
  {
    path: '**',
    redirectTo: TabRouteUrl.search,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
