import { animate, style, transition, trigger } from '@angular/animations';
import { createAnimation } from '@ionic/angular';

export const growIn = (baseEl: HTMLElement) => {
  const root = baseEl.shadowRoot as ShadowRoot;

  const backdropAnimation = createAnimation()
    .addElement(root.querySelector('ion-backdrop') || new HTMLElement())
    .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

  const wrapperAnimation = createAnimation()
    .addElement(root.querySelector('.modal-wrapper') || new HTMLElement())
    .keyframes([
      { offset: 0, opacity: '0', transform: 'scale(0)' },
      { offset: 1, opacity: '0.99', transform: 'scale(1)' }
    ]);

  return createAnimation()
    .addElement(baseEl)
    .easing('ease-out')
    .duration(300)
    .addAnimation([backdropAnimation, wrapperAnimation]);
};

export const fadeInOut = trigger(
  'fadeInOut', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate(
        '300ms ease-in-out',
        style({ opacity: 1 })
      )
    ]),
    transition(':leave', [
      style({ opacity: 1 }),
      animate(
        '250ms ease-in-out',
        style({ opacity: 0 })
      )
    ])
  ]);

export const fadeAndGrow = trigger(
  'fadeAndGrow', [
    transition(':enter', [
      style({ height: 0, opacity: 0 }),
      animate(
        '100ms ease-in-out',
        style({ height: '*' })
      ),
      style({ opacity: 0 }),
      animate(
        '100ms 50ms ease-in-out',
        style({ opacity: 1 })
      )
    ]),
    transition(':leave', [
      style({ opacity: 1, height: '*' }),
      animate(
        '100ms ease-in-out',
        style({ opacity: 0 })
      ),
      style({ opacity: 0, height: '*' }),
      animate(
        '100ms 50ms ease-in-out',
        style({ height: 0 })
      )
    ])
  ]);

export const enterSlideRtoL = trigger(
  'enterSlideRtoL', [
    transition(':enter', [
      style({ transform: 'translateX(100%)' }),
      animate(
        '250ms ease-in-out',
        style({ transform: 'translateX(0)' })
      )
    ]),
    transition(':leave', [
      style({ transform: 'translateX(0)' }),
      animate(
        '250ms ease-in-out',
        style({ transform: 'translateX(100%)'})
      )
    ])
  ]);

export const enterSlideLtoR = trigger(
  'enterSlideLtoR', [
    transition(':enter', [
      style({ transform: 'translateX(-100%)' }),
      animate(
        '250ms ease-in-out',
        style({ transform: 'translateX(0)' })
      )
    ]),
    transition(':leave', [
      style({ transform: 'translateX(0)' }),
      animate(
        '250ms ease-in-out',
        style({ transform: 'translateX(-100%)'})
      )
    ])
  ]);
export const shrinkOUt = (baseEl: HTMLElement) => growIn(baseEl).direction('reverse');
