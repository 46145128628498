import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { User } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class PublicProfileService {

  constructor() { }

  async getUserPublicProfile(profileUserId: string) {
    return await Parse.Cloud.run('getUserPublicProfile', { profileUserId });
  }

  async getIdByUsername(username: string) {
    return await Parse.Cloud.run('getIdByUsername', { username });
  }
}
