import { Injectable } from '@angular/core';
import { GetPlaceRateInfoGQL, GetWeeklyBusyTimesGQL, GetWeeklyBusyTimesQuery, Scalars } from 'src/generated/graphql';
import { IPlaceRateDay } from '../interfaces/i-place-rate-day';
import { IPlaceRateInfo } from '../interfaces/i-place-rate-info';
import { DateTime, Info } from 'luxon';
import { IPlaceRateDayHour } from '../interfaces/i-place-rate-day-hour';
import { ApolloQueryResult } from '@apollo/client/core';

@Injectable({
  providedIn: 'root'
})
export class PlaceRateService {

  static readonly AVAILABILITY_HIGH     = 'AVAILABILITY_HIGH';
  static readonly AVAILABILITY_MODERATE = 'AVAILABILITY_MODERATE';
  static readonly AVAILABILITY_LIMITED  = 'AVAILABILITY_LIMITED';

  private _placeRates: { [key: string]: IPlaceRateInfo } = {};

  constructor(
    private _placeRateInfo: GetPlaceRateInfoGQL,
    private _weeklyBusyTime: GetWeeklyBusyTimesGQL
  ) { }

  /**
   * Ensure the returned PlaceRate information contains day names and hour display
   * @param placeRates 
   * @returns 
   */
  applyDefaults(placeRates: IPlaceRateDay[]) {
    if (placeRates && placeRates.length > 0) {
      const weekdays = Info.weekdays('short');
      return placeRates.map((placeRateDay: IPlaceRateDay) => ({
        jsDay:    placeRateDay.jsDay,
        luxonDay: placeRateDay.luxonDay,
        name:     placeRateDay.name || weekdays[placeRateDay.luxonDay - 1],
        hours:    placeRateDay.hours.map((placeRateDayHour: IPlaceRateDayHour) => ({
          hour:    placeRateDayHour.hour,
          rate:    placeRateDayHour.rate,
          display: placeRateDayHour.display || DateTime.fromObject({ hour: placeRateDayHour.hour }).toFormat('h a').toLowerCase(),
          hover:   placeRateDayHour.hover   || DateTime.fromObject({ hour: placeRateDayHour.hour }).toFormat('h a').toLowerCase() + ' - ' + DateTime.fromObject({ hour: placeRateDayHour.hour }).plus({ hours: 1 }).toFormat('h a').toLowerCase(),
        } as IPlaceRateDayHour))
      } as IPlaceRateDay));
    }
    return placeRates;
  }

  /**
   * Generates the default rates information
   * @param defaultRate 
   * @returns 
   */
  generateDefaultRates(defaultRate: number) {
    const days  = this._fillDays();
    const hours = this._fillHours(defaultRate);
    return this._fillDayHours(days, hours);
  }

  applyToAll(placeRates: IPlaceRateDay[], rate: number) {
    if (!placeRates || placeRates.length === 0) {
      placeRates = this.generateDefaultRates(rate);
      return placeRates;
    }
    return placeRates.map(day => Object.assign({}, day, {
      hours: day.hours.map(hour => Object.assign({}, hour, { rate }))
    }));
  }

  applyDown(placeRates: IPlaceRateDay[], iDay: IPlaceRateDay, rate: number) {
    return placeRates.map(day => day.luxonDay !== iDay.luxonDay
      ? { ...day }
      : Object.assign({}, day, {
        hours: day.hours.map(hour => Object.assign({}, hour, { rate }))
      }));
  }

  applyAcross(placeRates: IPlaceRateDay[], iHour: IPlaceRateDayHour, rate: number) {
    return placeRates.map(day => Object.assign({}, day, {
      hours: day.hours.map(hour => hour.hour === iHour.hour
        ? Object.assign({}, hour, { rate })
        : {...hour})
    }));
  }

  /**
   * Returns the PlaceRate-like object for a placeId
   * @param placeId 
   * @returns 
   */
  async loadPlaceRateInfo(placeId: Scalars['ID']) {
    const placeRateInfo = await this._loadPlaceRateInfo(placeId);
    return placeRateInfo;
  }

  /**
   * Stores the updated place rate info for the place
   * @param placeId 
   * @param placeRateInfo 
   */
  setPlaceRateInfo(placeId: Scalars['ID'], placeRateInfo: IPlaceRateInfo) {
    this._placeRates[placeId] = placeRateInfo;
  }

  /**
   * Returns the rates per hour for a given day
   * @param placeId 
   * @param luxonDay 
   * @returns 
   */
  async ratesForDay(placeId: Scalars['ID'], luxonDay: number) {
    const placeRateInfo = await this.loadPlaceRateInfo(placeId);
    const hours         = placeRateInfo.rates.find(item => item.luxonDay === luxonDay)?.hours;
    return hours || [];
  }

  /**
   * Gets the availability text
   * @param placeId 
   * @returns 
   */
  async getWeeklyAvailabilityTextAndCount(placeId: Scalars['ID']) {
    const startDate    = DateTime.now();
    const endDate      = startDate.plus({ days: 7 });
    const rates        = await this.loadPlaceRateInfo(placeId);
    const busyTime     = await this._loadWeeklyBusyTime(placeId, startDate.toJSDate(), endDate.toJSDate());
    const busySlots    = this._weeklyBusySlots(busyTime);
    const availability = 1 - busySlots / rates.totalSlots;

    return {
      bookedSlots: busySlots,
      possibleSlots: rates.totalSlots,
      weeklySlots: rates.totalSlots - busySlots,
      startDate: startDate.toFormat('LLL d'),
      endDate: endDate.toFormat('LLL d'),
      availability: availability > .5
        ? PlaceRateService.AVAILABILITY_HIGH
        : availability > .25 && availability <= .5
          ? PlaceRateService.AVAILABILITY_MODERATE
          : PlaceRateService.AVAILABILITY_LIMITED
    };
  }

  /**
   * Returns the list of busy time for the week
   * @param placeId 
   * @returns 
   */
  private async _loadWeeklyBusyTime(placeId: Scalars['ID'], startDate: Date, endDate: Date): Promise<ApolloQueryResult<GetWeeklyBusyTimesQuery>> {
    return new Promise((resolve, reject) => {
      try {
        this._weeklyBusyTime.watch({
          placeId,
          startDate,
          endDate
        })
          .valueChanges
          .subscribe((response) => {
            resolve(response);
          });
      } catch (e) {
        reject(e);
      }
    });
  }

  private _weeklyBusySlots(busyTime: ApolloQueryResult<GetWeeklyBusyTimesQuery>): number {
    return busyTime?.data.bookings.edges?.map((booking) => {
      const node      = booking?.node;
      const startTime = DateTime.fromISO(node?.startTime);
      const endTime   = DateTime.fromISO(node?.endTime);
      const hours     = endTime.diff(startTime, 'hours').hours;
      return hours;
    }).reduce((prev, curr) => prev + curr, 0) || 0;
  }

  /**
   * Loads the PlaceRate-like object for a placeId
   * @param placeId 
   * @returns 
   */
  private async _loadPlaceRateInfo(placeId: Scalars['ID']): Promise<IPlaceRateInfo> {
    return new Promise<IPlaceRateInfo>((resolve) => {
      if (this._placeRates[placeId]) {
        resolve(this._placeRates[placeId]);
      } else {
        const errVal: IPlaceRateInfo = {
          objectId:    '',
          rates:       [],
          totalSlots:  0,
          avgRate:     0,
          defaultRate: 0
        };
        this._placeRateInfo
          .fetch({ placeId }, { fetchPolicy: 'no-cache' })
          .subscribe((rsp) => {
            if (rsp.data.place.placeRate && !rsp.error && !rsp.errors) {
              // Flatten the returned JavaScript into a more useable object
              const placeRate           = rsp.data.place.placeRate;
              this._placeRates[placeId] = {
                objectId:    placeRate.objectId,
                rates:       placeRate.rates.map(item => (item as any).value),
                avgRate:     placeRate.avgRate as number,
                minRate:     placeRate.minRate as number,
                maxRate:     placeRate.maxRate as number,
                totalSlots:  placeRate.totalSlots as number,
                avgSlots:    placeRate.avgSlots as number,
                minSlots:    placeRate.minSlots as number,
                maxSlots:    placeRate.maxSlots as number,
                defaultRate: placeRate.defaultRate as number
              };
              resolve(this._placeRates[placeId]);
            } else {
              resolve(errVal);
            }
          },
          err => resolve(errVal)
          );
      }
    });
  }

  /**
   * Returns the Sun - Sat list of days required for setting rates
   * @returns 
   */
  private _fillDays() {
    const days: IPlaceRateDay[] = [];
    const weekdays     = Info.weekdays('short');
    for (let i = 0; i < 7; i++) {
      const luxonDay = (i + 6) % 7;
      days.push({
        jsDay:    i,
        luxonDay: luxonDay + 1,
        name:     weekdays[luxonDay],
        hours:    []
      });
    }
    return days;
  }

  /**
   * Returns the 0 - 23 hours list required for setting rates
   * @returns 
   */
  private _fillHours(defaultRate: number) {
    const hours: IPlaceRateDayHour[] = [];
    for (let i = 0; i < 24; i++) {
      hours.push({
        hour:    i,
        display: DateTime.fromObject({ hour: i }).toFormat('h a').toLowerCase(),
        hover:   DateTime.fromObject({ hour: i }).toFormat('h a').toLowerCase() + ' - ' + DateTime.fromObject({ hour: i }).plus({ hours: 1 }).toFormat('h a').toLowerCase(),
        rate:    defaultRate
      });
    }
    return hours;
  }

  /**
   * Links the days and hours together
   * @param days 
   * @param hours 
   * @returns 
   */
  private _fillDayHours(days: IPlaceRateDay[], hours: IPlaceRateDayHour[]) {
    return days.map(day => ({
      jsDay:    day.jsDay,
      luxonDay: day.luxonDay,
      name:     day.name,
      hours:    hours.map(hour => ({ ...hour }))
    }));
  }
}
