import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

export enum TabRouteName {
  default  = '',
  bookings = 'bookings',
  inbox    = 'inbox',
  profile  = 'profile',
  search   = 'search',
}

export enum TabRouteUrl {
  default  = '/',
  bookings = '/bookings',
  inbox    = '/inbox',
  profile  = '/profile',
  search   = '/search',
}

@Injectable({
  providedIn: 'root'
})
export class TabService {

  private _baseRouteRequest$ = new Subject<TabRouteName>();
  private _currentTabName$   = new BehaviorSubject<TabRouteName>(TabRouteName.default);
  private _currentTabUrl$    = new BehaviorSubject<TabRouteUrl>(TabRouteUrl.default);
  private _isSubPage$        = new BehaviorSubject<boolean>(false);

  get baseRouteRequest$() {
    return this._baseRouteRequest$.asObservable();
  }

  get currentTabName$() {
    return this._currentTabName$.asObservable();
  }

  get currentTabUrl$() {
    return this._currentTabUrl$.asObservable();
  }

  get isSubPage$() {
    return this._isSubPage$.asObservable();
  }

  get currentTabName() {
    return this._currentTabName$.getValue();
  }

  set currentTabName(tabRouteName: TabRouteName) {
    this._currentTabName$.next(tabRouteName);
    this._currentTabUrl$.next('/' + tabRouteName as TabRouteUrl);
  }

  get currentTabUrl() {
    return this._currentTabUrl$.getValue();
  }

  get isSubPage() {
    return this._isSubPage$.getValue();
  }

  set isSubPage(val: boolean) {
    this._isSubPage$.next(val);
  }

  get tabRouteNames(): TabRouteName[] {
    return Object.values(TabRouteName);
  }

  get tabRouteUrls(): TabRouteUrl[] {
    return Object.values(TabRouteUrl);
  }

  /**
   * normalizeRoute
   * - Ensure a route doesn't start with the same names in sequence (e.g. /profile/profile/... returns as just /profile/...)
   * @param route 
   */
  normalizeRoute(route: string) {
    route       = route.startsWith('/') ? route : '/' + route;
    const parts = route.split('/');
    // part[0] will always be an emtpy string
    // part[1] will always be the base route
    if (parts.length > 2 && parts[1] === parts[2]) {
      return '/' + parts.slice(2).join('/');
    }
    return route;
  }

  /**
   * requestBaseRoute
   * - Instead of navigating directly to a base route, we output an event that the tab page listens to
   * - When the event is received, it performs a click on the appropriate tab button and Ionic's router is happier
   * @param tabRoute 
   */
  requestBaseRoute(tabRoute: TabRouteName) {
    this._baseRouteRequest$.next(tabRoute);
  }
}
