import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HtmlLoaderService {

  private _cache: { [key: string]: any } = {};

  constructor(
    private _http: HttpClient,
    private _ds: DomSanitizer
  ) { }

  load(file: string, trusted: boolean = true): Observable<string> {
    const key = file.replace(/-([a-z0-9])/g, (_, char) => char.toUpperCase());
    if (this._cache[key]) {
      return new Observable<string>((subscriber) => {
        subscriber.next(this._cache[key]);
        subscriber.complete();
      });
    }

    return this._http
      .get(file, {
        headers: new HttpHeaders()
          .set('Cache-Control', 'no-cache')
          .set('Pragma', 'no-cache')
          .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT'),
        responseType: 'text'
      })
      .pipe(
        map((response) => {
          this._cache[key] = this._mapHtml(response, trusted);
          return this._cache[key];
        })
      );
  }

  private _mapHtml(html: string, trusted: boolean): string {
    return trusted
      ? html
      : this._ds.sanitize(SecurityContext.HTML, html) || '';
  }
}
