import { NgModule } from '@angular/core';
import { CurrencyGlobalPipe } from './currency-global';
import { DateGlobalPipe } from './date-global';
import { CleaningHoursPipe } from './cleaning-hours.pipe';
import { TimePlusCleaningPipe } from './time-plus-cleaning.pipe';
import { SimpleArrayFilterPipe } from './simple-array-filter.pipe';
import { DisableCalendarDatePipe } from './disable-calendar-date.pipe';
import { CalendarDateDisplayPipe } from './calendar-date-display.pipe';
import { IsHourAmPipe } from './is-hour-am.pipe';
import { PrettyHourDisplayPipe } from './pretty-hour-display.pipe';
import { PlaceNameFromIdPipe } from './place-name-from-id.pipe';
import { SyzlStarPercentFilledPipe } from './syzl-star-percent-filled.pipe';
import { ProductionTimeFromTotalTimePipe } from './production-time-from-total-time.pipe';
import { LocalhostImageFixPipe } from './localhost-image-fix.pipe';
import { ReplaceSpaceWithPipe } from './replace-space-with.pipe';
import { CentsToDollarsPipe } from './cents-to-dollars.pipe';
import { TimeOnlyPipe } from './time-only.pipe';
import { PlaceOrderByUserPrefsPipe } from './place-order-by-user-prefs.pipe';
import { DollarSignPipe } from './dollar-sign.pipe';
import { PlaceRequiresCertnPipe } from './place-requires-certn.pipe';
import { StringReplacePipe } from './string-replace.pipe';
import { ArrayInArrayPipe } from './array-in-array.pipe';
import { ImageOptimizationPipe } from './image-optimization.pipe';
import { BytesPipe } from './bytes.pipe';
import { GetLastMessagePipe } from './get-last-message.pipe';
import { ParseFileNamePipe } from './parse-file-name.pipe';
import { RequestStartDateToHumanReadablePipe } from './request-start-date-to-human-readable.pipe';
import { UserPlacePrefMatchPipe } from './user-place-pref-match.pipe';
import { DistancePipe } from './distance.pipe';
import { EllipsisPipe } from './ellipsis.pipe';
import { InvoiceNamePipe } from './invoice-name.pipe';
import { IsPlaceNewPipe } from './is-place-new.pipe';
import { IsBookingDateUnavailablePipe } from './is-booking-date-unavailable.pipe';
import { GoogleAddressFormatSplitPipe } from './google-address-format-split.pipe';
import { SelectedBookingDisplayPipe } from './selected-booking-display.pipe';
import { CostTotalFromCartItemsPipe } from './cost-total-from-cart-items.pipe';
import { GcsFilenameSplitterPipe } from './gcs-filename-splitter.pipe';
import { TabServiceNormalizeRoutePipe } from './tab-service-normalize-route.pipe';

@NgModule({
  declarations: [
    CurrencyGlobalPipe,
    DateGlobalPipe,
    CleaningHoursPipe,
    TimePlusCleaningPipe,
    SimpleArrayFilterPipe,
    DisableCalendarDatePipe,
    CalendarDateDisplayPipe,
    IsHourAmPipe,
    PrettyHourDisplayPipe,
    PlaceNameFromIdPipe,
    SyzlStarPercentFilledPipe,
    ProductionTimeFromTotalTimePipe,
    LocalhostImageFixPipe,
    ReplaceSpaceWithPipe,
    CentsToDollarsPipe,
    TimeOnlyPipe,
    DollarSignPipe,
    PlaceOrderByUserPrefsPipe,
    PlaceRequiresCertnPipe,
    StringReplacePipe,
    ArrayInArrayPipe,
    ImageOptimizationPipe,
    BytesPipe,
    GetLastMessagePipe,
    ParseFileNamePipe,
    RequestStartDateToHumanReadablePipe,
    UserPlacePrefMatchPipe,
    DistancePipe,
    EllipsisPipe,
    InvoiceNamePipe,
    IsPlaceNewPipe,
    IsBookingDateUnavailablePipe,
    GoogleAddressFormatSplitPipe,
    SelectedBookingDisplayPipe,
    CostTotalFromCartItemsPipe,
    GcsFilenameSplitterPipe,
    TabServiceNormalizeRoutePipe,
  ],
  imports: [],
  exports: [
    CurrencyGlobalPipe,
    DateGlobalPipe,
    CleaningHoursPipe,
    TimePlusCleaningPipe,
    SimpleArrayFilterPipe,
    DisableCalendarDatePipe,
    CalendarDateDisplayPipe,
    IsHourAmPipe,
    PrettyHourDisplayPipe,
    PlaceNameFromIdPipe,
    SyzlStarPercentFilledPipe,
    ProductionTimeFromTotalTimePipe,
    LocalhostImageFixPipe,
    ReplaceSpaceWithPipe,
    CentsToDollarsPipe,
    TimeOnlyPipe,
    DollarSignPipe,
    PlaceOrderByUserPrefsPipe,
    PlaceRequiresCertnPipe,
    StringReplacePipe,
    ArrayInArrayPipe,
    ImageOptimizationPipe,
    BytesPipe,
    GetLastMessagePipe,
    ParseFileNamePipe,
    RequestStartDateToHumanReadablePipe,
    UserPlacePrefMatchPipe,
    DistancePipe,
    EllipsisPipe,
    InvoiceNamePipe,
    IsPlaceNewPipe,
    IsBookingDateUnavailablePipe,
    GoogleAddressFormatSplitPipe,
    SelectedBookingDisplayPipe,
    CostTotalFromCartItemsPipe,
    GcsFilenameSplitterPipe,
    TabServiceNormalizeRoutePipe,
  ],
  providers: [
    CleaningHoursPipe,
    TimePlusCleaningPipe,
    ProductionTimeFromTotalTimePipe,
    CalendarDateDisplayPipe,
    IsHourAmPipe,
    LocalhostImageFixPipe,
    ReplaceSpaceWithPipe,
    PlaceRequiresCertnPipe,
    StringReplacePipe,
    ImageOptimizationPipe,
    SimpleArrayFilterPipe,
    CentsToDollarsPipe,
    RequestStartDateToHumanReadablePipe,
    UserPlacePrefMatchPipe,
    DistancePipe,
    InvoiceNamePipe,
    IsPlaceNewPipe,
    IsBookingDateUnavailablePipe,
    SelectedBookingDisplayPipe,
  ]
})
export class PipesModule {}
