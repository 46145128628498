// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card {
  border-radius: 0;
  cursor: default;
}

.special {
  background-color: var(--ion-color-tertiary);
}
.special ion-card-title {
  color: var(--ion-color-light);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1vZGFsLWNvbnRlbnQtb25seS5wYWdlLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxnQkFBQTtFQUNBLGVBQUE7QUFDRjs7QUFFQTtFQUNFLDJDQUFBO0FBQ0Y7QUFDRTtFQUNFLDZCQUFBO0FBQ0oiLCJmaWxlIjoibW9kYWwtY29udGVudC1vbmx5LnBhZ2Uuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbImlvbi1jYXJkIHtcbiAgYm9yZGVyLXJhZGl1czogMDtcbiAgY3Vyc29yOiBkZWZhdWx0O1xufVxuXG4uc3BlY2lhbCB7XG4gIGJhY2tncm91bmQtY29sb3I6IHZhcigtLWlvbi1jb2xvci10ZXJ0aWFyeSk7XG4gIFxuICBpb24tY2FyZC10aXRsZSB7XG4gICAgY29sb3I6IHZhcigtLWlvbi1jb2xvci1saWdodCk7XG4gIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/modals/modal-content-only/modal-content-only.page.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;AACF;;AAEA;EACE,2CAAA;AACF;AACE;EACE,6BAAA;AACJ;AACA,4kBAA4kB","sourcesContent":["ion-card {\n  border-radius: 0;\n  cursor: default;\n}\n\n.special {\n  background-color: var(--ion-color-tertiary);\n  \n  ion-card-title {\n    color: var(--ion-color-light);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
