import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { IonImg } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { SyzlIconService } from '../services/syzl-icon.service';

/**
 * SyslIconDirective
 * - A directive to work with SyzlIconService to load Exported Syzl icons based on their name / type / number
 * - Add the directive to ```<ion-img>``` e.g.
 * 
 * ```
 * <ion-img
 *   appSyzlIcon
 *   [name]="ICON_NAME"
 *   [type]="color || line"
 *   [num]="ICON_NUMBER || omit if no number is required"
 *   [useDefaultSize]="true || false (default is true ... which applies width 36px and height 36px)"
 *   [width]="ICON_WIDTH"
 *   [height]="ICON_HEIGHT"
 * ></ion-img>
 * ```
 * 
 * - Do NOT set the src attribute on ```<ion-img>``` as this directive will do that for you
 * - Do NOT set the alt attribute on ```<ion-img>``` as this directive will do that for you
 * - Other ```<ion-img>``` attributes, such as slot, can still be used
 */
@Directive({
  selector: 'ion-img[appSyzlIcon]'
})
export class SyzlIconDirective implements OnInit, OnDestroy, OnChanges {

  /**
   * The base name of the icon to load (see Figma)
   */
  @Input() name = '';
  /**
   * The type of the icon to load (color (SyzlIconService.TYPE_COLOR) or line (SyzlIconService.TYPE_LINE))
   */
  @Input() type = '';
  /**
   * The number of the icon to load (default if SyzlIconService.NO_NUMBER (not a numbered icon))
   */
  @Input() num = SyzlIconService.NO_NUMBER;
  /**
   * Use the size defaults
   * - Defaults to true, which is 36px by 36px
   */
  @Input() useDefaultSize = true;
  /**
   * The width of the icon
   * - If unset and useDefaultSize is false, then a style/class must be used
   * - If unset and useDefaultSize is true, then the default size is applied
   * - If set (not 0), then this value is used
   */
  @Input() width = 0;
  /**
   * The height of the icon
   * - If unset and useDefaultSize is false, then a style/class must be used
   * - If unset and useDefaultSize is true, then the default size is applied
   * - If set (not 0), then this value is used
   */
  @Input() height = 0;

  private readonly _WIDTH  = 36;
  private readonly _HEIGHT = 36;

  /**
   * Boolean to keep track of when the icons.json file is ready and loaded
   */
  private _ready = false;
  /**
   * A subscription to listen for .next events for when the SyzlIconService is ready
   */
  private _iconsReady = Subscription.EMPTY;

  constructor(
    private _syzlIconService: SyzlIconService,
    private _ionImg: IonImg,
    private _el: ElementRef,
    private _renderer: Renderer2
  ) {
    // Set mininum width / height
    this._setSize('min-width', 20);
    this._setSize('min-height', 20);
    this._waitForIcons();
  }

  /**
   * Apply default sizing
   */
  ngOnInit() {
    if (this.useDefaultSize) {
      this._setSize('width', this._WIDTH);
      this._setSize('height', this._HEIGHT);
    }
  }

  /**
   * Ensure the _iconsReady subscription is unsubscribed
   */
  ngOnDestroy() {
    if (this._iconsReady) {
      this._iconsReady.unsubscribe();
    }
  }

  /**
   * React to @Input() changes
   * @param changes 
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.width || changes.height) {
      this._setSize('width', this.width);
      this._setSize('height', this.height);
    }
    if (changes.name || changes.type || changes.number) {
      this._fixName();
      this._getIcon();
    }
  }

  /**
   * When an icon comes down from the database, it can sometimes contains the number as part of its name
   * In those cases, we need to split the number off into the number field
   */
  private _fixName() {
    const parts = this.name?.split('-');
    if (parts && parts.length > 1) {
      const lastPart = parts[parts.length - 1];
      if (!isNaN(parseInt(lastPart, 10))) {
        this.name = this.name.substring(0, this.name.lastIndexOf('-'));
        this.num  = parseInt(lastPart, 10);
      }
    }
  }

  /**
   * Gets the icon src / alt from SyzlIconService
   */
  private _getIcon() {
    this._ionImg.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC';
    this._ionImg.alt = '';
    if (this._ready && this.name) {
      const { src, alt } = this._syzlIconService.getIcon(this.name, this.type, this.num);
      this._ionImg.src = src;
      // Weirdness - The alt tag applies before the image loads, so it quickly flashes on screen as a broken image
      // with a long alt tag ... moving this to a timeout solves that issue
      setTimeout(() => {
        this._ionImg.alt = alt;
      }, 0);
    }
  }

  /**
   * Sets the size of the <ion-img> element
   * @param style 
   * @param val 
   */
  private _setSize(style: string, val: number) {
    this._renderer.setStyle(this._el.nativeElement, style, val + 'px');
  }

  /**
   * Setup the subscription to listen for when SyzlIconService is ready
   */
  private _waitForIcons() {
    this._iconsReady = this._syzlIconService.ready.subscribe((ready) => {
      this._ready = ready;
      this._fixName();
      this._getIcon();
    });
  }
}
