import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, HostListener, Inject, Input, OnChanges, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { IonContent } from '@ionic/angular';

@Directive({
  selector: '[appSimpleContentSlider]'
})
export class SimpleContentSliderDirective implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  @Input() selectedIndex  = 0;
  @Input() useScreenWidth = true;
  @Input() ionContent: IonContent | null = null;

  el!: HTMLElement;

  private _initialDisplays: string[] = [];
  private _mutationObserver: MutationObserver | null = null;
  private _offsetWidth = 0;

  constructor(
    private _el: ElementRef,
    private _renderer: Renderer2,
    private _changeDetectionRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private _doc: Document,
  ) { }

  @HostListener('window:resize')
  onResize() {
    this._offsetWidth = this.useScreenWidth ? this._doc.documentElement.offsetWidth : this.el.offsetWidth;
    this._setTranslations(true);
    this.ngOnChanges();
  }

  @HostListener('transitionstart', ['$event'])
  async onTransitionStart(e: TransitionEvent) {
    if (e.target !== this.el) { return; }

    if (this.ionContent) {
      const el = await this.ionContent.getScrollElement();
      if (el.scrollTop > 0) {
        this.ionContent.scrollToTop(100);
      }
    }
  }

  @HostListener('transitionend', ['$event'])
  onTransitionEnd(e: TransitionEvent) {
    if (e.target !== this.el) { return; }

    this._hideOffscreenChildren();
  }

  ngOnInit() {
    this.el = this._el.nativeElement;
    this._renderer.setStyle(this.el, 'position', 'relative');
    this._renderer.setStyle(this.el, 'transition', 'transform 500ms ease-out');

    this._mutationObserver = new MutationObserver(() => this._setTranslations());
    this._mutationObserver.observe(this.el, { childList: true, subtree: false });
  }

  ngAfterViewInit() {
    this._setTranslations();
    this.onResize();

    // when bookingCart is being routed from fab-cart button, it should know which checkout component to display
    if (this.selectedIndex !== 0) {
      const transform = -1 * this.selectedIndex * this._offsetWidth;
      this._renderer.setStyle(this.el, 'transform', 'translateX(' + transform + 'px)');
    }
  }

  ngOnChanges() {
    if (this.el) {
      this._showAllChildren();
      const transform = -1 * this.selectedIndex * this._offsetWidth;
      this._renderer.setStyle(this.el, 'transform', 'translateX(' + transform + 'px)');
      // Fix for Chrome on iOS
      // Setting transform: translateX() to the same position does NOT call transitionstart / transitionend in Chrome on iOS (all other browsers do)
      // This runs in a timeout to coincide with the normal amination timing
      setTimeout(() => this._hideOffscreenChildren(), 500);
    }
  }

  ngOnDestroy(): void {
    if (this._mutationObserver) {
      this._mutationObserver.disconnect();
    }
  }

  /**
   * _setTranslations
   * - Set the X offsets for the items
   */
  private _setTranslations(skipSettingInitialDisplays: boolean = false) {
    if (this.el) {
      if (!skipSettingInitialDisplays) {
        this._initialDisplays = [];
      }
      const childNodes = this._getElementNodes();
      childNodes.forEach((childNode, i) => {
        this._renderer.setStyle(childNode, 'position', 'absolute');
        this._renderer.setStyle(childNode, 'top', 0);
        this._renderer.setStyle(childNode, 'transform', 'translateX(' + i * this._offsetWidth + 'px)');
        if (!skipSettingInitialDisplays) {
          this._initialDisplays.push((childNode as HTMLElement).style.display);
        }
      });
      this._changeDetectionRef.markForCheck();
    }
  }

  /**
   * _hideOffscreenChildren
   * - Hide offscreen chilren to improve performance
   */
  private _hideOffscreenChildren() {
    const childNodes = this._getElementNodes();
    childNodes.forEach((childNode, i) => {
      if (i === this.selectedIndex) {
        this._renderer.setStyle(childNode, 'display', this._initialDisplays[i]);
        return;
      }

      this._renderer.setStyle(childNode, 'display', 'none');
    });
    this._changeDetectionRef.markForCheck();
  }

  /**
   * _showAllChildren
   * - Shows all of the children for transitions
   */
  private _showAllChildren() {
    const childNodes = this._getElementNodes();
    childNodes.forEach((_, i) => {
      const child = this.el.children.item(i) as HTMLElement;
      if (!child || child.nodeType !== 1) { return; }

      this._renderer.setStyle(child, 'display', this._initialDisplays[i]);
    });
    this._changeDetectionRef.markForCheck();
  }

  /**
   * _getElementNodes
   * - Filters out all non-element nodes
   * @returns 
   */
  private _getElementNodes() {
    const childNodes: ChildNode[] = [];
    for (let i = 0; i < this.el.childNodes.length; i++) {
      const childNode = this.el.childNodes.item(i);
      if (childNode.nodeType === 1) {
        childNodes.push(childNode);
      }
    }
    return childNodes;
  }
}
