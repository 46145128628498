import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'syzlStarPercentFilled'
})
export class SyzlStarPercentFilledPipe implements PipeTransform {

  transform(star: number, rating: number): number {
    const floor = Math.floor(rating);
    const ceil  = Math.ceil(rating);
    if (star <= floor) {return 1;}
    if (star === ceil) {return parseFloat((rating - floor).toFixed(1));}
    return 0;
  }

}
