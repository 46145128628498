import { Pipe, PipeTransform } from '@angular/core';
import axios from 'axios';
import ICAL from 'ical.js';

@Pipe({
  name: 'gcalWebcalName',
  standalone: true
})
export class GcalWebcalNamePipe implements PipeTransform {

  async fetchCalendarNameFromWebcal(url: string): Promise<string> {
    try {
      // Convert webcal URL to HTTPS (most webcal URLs work over HTTPS as well)
      const httpsUrl = url.replace('webcal://', 'https://');
  
      // Make an HTTP request to get the iCalendar data
      const response = await axios.get(httpsUrl, { responseType: 'text' });
      
      // Parse the iCalendar data using ical.js
      const jcalData = ICAL.parse(response.data);
      const vcalendar = new ICAL.Component(jcalData);
      
      // Extract the calendar name (usually under the VCALENDAR component)
      const calendarName = vcalendar.getFirstPropertyValue('x-wr-calname') || 'Unknown Calendar';
      
      return calendarName.toString();
    } catch (error) {
      console.error('Error fetching or parsing iCalendar data:', error);
      return 'Unknown Calendar';
    }
  }

  private async _processSingleCalendar(calendarName: string): Promise<any> {
    const friendlyName = calendarName.startsWith('webcal://')
      ? await this.fetchCalendarNameFromWebcal(calendarName)
      : calendarName;

    return friendlyName;
  }

  async transform(calendarName: string): Promise<string> {
    return await this._processSingleCalendar(calendarName);
  }

}
