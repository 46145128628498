import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'simpleArrayFilter'
})
export class SimpleArrayFilterPipe implements PipeTransform {

  transform(arr: any[], filterOn: string, filterVal: any, filterOn2?: string, filterVal2?: any): any[] {
    return arr.filter(item => item[filterOn] === filterVal && (filterOn2 ? item[filterOn2] === filterVal2 : true));
  }

}
