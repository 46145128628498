import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { BasePageModal } from 'src/app/pages/base-page-modal/base-page-modal';
import { CountryCode, ISO_COUNTRY_CODES_CA_US, ISO_COUNTRY_CODES_OTHERS } from '../../phone-number-codes';
import { CountryCodeFromRegionCodePipe } from '../../pipes/country-code-from-region-code.pipe';

@Component({
  standalone: true,
  selector: 'app-phone-number-country-selection',
  templateUrl: './phone-number-country-selection.component.html',
  styleUrls: ['./phone-number-country-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    TranslateModule,
    CountryCodeFromRegionCodePipe,
  ]
})
export class PhoneNumberCountrySelectionComponent extends BasePageModal implements OnInit, OnDestroy {

  @Input() selected = '';

  allCountries: CountryCode[] = [];
  form = new FormGroup({
    search: new FormControl(''),
    countryCode: new FormControl('')
  }, { updateOn: 'change' });

  private _suggested = ISO_COUNTRY_CODES_CA_US;
  private _remaining = ISO_COUNTRY_CODES_OTHERS;
  private _destroy$  = new Subject<void>();

  constructor(
    injector: Injector,
    private _changeRef: ChangeDetectorRef
  ) {
    super(injector);
  }

  ngOnInit() {
    this.allCountries = this._suggested.concat(this._remaining);

    this.form.controls.search.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        distinctUntilChanged(),
        debounceTime(300)
      )
      .subscribe((val: string | null) => {
        val = val?.toLowerCase() ?? '';
        const suggested   = this._suggested.filter(item => item.country.toLowerCase().indexOf(val as string) > -1);
        const countries   = this._remaining.filter(item => item.country.toLowerCase().indexOf(val as string) > -1);
        this.allCountries = [...suggested, ...countries];
        this._changeRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  selectionChange(country: CountryCode) {
    this.selected = country.code;
    this.modalCtrl.dismiss(this.selected);
  }
}
