// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p {
  color: var(--ion-color-dark-shade);
  line-height: 20px;
}

span {
  color: var(--ion-color-primary);
  line-height: 20px;
  margin-left: auto;
  word-break: normal;
}
span:hover {
  cursor: pointer;
  text-decoration: underline;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInJlYWQtbW9yZS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLGtDQUFBO0VBQ0EsaUJBQUE7QUFDSjs7QUFFQTtFQUNJLCtCQUFBO0VBQ0EsaUJBQUE7RUFDQSxpQkFBQTtFQUNBLGtCQUFBO0FBQ0o7QUFBSTtFQUNJLGVBQUE7RUFDQSwwQkFBQTtBQUVSIiwiZmlsZSI6InJlYWQtbW9yZS5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbInAge1xuICAgIGNvbG9yOiB2YXIoLS1pb24tY29sb3ItZGFyay1zaGFkZSk7XG4gICAgbGluZS1oZWlnaHQ6IDIwcHg7XG59XG5cbnNwYW4ge1xuICAgIGNvbG9yOiB2YXIoLS1pb24tY29sb3ItcHJpbWFyeSk7XG4gICAgbGluZS1oZWlnaHQ6IDIwcHg7XG4gICAgbWFyZ2luLWxlZnQ6IGF1dG87XG4gICAgd29yZC1icmVhazogbm9ybWFsO1xuICAgICY6aG92ZXIge1xuICAgICAgICBjdXJzb3I6IHBvaW50ZXI7XG4gICAgICAgIHRleHQtZGVjb3JhdGlvbjogdW5kZXJsaW5lO1xuICAgIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components-standalone/read-more/read-more.component.scss"],"names":[],"mappings":"AAAA;EACI,kCAAA;EACA,iBAAA;AACJ;;AAEA;EACI,+BAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;AACJ;AAAI;EACI,eAAA;EACA,0BAAA;AAER;AACA,4vBAA4vB","sourcesContent":["p {\n    color: var(--ion-color-dark-shade);\n    line-height: 20px;\n}\n\nspan {\n    color: var(--ion-color-primary);\n    line-height: 20px;\n    margin-left: auto;\n    word-break: normal;\n    &:hover {\n        cursor: pointer;\n        text-decoration: underline;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
