import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cleaningHours'
})
export class CleaningHoursPipe implements PipeTransform {

  transform(productionTime: number): number {
    if (!productionTime || typeof productionTime !== 'number' || productionTime < 1) {
      return 0;
    }
    // Less than 8 hours is 1 hour for cleaning
    return productionTime < 8 ? 1 : 2;
  }
}
