import { Pipe, PipeTransform } from '@angular/core';
import { ICalendarDate } from '../components-standalone/calendar/calendar.component';

@Pipe({
  name: 'disableCalendarDate'
})
export class DisableCalendarDatePipe implements PipeTransform {
  transform(calendarDate: ICalendarDate, month: number, checkSlots: boolean = true, checkAvailability: boolean = true): boolean {
    return checkSlots && calendarDate.slots.length === 0
        || checkAvailability && !calendarDate.available
        || calendarDate.month !== month;
  }

}
