import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { OnesignalService } from 'src/app/services/onesignal.service';
import { User } from 'src/app/services/user.service';

@Component({
  standalone: true,
  selector: 'app-one-signal-failed',
  templateUrl: './one-signal-failed.component.html',
  styleUrls: ['./one-signal-failed.component.scss'],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
  ],
})
export class OneSignalFailedComponent implements OnInit, OnDestroy {

  @Input() showIfNotLoggedIn = false;

  isExpanded   = false;
  isBrave      = false;
  isDismissed  = false;
  isLoggedIn   = false;
  isSmsEnabled = false;

  private _destroy$ = new Subject<boolean>();

  constructor(
    public oneSignal: OnesignalService,
    private _user: User,
  ) { }

  private _isLoggedIn = () => {
    this.isLoggedIn = this._user.isLoggedIn();
  };

  async ngOnInit() {
    this.isBrave      = (window.navigator as any)['brave'] ? true : false;
    this._setupEvents();
  }

  ngOnDestroy() {
    this._destroy$.next(true);
    window.removeEventListener('user:login', this._isLoggedIn);
  }

  private _setupEvents() {
    this.oneSignal.isFailureAlertExpanded$.pipe(takeUntil(this._destroy$)).subscribe(val => this.isExpanded = val);
    this._isLoggedIn();
    window.addEventListener('user:login', this._isLoggedIn);
  }
}
