import * as Parse from 'parse';
import { environment } from 'src/environments/environment';
import { Category } from './services/categories';
import { Place } from './services/place.service';
import { Review } from './services/review-service';
import { Slide } from './services/slider-image';
import { User } from './services/user.service';

export class ParseInitializer {
  static init() {
    Slide.getInstance();
    Review.getInstance();
    Place.getInstance();
    Category.getInstance();
    User.getInstance();

    Parse.initialize(environment.appId);
    Parse.CoreManager.set('REQUEST_ATTEMPT_LIMIT', 1);
    window.localStorage.setItem('parseIsInitialized', 'true');
    (Parse as any).serverURL   = environment.serverUrl;
    (Parse as any).idempotency = true;
  }
}
