import { Component, Injector, Input, OnInit } from '@angular/core';
import { BasePageModal } from 'src/app/pages/base-page-modal/base-page-modal';
import { PlaceListingFragment } from 'src/generated/graphql';
import { TabRouteName } from 'src/app/services/tab.service';

@Component({
  selector: 'app-modal-insurance-details',
  templateUrl: './modal-insurance-details.page.html',
  styleUrls: ['./modal-insurance-details.page.scss'],
})
export class ModalInsuranceDetailsPage extends BasePageModal implements OnInit {

  @Input() place!: PlaceListingFragment;

  isInsuranceRequired = false;

  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.isInsuranceRequired = (this.place.insuranceRequired || 0) > 0;
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  onClick() {
    this.modalCtrl.dismiss();
    this.navigateTo(this.tabService.normalizeRoute(`${this.tabService.currentTabUrl}/${TabRouteName.profile}/documentation`));
  }
}
