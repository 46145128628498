//booking-status.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GetBookingStatusesGQL } from 'src/generated/graphql';

interface IStatus {
  objectId: string;
  status:   string;
  enum:     number;
}

@Injectable({
  providedIn: 'root',
})
export class BookingStatusService {

  static readonly ENUM_HELD           = 10;
  static readonly ENUM_ABANDONED      = 20;
  static readonly ENUM_REQUESTED      = 30;
  static readonly ENUM_DENIED         = 40;
  static readonly ENUM_APPROVED       = 50;
  static readonly ENUM_CLOSED_BY_HOST = 60;
  static readonly ENUM_IN_PROGRESS    = 70;
  static readonly ENUM_COMPLETED      = 80;
  static readonly ENUM_CANCELLED_MAKR = 1000;
  static readonly ENUM_CANCELLED_HOST = 1010;

  static readonly _STATUS_PENDING        = 'Pending';
  static readonly _STATUS_REQUESTED      = 'Requested';
  static readonly _STATUS_DENIED         = 'Denied by host';
  static readonly _STATUS_APPROVED       = 'Approved';
  static readonly _STATUS_CLOSED_BY_HOST = 'Closed by host';
  static readonly _STATUS_IN_PROGRESS    = 'Ongoing booking';
  static readonly _STATUS_COMPLETED      = 'Completed';
  static readonly _STATUS_CANCELLED_MAKR = 'Cancelled by maker';
  static readonly _STATUS_CANCELLED_HOST = 'Cancelled by host';

  private _statuses: IStatus[] = [];
  private _isLoaded$ = new BehaviorSubject<boolean>(false);

  constructor(
    private _getBookingStatuses: GetBookingStatusesGQL,
  ) {
    this._load();
  }

  get isLoaded() {
    return this._isLoaded$.asObservable();
  }

  statusIdFromEnum(val: number) {
    const status = this._statuses.find(item => item.enum === val);
    return status ? status.objectId : '';
  }

  statusIdFromStatusName(statusName: string) {
    const status = this._statuses.find(item => item.status === statusName);
    return status ? status.objectId : '';
  }

  statusEnumFromId(objectId: string) {
    const status = this._statuses.find(item => item.objectId === objectId);
    return status ? status.enum : '';
  }

  statusEnumFromStatusName(statusName: string) {
    const status = this._statuses.find(item => item.status === statusName);
    return status ? status.enum : '';
  }

  statusNameFromId(objectId: string) {
    const status = this._statuses.find(item => item.objectId === objectId);
    return status ? status.status : '';
  }

  statusNameFromEnum(val: number) {
    const status = this._statuses.find(item => item.enum === val);
    return status ? status.status : '';
  }

  /**
   * Returns the on-screen display text for a given status
   * - This doesn't necesarily equal the status text in the DB
   * @param val 
   * @returns 
   */
  static statusDisplayNameFromEnum(val: number) {
    switch (val) {
      case BookingStatusService.ENUM_REQUESTED:      return BookingStatusService._STATUS_REQUESTED;
      case BookingStatusService.ENUM_DENIED:         return BookingStatusService._STATUS_DENIED;
      case BookingStatusService.ENUM_APPROVED:       return BookingStatusService._STATUS_APPROVED;
      case BookingStatusService.ENUM_CLOSED_BY_HOST: return BookingStatusService._STATUS_CLOSED_BY_HOST;
      case BookingStatusService.ENUM_IN_PROGRESS:    return BookingStatusService._STATUS_IN_PROGRESS;
      case BookingStatusService.ENUM_COMPLETED:      return BookingStatusService._STATUS_COMPLETED;
      case BookingStatusService.ENUM_CANCELLED_MAKR: return BookingStatusService._STATUS_CANCELLED_MAKR;
      case BookingStatusService.ENUM_CANCELLED_HOST: return BookingStatusService._STATUS_CANCELLED_HOST;
      default: return '';
    }
  }

  static statusDisplayIconFromEnum(val: number) {
    switch (val) {
      case BookingStatusService.ENUM_REQUESTED:      return 'time';
      case BookingStatusService.ENUM_DENIED:         return 'close-outline';
      case BookingStatusService.ENUM_APPROVED:       return 'checkmark-outline';
      case BookingStatusService.ENUM_CLOSED_BY_HOST: return 'lock-closed';
      case BookingStatusService.ENUM_IN_PROGRESS:    return 'hourglass-outline';
      case BookingStatusService.ENUM_COMPLETED:      return 'checkmark-outline';
      case BookingStatusService.ENUM_CANCELLED_MAKR: return 'close-outline';
      case BookingStatusService.ENUM_CANCELLED_HOST: return 'close-outline';
      default: return '';
    }
  }

  private _load() {
    this._getBookingStatuses.fetch().subscribe((rsp) => {
      const edges    = rsp.data.bookingStatuses.edges || [];
      this._statuses = edges.map(item => ({
        objectId: item?.node?.objectId || '',
        status:   item?.node?.status   || '',
        enum:     item?.node?.enum     || 0,
      }));
      this._isLoaded$.next(true);
    });
  }
}
