import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { IPlaceAvailability } from 'src/app/interfaces/i-place-availability';
import { ComponentBase } from 'src/app/components-standalone/component-base';
import { IUserRequiredInfo } from 'src/app/services/booking-session.service';
import { Observable, takeUntil } from 'rxjs';
import { BookingRequest } from 'src/app/pages/booking-cart/booking-request';
import { BookingCartItemType } from 'src/app/services/booking-cart-item.service';

export enum BookingBarPage {
  placeDetails      = 'placeDetails',
  cartLoading       = 'cartLoading',
  cartDatesAndTimes = 'cartDatesAndTimes',
  cartAddOns        = 'cartAddOns',
  cartCheckout      = 'cartCheckout',
}

export interface ISectionNumbers {
  slotsPerWeek:   number;
  avgCostPerHour: number;
  availability?:  IPlaceAvailability;
}

export interface IBookingNumbers {
  numberOfBookings: number;
  numberOfAddOns: number;
  subtotal: number;
  dueNow: number;
}

export interface IBookingBar {
  activePage:         BookingBarPage;
  placeHasIdentity:   boolean;
  sectionInfo?:       ISectionNumbers;
  isSaving:           boolean;
}

@Component({
  selector: 'app-booking-bar',
  templateUrl: './booking-bar.component.html',
  styleUrls: ['./booking-bar.component.scss'],
  standalone: false,
})
export class BookingBarComponent extends ComponentBase implements AfterViewInit, OnDestroy, OnChanges {

  @Input({ required: true }) activePage: BookingBarPage = BookingBarPage.placeDetails;
  @Input() userRequiredInfo: IUserRequiredInfo = {
    hasCertn:               false,
    hasFoodHandling:        false,
    hasFuseApplication:     false,
    hasGoWalnutApplication: false,
    hasIdentity:            false,
    hasInsurance:           false,
    hasRecommendedMatch:    false,
    hasSmarterU:            false,
    hasSubscription:        false,
    hasWallet:              false,
    isNotSellingFood:       false,
    recommendedMatch:       '',
    eventInsuranceCount:    0,
    eventInsuranceCountMtd: 0,
  };

  @Input() bookings$: Observable<BookingRequest[]> | null = null;
  @Input() bookingCartItems: any[] = [];
  @Input() bookingInfo: IBookingNumbers = {
    numberOfBookings: 0,
    numberOfAddOns:   0,
    subtotal:         0,
    dueNow:           0,
  };
  @Input() hasMessage       = false;
  @Input() isSaving         = false;
  @Input() noPadding        = false;
  @Input() placeHasIdentity = false;
  @Input() sectionInfo: ISectionNumbers = {
    slotsPerWeek:   0,
    avgCostPerHour: 0,
  };
  
  @Output() book     = new EventEmitter<null>();
  @Output() continue = new EventEmitter<string>();
  @Output() request  = new EventEmitter<null>();

  @Output() dateError         = new EventEmitter<boolean>();
  @Output() timeError         = new EventEmitter<boolean>();
  @Output() foodHandlingError = new EventEmitter<boolean>();
  @Output() idError           = new EventEmitter<boolean>();
  @Output() insuranceError    = new EventEmitter<boolean>();
  @Output() msgError          = new EventEmitter<boolean>();
  @Output() walletError       = new EventEmitter<boolean>();

  bookingBarPage = BookingBarPage;

  hasDecimal     = false;
  hasDate        = false;
  hasTime        = false;
  isBookable     = false;
  placeHasCosts  = true;

  payableNowCost = 0;

  private _previouslyEmitted = false;

  ngAfterViewInit() {
    this.bookings$?.pipe(takeUntil(this._destroy$)).subscribe((bookings) => {
      this.hasDate = bookings.length > 0;
      this.hasTime = bookings.some(booking => booking.timeSelected);

      if (this._previouslyEmitted) {
        this._emitDateTimeErrors();
      }
    });
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.sectionInfo) {
      this.placeHasCosts = this.sectionInfo.avgCostPerHour > 0;
    }
    if (changes.placeHasIdentity) {
      this.isBookable = this.placeHasIdentity ? true : false;
    }
    if (changes.bookingInfo && this.bookingInfo && this.bookingInfo.subtotal) {
      this.hasDecimal = this.bookingInfo.subtotal !== parseInt(this.bookingInfo.subtotal.toString(), 10);
    }

    if (changes.bookingCartItems) {
      await this.checkPayableNow();
    }
  }

  async checkPayableNow() {
    this.payableNowCost = this.bookingCartItems
      .filter(item => item.get('itemType') === BookingCartItemType.foodHandling)
      .reduce((prev, curr) => prev + curr.get('total'), 0);
  }

  bookClick() {
    this.book.emit();
  }

  continueClick() {
    if (this.activePage === this.bookingBarPage.cartDatesAndTimes) {
      this._emitDateTimeErrors();
      this._previouslyEmitted = true;
    }
    if (this.bookingInfo.numberOfBookings === 0) {
      this.continue.emit('error');
    } else {
      this.continue.emit('');
    }
  }

  async requestClick() {
    const isIdentityOk     = this.userRequiredInfo.hasCertn        || this.userRequiredInfo.hasIdentity;
    const isFoodHandlingOk = this.userRequiredInfo.hasFoodHandling || this.userRequiredInfo.hasSmarterU || this.userRequiredInfo.isNotSellingFood;
    const isInsuranceOk    = this.userRequiredInfo.hasInsurance    || this.userRequiredInfo.hasSubscription;
    const isWalletOk       = this.userRequiredInfo.hasWallet;

    if (!isFoodHandlingOk || !isIdentityOk || !isInsuranceOk || !isWalletOk || !this.hasMessage || this.isSaving) {

      this.foodHandlingError.emit(!isFoodHandlingOk);
      this.idError.emit(!isIdentityOk);
      this.insuranceError.emit(!isInsuranceOk);
      this.msgError.emit(!this.hasMessage);
      this.walletError.emit(!isWalletOk);
    } else {
      this.request.emit();
    }
  }

  private _emitDateTimeErrors() {
    this.dateError.emit(!this.hasDate);
    this.timeError.emit(!this.hasTime);
  }
}
