//httpConfig.interceptor.ts
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { User } from './services/user.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let user  = new User();
    let token = '';
    const isCallingAPI = request.url.includes(environment.graphQlUrl);
        
    if (window.localStorage.getItem('parseIsInitialized') === 'true') {
      user = User.getCurrent();
      token = user.getSessionToken();
    }

    //Authentication by setting header with token value
    if (token !== '' && isCallingAPI) {
      request = request.clone({
        setHeaders: {
          'X-Parse-Session-Token': token
        }
      });
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => event),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(error);
      }));
  }
}
