import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'centsToDollars'
})
export class CentsToDollarsPipe implements PipeTransform {

  transform(cents: number, hideCents: boolean = false): string {
    const str = cents.toString();
    const len = str.length;

    if (str === '0') {
      return this._getDollars(str, len) + '.' + '00';
    }
    
    return hideCents
      ? this._getDollars(str, len)
      : this._getDollars(str, len) + '.' + this._getCents(str, len);
  }

  private _getCents(str: string, len: number) {
    return ('00' + str.substring(len - 2)).slice(2);
  }

  private _getDollars(str: string, len: number) {
    return len > 2 ? str.substring(0, len - 2) : '0';
  }
}
