import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Preference } from './preference.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JsonLoaderService {

  private readonly _DEFAULT_ENDING = 'en.json';

  constructor(
    private _http: HttpClient,
    private _preferences: Preference
  ) { }

  async load(url: string): Promise<any> {
    try {
      const val = await lastValueFrom(this._http.get(url));
      return val;
    } catch (error) {
      if (url.endsWith(this._preferences.lang + '.json')) {
        const endsWith = url.substring(url.length - 7);
        try {
          const val = await lastValueFrom(this._http.get(url.replace(endsWith, this._DEFAULT_ENDING)));
          return val;
        } catch (err) {}
      }
      return null;
    }
  }

  
}
