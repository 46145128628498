import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
@Pipe({
  name: 'selectedBookingDisplay'
})
export class SelectedBookingDisplayPipe implements PipeTransform {

  transform(startDate: string | Date, endDate: string | Date, full?: boolean): string {
    const start = typeof startDate === 'string' ? DateTime.fromISO(startDate) : DateTime.fromJSDate(startDate);
    const end   = typeof endDate === 'string'   ? DateTime.fromISO(endDate)   : DateTime.fromJSDate(endDate);

    const format = full ? 'd MMMM, EEEE' : 'd MMM, EEE';
    const displayDate = start.toFormat(format);
    const startTime = start.toFormat('ha').toLowerCase();
    const endTime   = end.toFormat('ha').toLowerCase();

    const timeRange = `(${startTime}-${endTime})`;
    return `${displayDate} ${timeRange}`;
  }

}
