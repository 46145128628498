// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

ion-icon {
  background-color: var(--ion-color-light);
  border-radius: 50%;
  position: absolute;
  top: -5px;
  left: -5px;
  padding: 0;
  width: 17px;
  height: 17px;
}

:host.small span {
  display: none;
}

div.is-clickable {
  cursor: pointer;
}

.paddingLeft {
  padding-left: 6px !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInByZWZlcmVuY2UtbWF0Y2guY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxjQUFBO0FBQ0Y7O0FBRUE7RUFDRSx3Q0FBQTtFQUNBLGtCQUFBO0VBQ0Esa0JBQUE7RUFDQSxTQUFBO0VBQ0EsVUFBQTtFQUNBLFVBQUE7RUFDQSxXQUFBO0VBQ0EsWUFBQTtBQUNGOztBQUdFO0VBQU8sYUFBQTtBQUNUOztBQUVBO0VBQ0UsZUFBQTtBQUNGOztBQUVBO0VBQ0UsNEJBQUE7QUFDRiIsImZpbGUiOiJwcmVmZXJlbmNlLW1hdGNoLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICBkaXNwbGF5OiBibG9jaztcbn1cblxuaW9uLWljb24ge1xuICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1pb24tY29sb3ItbGlnaHQpO1xuICBib3JkZXItcmFkaXVzOiA1MCU7XG4gIHBvc2l0aW9uOiBhYnNvbHV0ZTtcbiAgdG9wOiAtNXB4O1xuICBsZWZ0OiAtNXB4O1xuICBwYWRkaW5nOiAwO1xuICB3aWR0aDogMTdweDtcbiAgaGVpZ2h0OiAxN3B4O1xufVxuXG46aG9zdC5zbWFsbCB7XG4gIHNwYW4geyBkaXNwbGF5OiBub25lOyB9XG59XG5cbmRpdi5pcy1jbGlja2FibGUge1xuICBjdXJzb3I6IHBvaW50ZXI7XG59XG5cbi5wYWRkaW5nTGVmdCB7XG4gIHBhZGRpbmctbGVmdDogNnB4ICFpbXBvcnRhbnQ7XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/components/preference-match/preference-match.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,wCAAA;EACA,kBAAA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;AACF;;AAGE;EAAO,aAAA;AACT;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,4BAAA;AACF;AACA,o8BAAo8B","sourcesContent":[":host {\n  display: block;\n}\n\nion-icon {\n  background-color: var(--ion-color-light);\n  border-radius: 50%;\n  position: absolute;\n  top: -5px;\n  left: -5px;\n  padding: 0;\n  width: 17px;\n  height: 17px;\n}\n\n:host.small {\n  span { display: none; }\n}\n\ndiv.is-clickable {\n  cursor: pointer;\n}\n\n.paddingLeft {\n  padding-left: 6px !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
