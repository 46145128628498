import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as Parse from 'parse';

/**
 * A profile section in the checklist
 */
export interface IProfileSection {
  title: string;
  state: string;
  cta: string;
  path?: string;
  order: number;
  icon?: string;
  instructions?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProfileCompleteService {

  static readonly KITCHEN_PREF_TITLE      = 'KITCHEN_PREFERENCES';
  static readonly PROFILE_PIC_TITLE       = 'PROFILE_PICTURE';
  static readonly ID_VERIFICATION_TITLE   = 'IDENTITY_VERIFICATION';
  static readonly WALLET_TITLE            = 'WALLET';
  static readonly STATE_COMPLETE          = 'complete';
  static readonly STATE_INCOMPLETE        = 'incomplete';
  static readonly STATE_DISMISSED         = 'dismissed';
  static readonly STATE_PENDING           = 'pending';

  private _kitchenPrefComplete$          = new BehaviorSubject<IProfileSection>({
    title:        ProfileCompleteService.KITCHEN_PREF_TITLE,
    state:        ProfileCompleteService.STATE_INCOMPLETE,
    icon:         'demo',
    instructions: 'PROFILE_COMPLETE_KITCHEN_PREF_INSTRUCTIONS',
    cta:          'SET_PREFERENCES',
    path:         'preference-card',
    order:        2,
  });

  private _profilePhotoComplete$         = new BehaviorSubject<IProfileSection>({
    title:        ProfileCompleteService.PROFILE_PIC_TITLE,
    state:        ProfileCompleteService.STATE_INCOMPLETE,
    icon:         'camera',
    instructions: 'PROFILE_COMPLETE_PHOTO_INSTRUCTIONS',
    cta:          'UPLOAD_PICTURE',
    path:         'profile-edit',
    order:        3,
  });
  
  private _identityVerificationComplete$ = new BehaviorSubject<IProfileSection>({
    title:        ProfileCompleteService.ID_VERIFICATION_TITLE,
    state:        ProfileCompleteService.STATE_INCOMPLETE,
    icon:         'identity',
    instructions: 'PROFILE_COMPLETE_IDENTITY_INSTRUCTIONS',
    cta:          'VERIFY_ID',
    path:         'identity-verification',
    order:        4,
  });
  
  private _walletComplete$               = new BehaviorSubject<IProfileSection>({
    title:        ProfileCompleteService.WALLET_TITLE,
    state:        ProfileCompleteService.STATE_INCOMPLETE,
    icon:         'wallet',
    instructions: 'PROFILE_COMPLETE_WALLET_INSTRUCTIONS',
    cta:          'ADD_PAYMENT',
    path:         'wallet',
    order:        5,
  });

  private _checklist$                   = new BehaviorSubject<IProfileSection[]>([]);
  private _profileComplete$             = new BehaviorSubject<boolean>(false);
  private _dismissedComplete$           = new BehaviorSubject<boolean>(false);
  private _progressMessage$             = new BehaviorSubject<string>('');
  private _incompleteIndexes$           = new BehaviorSubject<number[]>([]);
  private _firstIncompleteSection$      = new BehaviorSubject<IProfileSection | null>(null);
  private _isHost$                      = new BehaviorSubject<boolean>(false);
  private _lastUpdatedSection$          = new BehaviorSubject<IProfileSection | null>(null);
  private _isInitialized                = false;

  // Cache for profile completion data
  private _cacheTimestamp: number = 0;
  private _cacheExpiration: number = 5000; // 5 seconds cache expiration
  private _lastResultCache: any = null;

  signUpComplete: IProfileSection = {
    title:        'WELCOME_TO_SYZL',
    state:        ProfileCompleteService.STATE_COMPLETE,
    icon:         'user',
    instructions: 'YOU_HAVE_CREATED_ACCOUNT',
    cta:          'NEXT',
    path:         '',
    order:        1,
  };

  fullyComplete: IProfileSection = {
    title:        'PROFILE_COMPLETE',
    state:        ProfileCompleteService.STATE_COMPLETE,
    icon:         'birthday-cake',
    instructions: 'Your profile is now complete',
    cta:          'EXIT',
    path:         '',
    order:        7,
  };

  constructor() { }

  get kitchenPrefComplete() {
    return this._kitchenPrefComplete$;
  }

  get profilePhotoComplete() {
    return this._profilePhotoComplete$;
  }

  get identityVerificationComplete() {
    return this._identityVerificationComplete$;
  }

  get walletComplete() {
    return this._walletComplete$;
  }

  get progressMessage() {
    return this._progressMessage$;
  }

  get incompleteIndexes() {
    return this._incompleteIndexes$;
  }

  get lastUpdatedSection() {
    return this._lastUpdatedSection$;
  }

  get profileComplete() {
    return this._profileComplete$;
  }

  get dismissedComplete() {
    return this._dismissedComplete$;
  }

  get checklist() {
    return this._checklist$;
  }

  get firstIncompleteSection(): IProfileSection | null {
    return this._firstIncompleteSection$.getValue();
  }

  get isHost(): boolean {
    return this._isHost$.getValue();
  }

  /**
   * Fetches profile completion data from the backend
   * and updates all the BehaviorSubjects
   */
  async refreshProfileCompletion(): Promise<void> {
    try {
      // Get current time
      const now = Date.now();
      
      // If cached data is still valid (less than 5 seconds old), skip refresh
      if (now - this._cacheTimestamp < this._cacheExpiration) {
        return;
      }

      
      // Fetch data from backend
      const result = await Parse.Cloud.run('getProfileCompletionSections');

      // Basic validation: Check if result is an object
      if (!result || typeof result !== 'object') {
        console.error('Backend function getProfileCompletionSections returned invalid data type:', result);
        throw new Error('Received invalid data type from backend.');
      }

      // Handle the nested sections structure if present
      const sections = result.sections || result;

      // Extract individual sections, using defaults if missing
      const preferences = sections.kitchenPrefComplete || sections.preferences || this._kitchenPrefComplete$.getValue();
      const photo = sections.photo || this._profilePhotoComplete$.getValue();
      const identity = sections.identity || this._identityVerificationComplete$.getValue();
      // Get wallet data but ensure the CTA is present
      const defaultWallet = this._walletComplete$.getValue();
      const wallet = sections.wallet ? {
        ...defaultWallet,
        ...sections.wallet,
        // Ensure CTA is always set, preferring backend value if available
        cta: sections.wallet.cta || defaultWallet.cta,
      } : defaultWallet;
      
      // Get isHost flag or default to false
      const isHost = result.isHost !== undefined ? result.isHost : false;

      // Update cache timestamp
      this._cacheTimestamp = now;

      // Update individual section subjects with data from the backend
      this._kitchenPrefComplete$.next(preferences);
      this._profilePhotoComplete$.next(photo);
      this._identityVerificationComplete$.next(identity);
      this._walletComplete$.next(wallet);
      this._isHost$.next(isHost);

      // Build the checklist - only include actual sections (filtering out metadata)
      // and ensure we're handling the case where the backend might return something different
      const checklist: IProfileSection[] = [];
      
      // Add sections in the right order
      if (preferences) checklist.push(preferences);
      if (photo) checklist.push(photo);
      if (identity) checklist.push(identity);
      if (wallet) checklist.push(wallet);
      
      // Sort by order property
      checklist.sort((a, b) => a.order - b.order);
      
      // Update the checklist observable
      this._checklist$.next(checklist);
      
      // Calculate derived states simply
      const isProfileComplete = checklist.every(section =>
        section.state === ProfileCompleteService.STATE_COMPLETE,
      );
      
      // Update the other observables
      this._profileComplete$.next(isProfileComplete);
      
      // For demonstration, just default these to false
      this._dismissedComplete$.next(false);
      
      // Create a simple progress message
      const completedCount = checklist.filter(
        section => section.state === ProfileCompleteService.STATE_COMPLETE,
      ).length;
      
      const progressMessage = isProfileComplete
        ? 'Profile Complete'
        : `${completedCount} of ${checklist.length} steps completed`;
      
      this._progressMessage$.next(progressMessage);
      
      // Calculate incomplete indexes
      const incompleteIndexes = checklist
        .map((section, index) => ({ section, index }))
        .filter(item => item.section.state !== ProfileCompleteService.STATE_COMPLETE)
        .map(item => item.index);
        
      this._incompleteIndexes$.next(incompleteIndexes);
      
      // Find first incomplete section
      const firstIncompleteSection = checklist.find(
        section => section.state !== ProfileCompleteService.STATE_COMPLETE,
      ) || null;
      
      this._firstIncompleteSection$.next(firstIncompleteSection);

    } catch (error) {
      console.error('Error in refreshProfileCompletion:', error); // Log error from this specific function
      // Reset cache timestamp on error to allow retry
      this._cacheTimestamp = 0; // Set to 0 instead of null to satisfy type number
      // Optionally reset subjects to a default/error state
      // this._checklist$.next([]); 
    }
  }

  /**
   * Get the profile completion state
   */
  async getProfileCompletion() {
    try {
      const profileCompletionState = await Parse.Cloud.run('getProfileCompletion');
      return profileCompletionState;
    } catch (error) {
      console.error('Error getting profile completion:', error);
      throw error;
    }
  }

  /**
   * Updates a specific state in the profile completion
   * @param state The state to update
   * @param value The new value
   */
  async updateState(state: string, value: string) {
    try {
      const profileCompletionState = await this.getProfileCompletion();
      profileCompletionState.set(state, value);
      await profileCompletionState.save();
      
      // Refresh the profile completion data after updating a state
      await this.refreshProfileCompletion();
      
      return profileCompletionState;
    } catch (error) {
      console.error('Error updating state:', error);
      throw error;
    }
  }

  /**
   * Checks if the profile is complete
   * @param login Whether this is being called during login
   */
  async checkIsComplete(login: boolean = false) {
    if (login) {
      this._isInitialized = false;
    }
    
    if (!this._isInitialized) {
      await this.refreshProfileCompletion();
    }
    
    return this._profileComplete$.getValue();
  }

  /**
   * Sets the last updated section
   * @param section The section that was updated
   */
  setLastUpdatedSection(section: IProfileSection) {
    this._lastUpdatedSection$.next(section);
  }

  /**
   * Checks if the wallet is complete - for backward compatibility
   * @param needsUpdate Whether to refresh the profile completion data
   * @returns Promise that resolves when the wallet status is updated
   */
  async checkWalletComplete(needsUpdate: boolean = true): Promise<void> {
    if (needsUpdate) {
      await this.refreshProfileCompletion();
      
      // If the wallet is complete, set it as the last updated section
      const walletSection = this._walletComplete$.getValue();
      if (walletSection.state === ProfileCompleteService.STATE_COMPLETE && !this._profileComplete$.getValue()) {
        this._lastUpdatedSection$.next({ ...walletSection });
      }
    }
  }

  /**
   * Sets a section state to dismissed
   * @param sectionTitle The title of the section to dismiss
   * @returns Promise that resolves when the section is dismissed
   */
  async setDismiss(sectionTitle: string) {
    try {
      // Update the state in the backend
      await Parse.Cloud.run('updateProfileSectionState', {
        sectionTitle,
        state: ProfileCompleteService.STATE_DISMISSED,
      });
      
      // Refresh the profile completion data after dismissing a section
      await this.refreshProfileCompletion();
      
      // Find the section in the checklist and set it as the last updated section
      const section = this._checklist$.getValue().find(
        section => section.title === sectionTitle,
      );
      
      if (section) {
        this._lastUpdatedSection$.next({ ...section, state: ProfileCompleteService.STATE_DISMISSED });
      }
      
      return true;
    } catch (error) {
      console.error('Error setting section to dismissed:', error);
      throw error;
    }
  }
  
  /**
   * Clears the last updated section
   */
  clearLastUpdated() {
    this._lastUpdatedSection$.next(null);
  }
}
