import { ModalController } from '@ionic/angular';
import { ComponentRef } from '@ionic/core';
import { HelperData } from '../helpers/helper-data-helper';
import { IModalDetails } from './i-modal-details';
import { ModalAddHelperPage } from './modal-add-helper/modal-add-helper.page';
import { ModalContentOnlyPage } from './modal-content-only/modal-content-only.page';
import { ModalContentWithEmailComponent } from './modal-content-with-email/modal-content-with-email.component';
import { ModalContentWithRadioAndTextareaComponent } from './modal-content-with-radio-and-textarea/modal-content-with-radio-and-textarea.component';
import { ModalContentWithTextareaPage } from './modal-content-with-textarea/modal-content-with-textarea.page';
import { ModalHtmlLoaderComponent } from './modal-html-loader/modal-html-loader.component';
import { ModalContentWithInputPage } from './modal-content-with-input/modal-content-with-input.page';
import { ModalCancellationComponent } from './modal-cancellation/modal-cancellation.component';

export class ModalActions {
  static readonly GO_BACK = 'goBack';
  static readonly CONFIRM = 'confirm';

  /**
     * Opens a modal with content only
     * - Used for Alerts, Confirmation, etc.
     * - The Page opening this modal MUST import ModalContentOnlyPageModule
     * @param modalCtrl 
     * @param modalDetails 
     * @returns 
     */
  static openModalContentOnly = async (modalCtrl: ModalController, modalDetails: IModalDetails) => {
    const modal = await modalCtrl.create({
      component: ModalContentOnlyPage,
      componentProps: { modalDetails },
      cssClass: 'modal-fullscreen small-modal-popup',
    },
    );
    await modal.present();
    return modal;
  };

  static openModalHtmlLoader = async (modalCtrl: ModalController, modalDetails: IModalDetails) => {
    const modal = await modalCtrl.create({
      component: ModalHtmlLoaderComponent,
      componentProps: { modalDetails },
      cssClass: 'modal-fullscreen'
    });
    await modal.present();
    return modal;
  };

  static openComponentModalNonFullScreen = async (modalCtrl: ModalController, component: ComponentRef, componentProps?: any, id?: string) => {
    const modal = await modalCtrl.create({
      component,
      componentProps,
      cssClass: 'modal-fullscreen small-modal-popup',
      id
    });

    await modal.present();
    return modal;
  };

  /**
     * Opens a modal with a text area
     * - Used for Alerts, Confirmation, etc. with an optional textarea response 
     * - The Page opening this modal MUST import ModalContentWithTextareaPageModule
     * @param modalCtrl 
     * @param modalDetails 
     * @returns 
     */
  static openModalWithTextArea = async (modalCtrl: ModalController, modalDetails: IModalDetails) => {
    const modal = await modalCtrl.create({
      component: ModalContentWithTextareaPage,
      componentProps: { modalDetails },
      cssClass: 'modal-fullscreen small-modal-popup'
    });
    await modal.present();
    return modal;
  };

  static openModalWithInput = async (modalCtrl: ModalController, modalDetails: IModalDetails) => {
    const modal = await modalCtrl.create({
      component: ModalContentWithInputPage,
      componentProps: { modalDetails },
      cssClass: 'modal-fullscreen small-modal-popup'
    });
    await modal.present();
    return modal;
  };

  /**
     * Opens a modal with radio buttons and a text area
     * @param modalCtrl 
     * @param modalDetails 
     * @returns 
     */
  static openModalWithRadioAndTextArea = async (modalCtrl: ModalController, modalDetails: IModalDetails) => {
    const modal = await modalCtrl.create({
      component: ModalContentWithRadioAndTextareaComponent,
      componentProps: { modalDetails },
      cssClass: 'modal-fullscreen small-modal-popup'
    });
    await modal.present();
    return modal;
  };

  /**
     * Opens a modal to capture a user's email address
     * - Used specifically for non-logged in users when we need to get their email address
     * @param modalCtrl 
     * @param modalDetails 
     * @returns 
     */
  static openModalWithEmail = async (modalCtrl: ModalController, modalDetails: IModalDetails) => {
    const modal = await modalCtrl.create({
      component: ModalContentWithEmailComponent,
      componentProps: { modalDetails },
      cssClass: 'modal-fullscreen small-modal-popup'
    });
    await modal.present();
    return modal;
  };

  /**
     * Opens a modal to add helpers
     * - Used specifically to add helpers to a booking
     * - The Page opening this modal MUST import ModalAddHelperPageModule
     * @param modalCtrl 
     * @param modalDetails 
     * @param helpers 
     * @returns 
     */
  static openModalAddHelpers = async (modalCtrl: ModalController, modalDetails: IModalDetails, helpers: HelperData[]) => {
    const modal = await modalCtrl.create({
      component: ModalAddHelperPage,
      componentProps: { modalDetails, helpers },
      cssClass: 'modal-fullscreen small-modal-popup'
    });
    await modal.present();
    return modal;
  };

  /**
   * Opens a modal for booking cancellation
   * -Used specifically to cancel to booking
   * @param modalCtrl 
   * @param modalDetails 
   * @returns 
   */
  static openModalCancellation = async (modalCtrl: ModalController, modalDetails: IModalDetails, userType: string) => {
    const modal = await modalCtrl.create({
      component: ModalCancellationComponent,
      componentProps: { modalDetails, userType },
      cssClass: 'modal-fullscreen small-modal-popup'
    });
    await modal.present();
    return modal;
  };

  /**
     * Opens a modal in default mode
     * @param modalCtrl 
     * @param component 
     * @param componentProps
     * @param cssClass 
     * @returns 
     */
  static open = async (modalCtrl: ModalController, component: ComponentRef, componentProps?: any, cssClass?: string | string[], backdropDismiss?: boolean) => {
    const modal = await modalCtrl.create({
      component,
      componentProps,
      cssClass,
      backdropDismiss
    });
    await modal.present();
    return modal;
  };

  /**
     * Opens a modal in fullscreen mode
     * @param modalCtrl 
     * @param component 
     * @param componentProps 
     * @returns 
     */
  static openFullScreenModal = async (modalCtrl: ModalController, component: ComponentRef, componentProps?: any) => {
    const modal = await modalCtrl.create({
      component,
      componentProps,
      cssClass: 'modal-fullscreen'
    });
    await modal.present();
    return modal;
  };

  /**
     * Opens a modal in fullheight mode
     * @param modalCtrl 
     * @param component 
     * @param componentProps 
     * @returns 
     */
  static openFullHeightModal = async (modalCtrl: ModalController, component: ComponentRef, componentProps?: any) => {
    const modal = await modalCtrl.create({
      component,
      componentProps,
      cssClass: 'modal-fullheight',
    });
    await modal.present();
    return modal;
  };
}
