// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.syzl-star-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
div.syzl-star-bar.horizontal {
  flex-direction: row;
}
div.syzl-star-bar.vertical {
  flex-direction: column;
}
div.syzl-star-bar.interactive app-syzl-star {
  cursor: pointer;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInN5emwtc3Rhci1iYXIuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxhQUFBO0VBQ0EsMkJBQUE7RUFDQSxtQkFBQTtBQUNKO0FBQ0k7RUFDSSxtQkFBQTtBQUNSO0FBRUk7RUFDSSxzQkFBQTtBQUFSO0FBSVE7RUFDSSxlQUFBO0FBRloiLCJmaWxlIjoic3l6bC1zdGFyLWJhci5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbImRpdi5zeXpsLXN0YXItYmFyIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGp1c3RpZnktY29udGVudDogZmxleC1zdGFydDtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuXG4gICAgJi5ob3Jpem9udGFsIHtcbiAgICAgICAgZmxleC1kaXJlY3Rpb246IHJvdztcbiAgICB9XG5cbiAgICAmLnZlcnRpY2FsIHtcbiAgICAgICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICB9XG5cbiAgICAmLmludGVyYWN0aXZlIHtcbiAgICAgICAgJiBhcHAtc3l6bC1zdGFyIHtcbiAgICAgICAgICAgIGN1cnNvcjogcG9pbnRlcjtcbiAgICAgICAgfVxuICAgIH1cbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/syzl-star-bar/syzl-star-bar.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,2BAAA;EACA,mBAAA;AACJ;AACI;EACI,mBAAA;AACR;AAEI;EACI,sBAAA;AAAR;AAIQ;EACI,eAAA;AAFZ;AACA,oyBAAoyB","sourcesContent":["div.syzl-star-bar {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n\n    &.horizontal {\n        flex-direction: row;\n    }\n\n    &.vertical {\n        flex-direction: column;\n    }\n\n    &.interactive {\n        & app-syzl-star {\n            cursor: pointer;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
