import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'costTotalFromCartItems'
})
export class CostTotalFromCartItemsPipe implements PipeTransform {

  transform(cartItems: Parse.Object[]): any {
    let total = 0;
    let count = 0;
    for (const item of cartItems) {
      total += item.get('cost') + item.get('serviceFee');
      count++;
    }
    
    return {
      total,
      count
    };
  }
}
