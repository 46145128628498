import { PreferenceMatchCalculator } from 'src/app/helpers/preference-match-calculator';
import { SyzlIconService } from 'src/app/services/syzl-icon.service';

export class SectionPreviewData {
  id       = '';
  title    = '';
  kitchen  = '';
  timeZone = '';
  images: any[] = [];
  nextAvailable: Date | null;
  listingDate: Date;
  preferenceMatch: PreferenceMatch[] = [];
  matchDetails: PreferenceMatchCalculator;
  capacity = 0;

  constructor(
    id: string,
    title: string,
    kitchen: string,
    timeZone: string,
    nextAvailable: Date | null,
    listingDate: Date,
    matches: PreferenceMatchCalculator,
    images: any[] = [],
    capacity: number
  ) {
    this.id              = id;
    this.title           = title;
    this.kitchen         = kitchen;
    this.timeZone        = timeZone;
    this.nextAvailable   = nextAvailable;
    this.listingDate     = listingDate;
    this.images          = images;
    this.preferenceMatch = matches.asArray;
    this.matchDetails    = matches;
    this.capacity        = capacity;
  }
}

/**
 * Holds the required information to display the preference match icons
 */
export class PreferenceMatch {
  icon         = '';
  iconNum      = SyzlIconService.NO_NUMBER;
  matches      = false;
  partialMatch = false;
  percentMatch = 0;
  preference   = '';

  constructor(icon: string, matches: boolean, iconNum?: number, partialMatch?: boolean, percentMatch?: number, preference?: string) {
    this.icon         = icon;
    this.iconNum      = iconNum || SyzlIconService.NO_NUMBER;
    this.matches      = matches;
    this.partialMatch = partialMatch || false;
    this.percentMatch = percentMatch || 0;
    this.preference   = preference || '';
  }
}

/**
 * Legacy ... will need to remove this
 */
export class PreviewPreference {
  icon    = '';
  iconNum = SyzlIconService.NO_NUMBER;
  matches = false;
}
