import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'imageOptimization'
})
export class ImageOptimizationPipe implements PipeTransform {

  private readonly _CLOUDINARY_START = environment.cloudinaryUrl;
  private readonly _GCS_PREFIX       = environment.gcsBucketPrefix;
  private readonly _PARAMS_START     = '?tx=';

  transform(filePath: string, params?: string): string {
    if (!filePath) {
      return '';
    }
    let out = filePath;

    // Conver the filePath to the Cloudinary path
    if (!filePath.startsWith(this._CLOUDINARY_START)) {
      filePath = filePath.substring(filePath.lastIndexOf('/') + 1);
      out      = this._CLOUDINARY_START + this._GCS_PREFIX + filePath;
    }
    
    // Add the params (if any)
    if (params) {
      out += (!params.startsWith(this._PARAMS_START) ? this._PARAMS_START : '') + params;
    }

    return out;
  }

}
