import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gcsFilenameSplitter'
})
export class GcsFilenameSplitterPipe implements PipeTransform {

  transform(fileName: string): string {
    return fileName?.slice(fileName.indexOf('_') + 1) || '';
  }

}
