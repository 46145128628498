import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AddressObfuscatorService, IObfuscatedAddress } from 'src/app/services/address-obfuscator.service';

@Component({
  selector: 'app-map-modal',
  templateUrl: './map-modal.component.html',
  styleUrls: ['./map-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapModalComponent implements OnInit, OnChanges {

  @Input() obfuscatedAddress: IObfuscatedAddress | null = null;

  staticMap: string | null = null;

  constructor(
    private _addressObfuscator: AddressObfuscatorService,
    private _changeRef: ChangeDetectorRef,
    private _el: ElementRef
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.obfuscatedAddress && changes.obfuscatedAddress.currentValue) {
      this.loadMap();
    }
  }

  loadMap() {
    this._changeRef.markForCheck();
    const geo = this.obfuscatedAddress?.geo;
    this._addressObfuscator
      .getStaticMapImage(geo?.latitude || 0, geo?.longitude || 0, .5, 14, this._el.nativeElement.offsetWidth, this.obfuscatedAddress?.isObfuscated)
      .then((img) => {
        this.staticMap = img;
        this._changeRef.markForCheck();
      });
  }
}
