// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendar-connect-button {
  margin-top: 20px;
}

ion-card-content p {
  margin-bottom: 16px;
}

ion-img,
img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

ion-card-header ion-grid {
  margin: inherit;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNhbGVuZGFyLXN5bmMuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxnQkFBQTtBQUNGOztBQUVBO0VBQ0UsbUJBQUE7QUFDRjs7QUFFQTs7RUFFRSxXQUFBO0VBQ0EsWUFBQTtFQUNBLG1CQUFBO0FBQ0Y7O0FBRUE7RUFDSSxlQUFBO0FBQ0oiLCJmaWxlIjoiY2FsZW5kYXItc3luYy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jYWxlbmRhci1jb25uZWN0LWJ1dHRvbiB7XG4gIG1hcmdpbi10b3A6IDIwcHg7XG59XG5cbmlvbi1jYXJkLWNvbnRlbnQgcCB7XG4gIG1hcmdpbi1ib3R0b206IDE2cHg7XG59XG5cbmlvbi1pbWcsXG5pbWcge1xuICB3aWR0aDogNTBweDtcbiAgaGVpZ2h0OiA1MHB4O1xuICBvYmplY3QtZml0OiBjb250YWluO1xufVxuXG5pb24tY2FyZC1oZWFkZXIgaW9uLWdyaWQge1xuICAgIG1hcmdpbjogaW5oZXJpdDtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/calendar-sync/calendar-sync.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;;EAEE,WAAA;EACA,YAAA;EACA,mBAAA;AACF;;AAEA;EACI,eAAA;AACJ;AACA,wrBAAwrB","sourcesContent":[".calendar-connect-button {\n  margin-top: 20px;\n}\n\nion-card-content p {\n  margin-bottom: 16px;\n}\n\nion-img,\nimg {\n  width: 50px;\n  height: 50px;\n  object-fit: contain;\n}\n\nion-card-header ion-grid {\n    margin: inherit;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
